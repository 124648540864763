import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useCountDownTimer } from '@core';
import { useRecoilValue } from 'recoil';
import { authStore } from '@auth';

const REPEAT_CODE_SECONDS = 60;

export const useRetryTimer = () => {
  const codeSentAt = useRecoilValue(authStore.codeSentAtSelector);

  const { seconds, startTimer, stopTimer, formattedTime } = useCountDownTimer(REPEAT_CODE_SECONDS);

  useEffect(() => {
    stopTimer();
    const now = dayjs();
    const sent = dayjs(codeSentAt);
    const diff = now.diff(sent, 'seconds');
    if (diff < REPEAT_CODE_SECONDS) {
      const secondsLeft = REPEAT_CODE_SECONDS - diff;
      startTimer(secondsLeft);
    }
  }, [stopTimer, codeSentAt, startTimer]);

  return { seconds, formattedTime };
};
