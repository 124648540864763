import * as yup from 'yup';
import { useCompanyValidation, useEmailValidation, usePasswordValidation } from '@form';

export const useValidation = () => {
  const passwordValidation = usePasswordValidation();
  const companyValidation = useCompanyValidation();
  const emailValidation = useEmailValidation();

  const firstStepValidation = yup.object().shape({
    full_name: yup.string().trim().required('Введите фамилию, имя и отчество'),
    ...emailValidation,
    ...passwordValidation,
  });

  const secondStepValidation = yup.object().shape({
    ...companyValidation,
    contract_offer: yup.bool().oneOf([true], ''),
    personal_data_processing: yup.bool().oneOf([true], ''),
  });

  return {
    firstStepValidation,
    secondStepValidation,
  };
};
