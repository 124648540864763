import { QueryParamConfig } from 'use-query-params';

export type BooleanParamType = boolean | null | undefined;

export const BooleanParam: QueryParamConfig<BooleanParamType> = {
  encode: (boolean: BooleanParamType) =>
    typeof boolean === 'boolean' ? (boolean ? 'true' : 'false') : boolean,

  decode: (booleanStr: string | (string | null)[] | null | undefined) =>
    (booleanStr && (booleanStr === 'true' ? true : false)) as boolean,
};
