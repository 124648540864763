import mime from 'mime';

export function validateFile(
  file: File,
  options: { accept: string | undefined; maxSize: number | undefined },
): { errors: string[]; acceptFile: File | null } {
  const { accept, maxSize } = options;
  let result = null;
  let hasSizeError = false;
  let hasExtensionError = false;
  if (accept?.length || maxSize) {
    const availableMimeTypes = (accept || '')
      .replaceAll(/[\s,]/g, '')
      .split('.')
      .map((ext) => mime.getType(ext));

    const isFileHasAvailableType = accept?.length
      ? availableMimeTypes.some((mimeType) => mimeType === file.type)
      : true;
    const isFileHasAvailableSize = maxSize ? file.size <= maxSize * 1000 * 1000 : true;

    if (isFileHasAvailableType && isFileHasAvailableSize) {
      result = file;
    } else {
      if (!isFileHasAvailableSize) {
        hasSizeError = true;
      }
      if (!isFileHasAvailableType) {
        hasExtensionError = true;
      }
    }
  }

  let errors = [];
  if (hasSizeError && maxSize) {
    errors.push(`Размер файла превышает ${maxSize}Мб`);
  }
  if (hasExtensionError) {
    errors.push('Неверный формат файла');
  }
  return { errors, acceptFile: result };
}
