import { CostSettingsForm } from '../../components';
import { useLoadCostSettingsQuery } from '../../hooks';

const ViewPage = () => {
  const { data, isLoading } = useLoadCostSettingsQuery();

  return <CostSettingsForm data={data} isLoading={isLoading} displayOnly />;
};

export default ViewPage;
