import { useMutation } from '@tanstack/react-query';
import { userDomain, userStore } from '@user';
import { useSetRecoilState } from 'recoil';

export const useUpdateProfileMutation = () => {
  const setUser = useSetRecoilState(userStore.userState);

  return useMutation(
    (payload: Partial<userDomain.User>) => {
      return userDomain.userRepository.updateProfile(payload);
    },
    {
      onSuccess: (data) => {
        setUser(data);
      },
    },
  );
};
