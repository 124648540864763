import { NullParamEnum } from '@core';
import { useCallback } from 'react';
import { CityTable, CityTableToolbar } from '../../components';
import { useLoadCityQuery } from '../../hooks';

const TablePage = () => {
  const { data, isLoading, queryParams, setQueryParams } = useLoadCityQuery();
  const hasActions = queryParams['filter.deleted_at'] === NullParamEnum.Null;

  const onPageChange = useCallback(
    (page: number) => {
      setQueryParams({ page });
    },
    [setQueryParams],
  );

  return (
    <>
      <CityTableToolbar queryParams={queryParams} setQueryParams={setQueryParams} />
      <CityTable
        data={data}
        isLoading={isLoading}
        onPageChange={onPageChange}
        hasActions={hasActions}
      />
    </>
  );
};

export default TablePage;
