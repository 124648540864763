import * as yup from 'yup';

export const useTargetAudienceValidation = () => {
  return {
    target_audience: yup.object().shape({
      age_from: yup
        .number()
        .transform((value) => (value === Number(value) ? value : null))
        .nullable()
        .required('Укажите верхнюю планку возраста')
        .min(18, 'Минимальный возраст 18 лет')
        .max(100, 'Максимальный возраст 100 лет'),
      age_to: yup
        .number()
        .transform((value) => (value === Number(value) ? value : null))
        .nullable()
        .required('Укажите верхнюю планку возраста')
        .min(18, 'Минимальный возраст 18 лет')
        .max(100, 'Максимальный возраст 100 лет'),
      city: yup.object().nullable().required('Выберите город'),
      gender: yup.string().nullable(),
      children: yup.array().of(yup.object()).nullable(),
    }),
  };
};
