import { useCurrentRole, userDomain } from '@user';

export const useIsCurrentRoleEqual = (
  role: userDomain.UserRole | userDomain.UserRole[],
): boolean => {
  const currentRole = useCurrentRole();

  if (Array.isArray(role)) {
    return Boolean(currentRole && role.includes(currentRole));
  }

  return currentRole === role;
};
