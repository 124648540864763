import { InputNumber, InputNumberChangeParams, InputNumberProps } from 'primereact/inputnumber';
import { useCallback } from 'react';
import { withFormControl } from '../with-form-control';

type NumberRangeSingleValue = number | undefined;
type NumberRangeValue = [NumberRangeSingleValue, NumberRangeSingleValue];
type InputOptions = Omit<InputNumberProps, 'name' | 'value' | 'onValueChange'> & { label?: string };

export type InputNumberRangeProps = {
  name?: string;
  value?: NumberRangeValue;
  onChange?: (value: NumberRangeValue) => void;
  fromOptions?: InputOptions;
  toOptions?: InputOptions;
} & InputNumberProps;

export const InputNumberRange = ({
  name,
  value,
  onChange,
  fromOptions,
  toOptions,
  ...rest
}: InputNumberRangeProps) => {
  const [fromValue, toValue] = value!;

  const handleChangeFrom = useCallback(
    (e: InputNumberChangeParams) => {
      onChange && onChange([e.value ?? undefined, value![1]]);
    },
    [onChange, value],
  );

  const handleChangeTo = useCallback(
    (e: InputNumberChangeParams) => {
      onChange && onChange([value![0], e.value ?? undefined]);
    },
    [onChange, value],
  );

  return (
    <div className="flex gap-3">
      <div className="p-inputgroup">
        <InputNumber
          name={`${name}.0`}
          value={fromValue}
          onValueChange={handleChangeFrom}
          {...rest}
          {...fromOptions}
          max={toValue}
        />
      </div>
      <div className="p-inputgroup">
        <InputNumber
          name={`${name}.1`}
          value={toValue}
          onValueChange={handleChangeTo}
          {...rest}
          {...toOptions}
          min={fromValue}
        />
      </div>
    </div>
  );
};

InputNumberRange.defaultProps = {
  value: [],
  fromOptions: {},
  toOptions: {},
  labelsShown: true,
};

export const FormInputNumberRange = withFormControl<InputNumberRangeProps>((props) => {
  return <InputNumberRange {...props} />;
});
