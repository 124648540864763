import { FormLabel } from '@form';
import { Skeleton } from 'primereact/skeleton';
import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  label: string;
  value?: string | number | ReactNode | null;
  isLoading?: boolean;
  direction?: 'row' | 'column';
  valueSuffix?: string;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
};

export const DisplayValue = ({
  label,
  value,
  isLoading,
  direction,
  className,
  labelClassName,
  valueClassName,
  valueSuffix,
}: Props) => {
  return (
    <div className={cn(styles.wrapper, { [styles.column]: direction === 'column' }, className)}>
      <FormLabel label={label} className={labelClassName} />
      {isLoading ? (
        <Skeleton />
      ) : (
        <div className={valueClassName}>
          {value ? (
            <span>
              {value}
              {valueSuffix ? ` ${valueSuffix}` : ''}
            </span>
          ) : (
            '–'
          )}
        </div>
      )}
    </div>
  );
};

DisplayValue.defaultProps = {
  isLoading: false,
  direction: 'column',
};
