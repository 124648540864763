import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

export const usePhoneValidation = () => {
  return {
    phone: yup
      .string()
      .trim()
      .required('Введите номер телефона')
      .test('valid', 'Некорректный номер телефона', (value) =>
        isValidPhoneNumber(value ?? '', 'RU'),
      ),
  };
};
