import { balanceDomain } from '@balance';
import { RangeParam } from '@core';
import { useMemo } from 'react';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

export const useLoadTransactionQueryParams = (defaultItemsPerPage: number = 10) => {
  const defaultQueryParams: balanceDomain.TransactionLoadParamsConfig = useMemo(
    () => ({
      page: withDefault(NumberParam, 1),
      limit: withDefault(NumberParam, defaultItemsPerPage),
      search: StringParam,
      'filter.created_at': RangeParam,
      sortBy: StringParam,
    }),
    [defaultItemsPerPage],
  );

  return useQueryParams(defaultQueryParams);
};
