import { ProgressSpinner } from 'primereact/progressspinner';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  fullScreen?: boolean;
  fullContainer?: boolean;
  className?: string;
};

export const Loader = ({ fullContainer, fullScreen, className }: Props) => {
  return (
    <div
      className={cn(styles.wrapper, {
        [styles.screen]: fullScreen,
        [styles.full]: fullContainer,
      })}
    >
      <ProgressSpinner />
    </div>
  );
};

Loader.defaultProps = {
  fullContainer: false,
  fullScreen: false,
};
