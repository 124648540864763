import { cityDomain } from '@city';
import { withFormControl } from '../with-form-control';
import { selectTimezoneOptions } from './constants';
import { Select, SelectProps } from './select';

export const SelectTimezone = (props: SelectProps<cityDomain.Timezone>) => {
  return <Select options={selectTimezoneOptions} {...props} />;
};

export const FormSelectTimezone = withFormControl<SelectProps<cityDomain.Timezone>>(SelectTimezone);
