import { cityDomain } from '@city';
import { Button, TabItem, Tabs } from '@components';
import { NullParamEnum, NullParamType } from 'core/params';
import { routes } from '@router';
import { useNavigate } from 'react-router-dom';
import { DecodedValueMap, SetQuery } from 'use-query-params';

const tabs: TabItem<NullParamType>[] = [
  {
    label: 'Все',
    key: NullParamEnum.Null,
  },
  {
    label: 'Удаленные',
    key: NullParamEnum.NotNull,
  },
];

type Props = {
  queryParams: DecodedValueMap<cityDomain.CityLoadParamsConfig>;
  setQueryParams: SetQuery<cityDomain.CityLoadParamsConfig>;
};

export const CityTableToolbar = ({ queryParams, setQueryParams }: Props) => {
  const navigate = useNavigate();

  const onTabSelect = ({ key }: TabItem<NullParamType>) => {
    setQueryParams({
      'filter.deleted_at': key,
      page: 1,
    });
  };

  const onAddClick = () => {
    navigate(routes.dashboard.sub.city.sub.creation.path);
  };

  return (
    <div className="mb-4">
      <Tabs items={tabs} active={queryParams['filter.deleted_at']} onSelect={onTabSelect} />
      <Button data-testid="addCity" label="Добавить город" icon="plus" onClick={onAddClick} />
    </div>
  );
};
