import { userDomain, userStore } from '@user';
import { FieldValues, useForm, UseFormProps } from 'react-hook-form';
import { useRecoilState } from 'recoil';

export const useEditableForm = <TFieldValues extends FieldValues>(
  currentForm: userDomain.EditableForm,
  props: UseFormProps<TFieldValues>,
) => {
  const form = useForm<TFieldValues>(props);
  const [editableForm, setEditableForm] = useRecoilState(userStore.editableFormState);
  const isEditable = editableForm === currentForm;

  const toggleEditable = (resetValues?: Partial<TFieldValues>) => {
    if (editableForm) {
      form.reset(resetValues as any);
    }
    setEditableForm(editableForm ? null : currentForm);
  };

  return {
    form,
    editableForm,
    isEditable,
    toggleEditable,
  };
};
