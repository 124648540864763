import { Button } from 'components/button';
import React, { PropsWithChildren } from 'react';
import useCollapse from 'react-collapsed';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = PropsWithChildren<{
  collapseText: string;
  closeText?: string;
  className?: string;
}>;

export const Collapse = ({ collapseText, closeText, children, className }: Props) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const { onClick, ...toggleProps } = getToggleProps();

  const onToggleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <div className={cn(className, styles.collapse, { [styles.expanded]: isExpanded })}>
      <div {...getCollapseProps()}>
        <div>{children}</div>
      </div>
      <div onClick={onToggleClick} {...toggleProps}>
        <Button
          label={isExpanded ? closeText : collapseText}
          icon="chevron-down"
          iconPos="right"
          className={cn('p-button-text text-base', isExpanded && 'mt-3')}
        />
      </div>
    </div>
  );
};

Collapse.defaultProps = {
  collapseText: 'Показать подробности',
  closeText: 'Скрыть подробности',
};
