import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '@router';
import { useCurrentUser } from '@user';

export const withAuthGuard = (AuthPage: FC) => {
  const GuardedAuthPage = () => {
    const user = useCurrentUser();

    if (user) {
      return <Navigate to={routes.dashboard.path} replace />;
    }

    return <AuthPage />;
  };

  return GuardedAuthPage;
};
