import * as yup from 'yup';

export const useValidation = () => {
  return yup.object().shape({
    vote_markup: yup
      .number()
      .transform((value) => (value === Number(value) ? value : null))
      .nullable()
      .required('Укажите сумму вознаграждения')
      .moreThan(0, 'Сумма должна быть больше нуля'),
    vote_reward: yup
      .number()
      .transform((value) => (value === Number(value) ? value : null))
      .nullable()
      .required('Укажите комиссию площадки')
      .min(0, 'Комиссия должна быть положительной'),
    withdrawal_amount_min: yup
      .number()
      .transform((value) => (value === Number(value) ? value : null))
      .nullable()
      .required('Укажите минимальную сумму для вывода')
      .moreThan(0, 'Сумма должна быть больше нуля'),
  });
};
