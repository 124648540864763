import { Divider } from '@components';
import { Notification } from '@widgets';
import { CostSettingsForm } from '../../components';
import { useLoadCostSettingsQuery } from '../../hooks';

const EditingPage = () => {
  const { data, isLoading } = useLoadCostSettingsQuery();

  return (
    <>
      {!!data && !data.is_editable && (
        <Notification
          title="Некоторые поля редактировать нельзя"
          reason="Вознаграждение за голос и комиссию площадки можно редактировать, когда нет активных опросов"
          className="mb-4"
        />
      )}
      <Divider className="mb-3 mt-0" />
      <CostSettingsForm data={data} isLoading={isLoading} />
    </>
  );
};

export default EditingPage;
