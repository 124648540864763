import { IBaseResource } from '@snap-alex/domain-js';
import { RawAxiosRequestHeaders } from 'axios';
import { httpResource } from 'core/resources/httpResource';
import { AuthResponse, CodeResponse } from '../interfaces';
import { AuthProvider } from '../providers/AuthProvider';
import { AuthResource, authResource } from '../resources/AuthResource';
import { Session } from '../types';
import { SessionStorage, sessionStorage } from './SessionStorage';
import {
  CheckEmailDto,
  CheckPhoneDto,
  ConfirmCodeDto,
  EmailLoginDto,
  PhoneLoginDto,
  RegistrationDto,
  RestorePasswordDto,
  SendCodeDto,
  SendRestoreLinkDto,
} from '../dtos';
import { userDomain } from '@user';

class AuthService extends AuthProvider<Session, AuthResponse> {
  constructor(
    storage: SessionStorage<Session>,
    http: IBaseResource,
    private authRes: AuthResource,
  ) {
    super(storage, http);
  }

  private resolveAuthResponse(res: AuthResponse): userDomain.User {
    const { token, ...rest } = res;
    this.setSession(token);
    return rest;
  }

  protected createAuthHeaders(session: Session | string): RawAxiosRequestHeaders {
    return { Authorization: `Bearer ${session}` };
  }

  protected checkSessionRemote(session: Session): Promise<AuthResponse | null> {
    return this.authRes.validateToken(this.createAuthHeaders(session));
  }

  protected isSessionValid(session: AuthResponse): boolean {
    return Boolean(session && session.token);
  }

  protected resolveRemoteSession(session: AuthResponse): Session {
    return session.token;
  }

  protected deleteSessionRemote(): Promise<void> {
    return this.authRes.logout();
  }

  public checkPhone(payload: CheckPhoneDto): Promise<void> {
    return this.authRes.checkPhone(payload);
  }

  public checkEmail(payload: CheckEmailDto): Promise<void> {
    return this.authRes.checkEmail(payload);
  }

  public async phoneLogin(payload: PhoneLoginDto): Promise<userDomain.User> {
    return this.resolveAuthResponse(await this.authRes.phoneLogin(payload));
  }

  public async emailLogin(payload: EmailLoginDto): Promise<userDomain.User> {
    return this.resolveAuthResponse(await this.authRes.emailLogin(payload));
  }

  public sendCode(payload: SendCodeDto): Promise<CodeResponse> {
    return this.authRes.sendCode(payload);
  }

  public sendRestoreLink(payload: SendRestoreLinkDto): Promise<void> {
    return this.authRes.sendRestoreLink(payload);
  }

  public resendCode(payload: SendCodeDto): Promise<CodeResponse> {
    return this.authRes.resendCode(payload);
  }

  public confirmCode(payload: ConfirmCodeDto): Promise<void> {
    return this.authRes.confirmCode(payload);
  }

  public async register(payload: RegistrationDto): Promise<userDomain.User> {
    return this.resolveAuthResponse(await this.authRes.register(payload));
  }

  public restorePasswordByPhone(payload: RestorePasswordDto): Promise<void> {
    return this.authRes.restorePasswordByPhone(payload);
  }

  public restorePasswordByEmail(payload: RestorePasswordDto): Promise<void> {
    return this.authRes.restorePasswordByEmail(payload);
  }
}

export const authService = new AuthService(sessionStorage, httpResource, authResource);
