import { balanceDomain } from '@balance';
import { RangeDatePicker, RangeDatePickerChangeParams } from '@form';
import { InputText } from 'primereact/inputtext';
import { DecodedValueMap, SetQuery } from 'use-query-params';
import debounce from 'lodash.debounce';
import { ChangeEventHandler, useState } from 'react';
import { Icon } from '@components';

type Props = {
  queryParams: DecodedValueMap<balanceDomain.TransactionLoadParamsConfig>;
  setQueryParams: SetQuery<balanceDomain.TransactionLoadParamsConfig>;
};

const debouncedFn = debounce((fn: () => void) => fn(), 300);

export const TransactionTableToolbar = ({ queryParams, setQueryParams }: Props) => {
  const [fullname, setFullname] = useState(queryParams['search']);

  const onFullnameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    setFullname(value);
    debouncedFn(() => setQueryParams({ search: value }));
  };

  const onCreatedAtChange = (e: RangeDatePickerChangeParams) => {
    setQueryParams({ 'filter.created_at': e.value });
  };

  return (
    <div className="flex align-items-center gap-4 mb-4">
      <span className="p-input-icon-right">
        <Icon icon="search" color="icons-ghost" />
        <InputText value={fullname ?? ''} placeholder="ФИО" onChange={onFullnameChange} />
      </span>
      <RangeDatePicker
        value={queryParams['filter.created_at']}
        onChange={onCreatedAtChange}
        clearable
      />
    </div>
  );
};
