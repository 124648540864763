import * as yup from 'yup';

export const useValidation = () => {
  return yup.object().shape({
    transaction_summ: yup
      .number()
      .transform((value) => (value === Number(value) ? value : null))
      .nullable()
      .required('Введите сумму')
      .moreThan(0, 'Сумма должна быть больше 0'),
  });
};
