import { useCallback } from 'react';
import { UserTable, UserTableToolbar } from '../../components';
import { useLoadUserQuery } from '../../hooks';

const TablePage = () => {
  const { data, isLoading, queryParams, setQueryParams } = useLoadUserQuery();

  const onPageChange = useCallback(
    (page: number) => {
      setQueryParams({ page });
    },
    [setQueryParams],
  );

  return (
    <>
      <UserTableToolbar
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        inModerationItems={data?.meta?.inModerationItems}
      />
      <UserTable
        data={data}
        queryParams={queryParams}
        isLoading={isLoading}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default TablePage;
