import { Outlet } from 'react-router-dom';
import cn from 'classnames';
import styles from './styles.module.scss';
import { Logo } from '@widgets';
import { withAuthGuard } from '@permissions';

const AuthLayout = withAuthGuard(() => {
  return (
    <div className={styles.layout}>
      <div className={cn(styles.card, 'p-fluid')}>
        <Logo className="mx-auto mb-5" />
        <Outlet />
      </div>
    </div>
  );
});

export default AuthLayout;
