import { atom } from 'recoil';
import { dynamicTitleStateKey } from '../constants';

type State = {
  title: string | null;
  isLoading: boolean;
};

export const dynamicTitleState = atom<State>({
  key: dynamicTitleStateKey,
  default: {
    title: null,
    isLoading: false,
  },
});
