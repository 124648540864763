import { Button } from '@components';
import { FormProvider, useForm } from 'react-hook-form';
import { withAuthForm } from '../../hocs';
import { userDomain } from '@user';
import { AuthStep } from '../../pages';
import { OwnerTypeFormControl } from '@widgets';

type FormValues = {
  owner_type: userDomain.OwnerType;
};

export const RegistrationTypeForm = withAuthForm(
  ({ credentials, backToFirstStep, updateCredentials, setCurrentStep }) => {
    const form = useForm<FormValues>({
      defaultValues: {
        owner_type: credentials.ownerType,
      },
    });
    const { handleSubmit, formState } = form;
    const { isValid } = formState;

    const onSubmit = async (values: FormValues) => {
      updateCredentials({ ownerType: values.owner_type });
      setCurrentStep(
        values.owner_type === userDomain.OwnerType.Individual
          ? AuthStep.IndividualRegistration
          : AuthStep.LegalEntityRegistration,
      );
    };

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <OwnerTypeFormControl />
          <div className="flex mt-5">
            <Button icon="chevron-left" className="p-button-info mr-3" onClick={backToFirstStep} />
            <Button type="submit" label="Продолжить" data-testid="buttonIndividualEntity" disabled={!isValid} />
          </div>
        </form>
      </FormProvider>
    );
  },
);
