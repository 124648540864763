import { BaseRepository } from '@snap-alex/domain-js';
import { CostSettings } from '../interfaces';
import { costSettingsResource } from '../resources/CostSettingsResource';

class CostSettingsRepository extends BaseRepository<CostSettings> {
  public loadCostSettings(): Promise<CostSettings> {
    return this.resource().get();
  }

  public saveCostSettings(payload: Partial<CostSettings>): Promise<CostSettings> {
    return this.resource('save').create(payload);
  }
}

export const costSettingsRepository = new CostSettingsRepository(costSettingsResource);
