import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { userDomain, userStore } from '@user';
import { useSetRecoilState } from 'recoil';

export const useLoadProfile = (id: string | number, options?: UseQueryOptions<userDomain.User>) => {
  const setUser = useSetRecoilState(userStore.userState);

  return useQuery<userDomain.User>(
    [userDomain.userResourceUrl, 'me', String(id)],
    () => userDomain.userRepository.loadProfile(),
    {
      onSuccess: (...args) => {
        setUser(args[0]);
        options?.onSuccess && options.onSuccess(...args);
      },
      ...options,
      enabled: !!id,
    },
  );
};
