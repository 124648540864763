import { balanceDomain } from '@balance';
import { getDateRangeParam } from '@core';
import { useQuery } from '@tanstack/react-query';
import { useLoadTransactionQueryParams } from './use-load-transaction-query-params';

export const useLoadTransactionQuery = () => {
  const [queryParams, setQueryParams] = useLoadTransactionQueryParams();
  const { 'filter.created_at': createdAt } = queryParams;
  return {
    queryParams,
    setQueryParams,
    ...useQuery([balanceDomain.transactionResourceUrl, queryParams], () => {
      return balanceDomain.transactionRepository.load({
        ...queryParams,
        // @ts-ignore
        'filter.created_at': getDateRangeParam(createdAt),
      });
    }),
  };
};
