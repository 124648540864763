import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { getErrorStatus, RequestErrors } from '@core';
import { dynamicTitleState } from '../store';
import { getDashboardPath } from '@router';

type UseLoadById<T> = (
  id: string,
  options?: UseQueryOptions<T, unknown, T, QueryKey> | undefined,
) => UseQueryResult<T, unknown>;

export const useDynamicPage = <T extends { id: number }>(
  useLoadById: UseLoadById<T>,
  titleKey: keyof T,
  redirectPath: string | string[],
  options?: UseQueryOptions<T, unknown, T, QueryKey>,
) => {
  const navigate = useNavigate();
  const setDynamicTitle = useSetRecoilState(dynamicTitleState);
  const { id } = useParams();
  const query = useLoadById(id as string, {
    ...options,
    onSuccess: (data) => {
      setDynamicTitle({ title: String(data[titleKey]), isLoading: false });
    },
    onError: (err) => {
      if (getErrorStatus(err) === RequestErrors.NotFound) {
        navigate(getDashboardPath(redirectPath));
      }
    },
  });

  useEffect(() => {
    setDynamicTitle((prev) => ({ ...prev, isLoading: true }));
  }, [setDynamicTitle]);

  return query;
};
