import cn from 'classnames';
import { Icon } from '@components';
import { Button as PrimeButton, ButtonProps } from 'primereact/button';
import { IconsId } from '../../assets/icons';

type Props = Omit<ButtonProps, 'icon' | 'iconPos'> & {
  icon?: IconsId;
  iconPos: 'right' | 'left';
};

export const Button = ({ icon, label, className, iconPos, ...other }: Props) => {
  const buttonClassName = cn(className, {
    'p-button-icon-only': !label && !!icon,
  });
  const iconClassName = cn({
    'p-button-icon-left': !!label && iconPos === 'left',
    'p-button-icon-right': !!label && iconPos === 'right',
  });

  return (
    <PrimeButton {...other} className={buttonClassName}>
      {icon && iconPos === 'left' && <Icon icon={icon} className={iconClassName} />}
      <span>{label}</span>
      {icon && iconPos === 'right' && <Icon icon={icon} className={iconClassName} />}
    </PrimeButton>
  );
};

Button.defaultProps = {
  type: 'button',
  iconPos: 'left',
};
