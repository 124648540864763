import { useState } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCheckEmail, useRegister } from '../../hooks';
import { withAuthForm } from '../../hocs';
import { FormCheckbox, FormInputPasswordToggle, FormInputText } from '@form';
import { Button } from '@components';
import { useValidation } from './use-validation';
import { getErrorMessage, getErrorStatus, RequestErrors } from 'core';

type RegistrationStep = 1 | 2;

type FormValues = {
  full_name: string;
  email: string;
  password: string;
  repeat_password: string;
  tin: string;
  name: string;
  activity_field: string;
  contract_offer: boolean;
  personal_data_processing: boolean;
};

const RegistrationStepTitleMap = {
  1: 'Введите ваши данные',
  2: 'Введите данные компании',
};

export const LegalEntityRegistrationForm = withAuthForm(({ credentials, backToFirstStep }) => {
  const [error, setError] = useState<string | null>(null);
  const [registrationStep, setRegistrationStep] = useState<RegistrationStep>(1);
  const { firstStepValidation, secondStepValidation } = useValidation();
  const isFirstRegistrationStep = registrationStep === 1;
  const { mutateAsync: register, isLoading: isRegistering } = useRegister();
  const { mutateAsync: checkEmail, isLoading: isChecking } = useCheckEmail();
  const form = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      full_name: '',
      email: '',
      password: '',
      repeat_password: '',
      tin: '',
      name: '',
      activity_field: '',
      contract_offer: false,
      personal_data_processing: false,
    },
    resolver: yupResolver(isFirstRegistrationStep ? firstStepValidation : secondStepValidation),
  });
  const { handleSubmit, formState, watch } = form;
  const { isValid } = formState;
  const email = watch('email');

  const clearError = () => {
    setError(null);
  };

  const onNextStep = async () => {
    try {
      await checkEmail({ email });
      setError('Данный email уже используется');
    } catch (err) {
      if (getErrorStatus(err) === RequestErrors.NotFound) {
        setRegistrationStep(2);
      }
    }
  };

  const onSubmit = async (values: FormValues) => {
    if (credentials.codeId) {
      const { contract_offer, personal_data_processing, tin, name, activity_field, ...rest } =
        values;

      try {
        await register({
          ...rest,
          company: {
            tin,
            name,
            activity_field,
          },
          code_id: credentials.codeId,
          owner_type: credentials.ownerType,
        });
      } catch (err) {
        if (getErrorStatus(err) === RequestErrors.WrongRequest) {
          setError(getErrorMessage(err));
        }
      }
    }
  };

  return (
    <div>
      <div className="flex justify-content-between text-sm mb-2">
        <span>{RegistrationStepTitleMap[registrationStep]}</span>
        <span className="text-third">Шаг {registrationStep} из 2</span>
      </div>
      <ProgressBar value={registrationStep * 50} showValue={false} className="mb-4" />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isFirstRegistrationStep ? (
            <>
              <FormInputText name="full_name" label="Напишите ваши ФИО" key="full_name" />
              <FormInputText
                name="email"
                label="Вашая электронная почта"
                key="email"
                error={error}
                errorTimeout={4}
                onErrorHide={clearError}
              />
              <FormInputPasswordToggle name="password" label="Придумайте пароль" />
              <FormInputPasswordToggle name="repeat_password" label="Повторите пароль" />
            </>
          ) : (
            <>
              <FormInputText
                name="tin"
                label="ИНН"
                key="tin"
                error={error}
                errorTimeout={4}
                onErrorHide={clearError}
              />
              <FormInputText name="name" label="Название юридического лица" key="name" />
              <FormInputText name="activity_field" label="Сфера деятельности" />
              <FormCheckbox
                name="contract_offer"
                label={
                  <span>
                    Ознакомился с{' '}
                    <a
                      href="/Оферта.docx"
                      rel="noreferrer noopener"
                      className="text-accent no-underline"
                    >
                      договором оферты
                    </a>
                  </span>
                }
                inline
              />
              <FormCheckbox
                name="personal_data_processing"
                label="Согласие на обработку перс. данных"
                inline
              />
            </>
          )}
          <div className="flex mt-5 mb-4">
            <Button
              icon="chevron-left"
              className="p-button-info mr-3"
              onClick={backToFirstStep}
            />
            {isFirstRegistrationStep ? (
              <Button
                type="button"
                label="Продолжить"
                loading={isChecking}
                data-testid="buttonContinue"
                disabled={!isValid}
                onClick={onNextStep}
              />
            ) : (
              <Button type="submit" label="Завершить" loading={isRegistering} disabled={!isValid} />
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
});
