import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getLastSubPath, routes } from '@router';
import { getDashboardPath } from 'router/utils';
import { TabItem, Tabs } from '@components';
import { useMemo } from 'react';
import { useIsAvailableByRole } from '@permissions';
import { userDomain } from '@user';

const ManagementLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeTab = getLastSubPath(pathname);
  const isCostShown = useIsAvailableByRole({ oneOf: userDomain.UserRole.Admin });

  const onTabSelect = (value: typeof tabs[0]) => {
    navigate(value.to);
  };

  const tabs: TabItem[] = useMemo(
    () => [
      {
        label: 'Стоимость',
        key: 'management',
        visible: isCostShown,
        to: getDashboardPath(routes.dashboard.sub.management.path),
      },
      {
        label: 'Рассылка уведомлений',
        key: 'distribution',
        to: routes.dashboard.sub.management.sub.distribution.path,
      },
      {
        label: 'Аномальное поведение',
        key: 'anomaly',
        to: routes.dashboard.sub.management.sub.anomaly.path,
      },
    ],
    [isCostShown],
  );

  return (
    <div>
      <Tabs items={tabs} active={activeTab} onSelect={onTabSelect} className="mb-3" />
      <Outlet />
    </div>
  );
};

export default ManagementLayout;
