// https://primereact.org/locale/
// https://github.com/primefaces/primelocale

export const ru = {
  accept: 'Да',
  reject: 'Нет',
  emptyMessage: 'Ничего не найдено',
  firstDayOfWeek: 1,
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  monthNamesShort: [
    'Янв',
    'Фев',
    'Мар',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авн',
    'Сен',
    'Окт',
    'Ноя',
    'Дек',
  ],
  dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
};
