import { authDomain } from '@auth';
import { useMutation } from '@tanstack/react-query';
import { userStore } from '@user';
import { useSetRecoilState } from 'recoil';

export const usePhoneLogin = () => {
  const setUser = useSetRecoilState(userStore.userState);

  return useMutation(
    (payload: authDomain.PhoneLoginDto) => {
      return authDomain.authService.phoneLogin(payload);
    },
    {
      onSuccess: (data) => {
        setUser(data);
      },
    },
  );
};
