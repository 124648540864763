import { useIsAvailableByOwnerType } from '@permissions';
import { routes } from '@router';
import { userDomain } from '@user';

export const useDashboardHeaderMap = () => {
  const isProfileDividerShown = useIsAvailableByOwnerType({
    notOf: userDomain.OwnerType.LegalEntity,
  });

  return {
    [routes.dashboard.sub.profile.path]: {
      title: 'Профиль',
      subtitle:
        'В этом разделе вы можете просмотреть и редактировать основную информацию об аккаунте',
      divider: isProfileDividerShown,
    },
    [`${routes.dashboard.sub.profile.path}/${routes.dashboard.sub.profile.sub.company.path}`]: {
      title: 'Профиль',
      subtitle:
        'В этом разделе вы можете просмотреть и редактировать основную информацию об аккаунте',
      divider: isProfileDividerShown,
    },
    [routes.dashboard.sub.balance.path]: {
      title: 'Баланс',
      subtitle: 'В этом разделе вы можете пополнить баланс и просмотреть историю своих операций',
      divider: false,
    },
    [routes.dashboard.sub.survey.path]: {
      title: 'Опросы',
      subtitle: 'В этом разделе вы можете редактировать/создавать/просматривать свои опросы',
      divider: false,
    },
    [`${routes.dashboard.sub.survey.path}/${routes.dashboard.sub.survey.sub.creation.path}`]: {
      title: 'Создание нового опроса',
      back: true,
    },
    [`${routes.dashboard.sub.survey.path}/${routes.dashboard.sub.survey.sub.editing.path}`]: {
      title: 'Редактирование опроса:',
      back: true,
      dynamic: true,
    },
    [`${routes.dashboard.sub.survey.path}/${routes.dashboard.sub.survey.sub.view.path}`]: {
      title: 'Опрос:',
      back: true,
      dynamic: true,
      divider: false,
    },
    [routes.dashboard.sub.support.path]: {
      title: 'Поддержка',
      subtitle: 'В этом разделе вы можете обратиться в поддержку, если у вас возникла проблема',
    },
    [routes.dashboard.sub.user.path]: {
      title: 'Пользователи',
      subtitle: 'В этом разделе вы можете редактировать/просматривать пользователей системы',
      divider: false,
    },
    [`${routes.dashboard.sub.user.path}/${routes.dashboard.sub.user.sub.editing.path}`]: {
      title: 'Редактирование пользователя:',
      back: true,
      dynamic: true,
    },
    [`${routes.dashboard.sub.user.path}/${routes.dashboard.sub.user.sub.view.path}`]: {
      title: 'Пользователь:',
      back: true,
      dynamic: true,
      divider: false,
    },
    [routes.dashboard.sub.customer.path]: {
      title: 'Заказчики',
      subtitle: 'В этом разделе вы можете редактировать/создавать/просматривать заказчиков',
      divider: false,
    },
    [`${routes.dashboard.sub.customer.path}/${routes.dashboard.sub.customer.sub.editing.path}`]: {
      title: 'Редактирование заказчика:',
      back: true,
      dynamic: true,
      divider: false,
    },
    [`${routes.dashboard.sub.customer.path}/${routes.dashboard.sub.customer.sub.view.path}`]: {
      title: 'Заказчик:',
      back: true,
      dynamic: true,
      divider: false,
    },
    [routes.dashboard.sub.moderator.path]: {
      title: 'Модераторы',
      subtitle: 'В этом разделе вы можете редактировать/создавать/просматривать модераторов',
      divider: false,
    },
    [`${routes.dashboard.sub.moderator.path}/${routes.dashboard.sub.moderator.sub.creation.path}`]:
      {
        title: 'Добавление модератора',
        back: true,
        divider: false,
      },
    [`${routes.dashboard.sub.moderator.path}/${routes.dashboard.sub.moderator.sub.editing.path}`]: {
      title: 'Редактирование модератора:',
      back: true,
      dynamic: true,
      divider: false,
    },
    [`${routes.dashboard.sub.moderator.path}/${routes.dashboard.sub.moderator.sub.view.path}`]: {
      title: 'Модератор:',
      back: true,
      dynamic: true,
      divider: false,
    },
    [routes.dashboard.sub.city.path]: {
      title: 'Города',
      subtitle: 'В этом разделе вы можете редактировать/создавать/просматривать города',
      divider: false,
    },
    [`${routes.dashboard.sub.city.path}/${routes.dashboard.sub.city.sub.creation.path}`]: {
      title: 'Добавление города',
      back: true,
      divider: false,
    },
    [`${routes.dashboard.sub.city.path}/${routes.dashboard.sub.city.sub.editing.path}`]: {
      title: 'Редактирование города:',
      back: true,
      dynamic: true,
      divider: false,
    },
    [`${routes.dashboard.sub.city.path}/${routes.dashboard.sub.city.sub.view.path}`]: {
      title: 'Город:',
      back: true,
      dynamic: true,
      divider: false,
    },
    [routes.dashboard.sub.management.path]: {
      title: 'Управление',
      subtitle: 'В этом разделе вы можете просматривать/редактировать стоимость',
      divider: false,
    },
    [`${routes.dashboard.sub.management.path}/${routes.dashboard.sub.management.sub.distribution.path}`]:
      {
        title: 'Управление',
        subtitle: 'В этом разделе вы можете отправлять уведомления пользователям',
        divider: false,
      },
    [`${routes.dashboard.sub.management.path}/${routes.dashboard.sub.management.sub.anomaly.path}`]:
      {
        title: 'Управление',
        subtitle: 'В этом разделе вы можете просматривать/контролировать аномальное поведение',
        divider: false,
      },
    [routes.dashboard.sub.cost.path]: {
      title: 'Управление: Редактирование стоимости',
      back: true,
      divider: false,
    },
    [routes.dashboard.sub.transaction.path]: {
      title: 'Операции',
      subtitle: 'В этом разделе вы можете посмотреть все операции',
      divider: false,
    },
  };
};
