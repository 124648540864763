import { BaseRepository } from '@snap-alex/domain-js';
import { BaseListMeta, BaseListResponse } from '../types';

export class BaseListRepository<T, L extends object = any, M = BaseListMeta> extends BaseRepository<
  T,
  BaseListResponse<T, M>,
  L
> {
  public load(params?: L) {
    return super.load(params);
  }
}
