import { RequestErrors } from '../enums/RequestErrors';
import { getErrorMessage } from '../utils/get-error-message';
import { getErrorStatus } from '../utils/get-error-status';
import { useCallback, useState } from 'react';

export const useServerError = (expectedStatus?: RequestErrors | RequestErrors[]) => {
  const [error, setError] = useState<string | null>(null);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const resolveError = useCallback(
    (error: any) => {
      const errorMessage = getErrorMessage(error);
      if (expectedStatus) {
        const errorStatus = getErrorStatus(error);
        if (
          Array.isArray(expectedStatus)
            ? expectedStatus.includes(errorStatus)
            : expectedStatus === errorStatus
        ) {
          setError(errorMessage);
        }
      } else {
        setError(errorMessage);
      }
    },
    [expectedStatus],
  );

  return {
    error,
    setError,
    resolveError,
    clearError,
  };
};
