import { BaseRestResource } from '@snap-alex/domain-js';
import { RawAxiosRequestHeaders } from 'axios';
import { httpResource } from 'core/resources/httpResource';
import { AuthResponse, CodeResponse } from '../interfaces';
import {
  CheckEmailDto,
  CheckPhoneDto,
  ConfirmCodeDto,
  EmailLoginDto,
  PhoneLoginDto,
  RegistrationDto,
  RestorePasswordDto,
  SendCodeDto,
  SendRestoreLinkDto,
} from '../dtos';

export class AuthResource extends BaseRestResource {
  protected lkRelativePath = 'lk';
  protected adminRelativePath = 'admin';

  public validateToken(headers: RawAxiosRequestHeaders): Promise<AuthResponse> {
    return this.child('check-token').get({}, { headers });
  }

  public checkPhone(payload: CheckPhoneDto): Promise<void> {
    return this.child(this.lkRelativePath, 'check-phone').create(payload);
  }

  public checkEmail(payload: CheckEmailDto): Promise<void> {
    return this.child(this.lkRelativePath, 'check-email').create(payload);
  }

  public phoneLogin(payload: PhoneLoginDto): Promise<AuthResponse> {
    return this.child(this.lkRelativePath, 'phone-login').create(payload);
  }

  public emailLogin(payload: EmailLoginDto): Promise<AuthResponse> {
    return this.child('email-login').create(payload);
  }

  public sendCode(payload: SendCodeDto): Promise<CodeResponse> {
    return this.child('send-code').create(payload);
  }

  public sendRestoreLink(payload: SendRestoreLinkDto): Promise<void> {
    return this.child(this.adminRelativePath, 'send-restore-link').create(payload);
  }

  public resendCode(payload: SendCodeDto): Promise<CodeResponse> {
    return this.child('resend-code').create(payload);
  }

  public confirmCode(payload: ConfirmCodeDto): Promise<void> {
    return this.child(this.lkRelativePath, 'confirm-code').create(payload);
  }

  public register(payload: RegistrationDto): Promise<AuthResponse> {
    return this.child(this.lkRelativePath, 'register').create(payload);
  }

  public restorePasswordByPhone(payload: RestorePasswordDto): Promise<void> {
    return this.child(this.lkRelativePath, 'restore-password').create(payload);
  }

  public restorePasswordByEmail(payload: RestorePasswordDto): Promise<void> {
    return this.child(this.adminRelativePath, 'restore-password').create(payload);
  }

  public logout(): Promise<void> {
    return this.child('logout').create({});
  }
}

export const authResource = new AuthResource(httpResource, 'auth');
