import { userDomain } from '@user';

type Rules = {
  notOf?: userDomain.OwnerType | userDomain.OwnerType[];
  oneOf?: userDomain.OwnerType | userDomain.OwnerType[];
};

export const isAvailableByOwnerType = (
  role: userDomain.OwnerType | undefined,
  { oneOf, notOf }: Rules,
) => {
  if (
    !role ||
    (notOf && (Array.isArray(notOf) ? notOf.includes(role) : notOf === role)) ||
    (oneOf && (Array.isArray(oneOf) ? !oneOf.includes(role) : oneOf !== role))
  ) {
    return false;
  }

  return true;
};
