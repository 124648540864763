import { Button, TableActions } from '@components';
import { ActionItem } from '@core';
import { useCallback, useMemo } from 'react';

type Params<T> = {
  onEdit: (item: T) => void;
  onRemove: (item: T) => void;
};

export const useDefaultActions = <T extends object>({
  onEdit,
  onRemove,
}: Params<T>): TableActions<T> => {
  const actions: ActionItem<T>[] = useMemo(
    () => [
      {
        label: 'Редактировать',
        icon: 'pencil',
        onClick: onEdit,
      },
      {
        label: 'Удалить',
        icon: 'trash',
        onClick: onRemove,
      },
    ],
    [onEdit, onRemove],
  );

  return useCallback(
    (item) => [
      {
        template: (
          <>
            {actions.map(({ label, icon, onClick }, index) => (
              <Button
                key={index}
                label={label}
                icon={icon}
                className="p-button-menu"
                onClick={() => onClick(item)}
              />
            ))}
          </>
        ),
      },
    ],
    [actions],
  );
};
