import { cityDomain } from '@city';
import { useQuery } from '@tanstack/react-query';
import { useLoadCityQueryParams } from './use-load-city-query-params';

export const useLoadCityQuery = () => {
  const [queryParams, setQueryParams] = useLoadCityQueryParams();
  return {
    queryParams,
    setQueryParams,
    ...useQuery([cityDomain.cityResourceUrl, queryParams], () => {
      return cityDomain.cityRepository.load(queryParams);
    }),
  };
};
