import { RadioButtonProps, RadioButton } from 'primereact/radiobutton';
import { withFormControl } from '../with-form-control';
import { useWatch } from 'react-hook-form';

export const FormRadioButton = withFormControl<RadioButtonProps>((props) => {
  const { name, value } = props;
  const currentValueForm = useWatch({ name });

  return <RadioButton checked={currentValueForm === value} {...props} />;
});
