import { Button, TableActions } from '@components';
import { IconsId } from '@icons';
import { userDomain } from '@user';
import { useCallback, useMemo } from 'react';

type Params = {
  onEdit: (item: userDomain.User) => void;
  onRemove: (item: userDomain.User) => void;
  onBlock: (item: userDomain.User) => void;
  onUnblock: (item: userDomain.User) => void;
  onApprove: (item: userDomain.User) => void;
  onDecline: (item: userDomain.User) => void;
};

type UserAction = { label: string; icon: IconsId; onClick: (item: userDomain.User) => void };

export const useActions = ({
  onEdit,
  onRemove,
  onBlock,
  onUnblock,
  onApprove,
  onDecline,
}: Params): TableActions<userDomain.User> => {
  const UserActions: { [key: string]: UserAction } = useMemo(
    () => ({
      edit: {
        label: 'Редактировать',
        icon: 'pencil',
        onClick: onEdit,
      },
      block: {
        label: 'Заблокировать',
        icon: 'lock',
        onClick: onBlock,
      },
      remove: {
        label: 'Удалить',
        icon: 'trash',
        onClick: onRemove,
      },
      unblock: {
        label: 'Разблокировать',
        icon: 'unlock',
        onClick: onUnblock,
      },
      approve: {
        label: 'Подтвердить регистрацию',
        icon: 'check',
        onClick: onApprove,
      },
      decline: {
        label: 'Отклонить регистрацию',
        icon: 'reject',
        onClick: onDecline,
      },
    }),
    [onApprove, onBlock, onDecline, onEdit, onRemove, onUnblock],
  );

  const UserActionsMap: { [key: string]: UserAction[] } = useMemo(
    () => ({
      [userDomain.UserStatus.New]: [UserActions.edit, UserActions.block, UserActions.remove],
      [userDomain.UserStatus.Moderation]: [
        UserActions.edit,
        UserActions.block,
        UserActions.decline,
        UserActions.approve,
        UserActions.remove,
      ],
      [userDomain.UserStatus.Approved]: [
        UserActions.edit,
        UserActions.block,
        UserActions.decline,
        UserActions.remove,
      ],
      [userDomain.UserStatus.Declined]: [
        UserActions.edit,
        UserActions.block,
        UserActions.approve,
        UserActions.remove,
      ],
      blocked: [UserActions.edit, UserActions.unblock, UserActions.remove],
    }),
    [UserActions],
  );

  return useCallback(
    (item) => [
      {
        template: (
          <>
            {UserActionsMap[item.is_blocked ? 'blocked' : item.status].map(
              ({ label, icon, onClick }, index) => (
                <Button
                  key={index}
                  label={label}
                  icon={icon}
                  className="p-button-menu"
                  onClick={() => onClick(item)}
                />
              ),
            )}
          </>
        ),
      },
    ],
    [UserActionsMap],
  );
};
