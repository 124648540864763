import { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { NotFoundLayout } from '@layouts';
import { authStore, useCheckAuth } from '@auth';
import { userDomain } from '@user';
import { useIsCurrentRoleEqual } from '@permissions';
import { Loader } from '@components';

import { routes } from './routes';
import { getDashboardPath, renderRoutes } from './utils';
import { useCurrentUser, useLoadProfile } from 'modules/user/ui/hooks';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const Router = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { id } = useCurrentUser() ?? {};
  const { isChecked } = useRecoilValue(authStore.checkAuthSelector);
  const { mutateAsync: checkAuth } = useCheckAuth();

  useLoadProfile(id);

  const isSurveyOwner = useIsCurrentRoleEqual(userDomain.UserRole.SurveyOwner);
  const dashboardRedirectPath = isSurveyOwner
    ? routes.dashboard.sub.profile.path
    : routes.dashboard.sub.survey.path;

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    if (pathname === routes.dashboard.path) {
      navigate(getDashboardPath(dashboardRedirectPath), { replace: true });
    }
  }, [pathname, navigate, dashboardRedirectPath]);

  const renderedRoutes = useMemo(() => renderRoutes(routes), []);

  if (!isChecked) {
    return <Loader fullScreen />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to={routes.dashboard.path} replace />} />
        {renderedRoutes}
        <Route path="*" element={<NotFoundLayout />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};
