import { ReactElement, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

type Props = {
  message?: string | ReactElement | null;
  showTimeout?: number;
  onHide?: () => void;
  className?: string;
};

export const FormError = ({ message, showTimeout, onHide, className }: Props) => {
  const [isShown, setIsShown] = useState(false);
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (showTimeout && !!message) {
      setIsShown(true);
      timer.current = setTimeout(() => {
        setIsShown(false);
        onHide && onHide();
        timer.current = null;
      }, showTimeout * 1000);
    }
  }, [message, showTimeout, onHide]);

  useEffect(() => {
    return () => {
      if (!!timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  if ((message && !showTimeout) || (message && showTimeout && isShown)) {
    return <div className={cn('main-error text-sm', className)}>{message}</div>;
  }

  return null;
};
