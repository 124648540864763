import { withFormControl, FormError } from '@form';
import { fileDomain } from '@file';
import { ChangeEventHandler, useState } from 'react';
import { validateFile } from './utils';
import styles from './styles.module.scss';
import prettyBytes from 'pretty-bytes';

type Props = {
  value?: fileDomain.ServerFile | null | undefined;
  maxSize?: number;
  buttonLabel?: string;
  onUpload?: (file: fileDomain.ServerFile) => void;
};

const ACCEPT = '.jpg, .jpeg, .png';

export const ImageUpload = ({ value, maxSize, buttonLabel, onUpload, ...other }: Props) => {
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const uploadHandler: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const files = e.target.files;
    if (files && files instanceof FileList && onUpload) {
      setErrors([]);
      const { errors, acceptFile } = validateFile(files[0], {
        accept: ACCEPT,
        maxSize,
      });

      if (errors.length) {
        setErrors(errors);
      } else if (acceptFile) {
        try {
          setIsUploading(true);
          const file = await fileDomain.fileRepository.uploadFile(acceptFile);
          onUpload(file);
        } finally {
          setIsUploading(false);
        }
      }
    }
  };

  return (
    <>
      <div className="flex">
        {value && (
          <div className={styles.image}>
            <img src={value.url} alt={value.title} />
          </div>
        )}
        <div className="flex flex-column justify-content-between align-items-start">
          {value && (
            <div>
              <span className="block font-medium mb-1">
                {value.title}
                {value.extension}
              </span>
              <span className="text-secondary text-sm">
                {prettyBytes(value.size, { locale: true })}
              </span>
            </div>
          )}
          <label htmlFor="image-upload" className="cursor-pointer select-none">
            <input
              {...other}
              id="image-upload"
              type="file"
              accept={ACCEPT}
              onChange={uploadHandler}
              className="hidden"
            />
            <span className={`p-button p-button-${value ? 'secondary' : 'info'} transition-none`}>
              {isUploading && <span className="pi pi-spinner pi-spin mr-2" />}
              {value ? 'Изменить изображение' : buttonLabel}
            </span>
          </label>
        </div>
      </div>
      {!value && (
        <div className={styles.limitations}>
          <div className={styles.limitation}>
            Рекомендуемый размер изображения: 680 x 1024 px
          </div>
          <div className={styles.limitation}>
            Форматы изображения: {ACCEPT.replaceAll('.', '').toUpperCase()}
          </div>
          {maxSize && (
            <div className={styles.limitation}>
              Максимальный размер файла: {prettyBytes(maxSize * 1000 * 1000, { locale: true })}
            </div>
          )}
        </div>
      )}
      {!!errors.length && (
        <div className="flex flex-column gap-1 mt-1">
          {errors.map((error) => (
            <FormError message={error} className="pl-extra1" />
          ))}
        </div>
      )}
    </>
  );
};

export const FormImageUpload = withFormControl<Props>(({ onChange, onUpload, ...other }) => {
  return <ImageUpload onUpload={onChange} {...other} />;
});

ImageUpload.defaultProps = {
  buttonLabel: 'Выбрать изображение',
};
