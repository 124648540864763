import { Divider } from '@components';
import { Sidebar as PrimeSidebar, SidebarProps } from 'primereact/sidebar';
import { ReactNode } from 'react';

export type Props = {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  divider?: boolean;
} & SidebarProps;

export const Sidebar = ({ title, subtitle, divider, children, ...other }: Props) => {
  return (
    <PrimeSidebar blockScroll {...other}>
      {!!title && <h2 className="text-2xl font-medium line-height-2 mb-2">{title}</h2>}
      {!!subtitle && <p className="text-third text-sm mb-4">{subtitle}</p>}
      {divider && <Divider />}
      {children}
    </PrimeSidebar>
  );
};

Sidebar.defaultProps = {
  divider: true,
  position: 'right',
};
