import { balanceDomain } from '@balance';
import { FormValues } from '.';

export const getDefaultValues = (
  data?: Partial<balanceDomain.CreateTransactionDto> | null,
): FormValues => {
  return {
    transaction_summ: data?.transaction_summ ?? null,
    refill_type: balanceDomain.RefillType.Individual,
  };
};
