import * as yup from 'yup';
import { usePhoneValidation } from '@form';

export const useValidation = () => {
  const phoneValidation = usePhoneValidation();

  return yup.object().shape({
    ...phoneValidation,
  });
};
