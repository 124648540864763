import { cookiesService, IStorage } from '../storages';
import { Session } from '../types';

export class SessionStorage<Session extends string> {
  constructor(private key: string, private storage: IStorage) {}

  public getKey(): string {
    return this.key;
  }

  public async getSession(): Promise<Session | null> {
    return await this.storage.get(this.key);
  }

  public async setSession(session: Session): Promise<Session> {
    return await this.storage.set(this.key, session);
  }

  public async deleteSession(): Promise<void> {
    await this.storage.remove(this.key);
  }
}

export const sessionStorage = new SessionStorage<Session>('@auth', cookiesService);
