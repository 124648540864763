import { InputTextProps } from 'primereact/inputtext';
import { IMaskInput } from 'react-imask';
import cn from 'classnames';
import { withFormControl } from '../with-form-control';

type Props = Omit<InputTextProps, 'onChange'> & {
  onChange?: (value: string | null | undefined) => void;
};

export const InputPhone = ({ onChange, className, ...other }: Props) => {
  const handleChange = (value: any) => {
    onChange && onChange(value);
  };

  return (
    <IMaskInput
      mask="{+}{7} (000) 000-00-00"
      onAccept={handleChange}
      // @ts-ignore
      className={cn('p-inputtext', className)}
      unmask
      {...other}
    />
  );
};

export const FormInputPhone = withFormControl<Props>((props) => {
  return <InputPhone {...props} />;
});
