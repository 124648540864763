import { decodeDelimitedArray, encodeDelimitedArray, QueryParamConfig } from 'use-query-params';

export type RangeParamType =
  | [string | null | undefined, string | null | undefined]
  | null
  | undefined;

export const RangeParam: QueryParamConfig<RangeParamType> = {
  // @ts-ignore
  encode: (range: RangeParamType) => encodeDelimitedArray(range, ','),

  decode: (rangeStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedArray(rangeStr, ',') as RangeParamType,
};
