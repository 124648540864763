import { BaseListRepository } from '@core';
import { customerDomain } from '@customer';
import { moderatorDomain } from '@moderator';
import { UpdatePasswordDto, UpdatePhoneDto, UpdateStatusDto } from '../dtos';
import { User } from '../interfaces';
import { userResource } from '../resources/UserResource';
import { UserLoadParams, UserMeta } from '../types';

class UserRepository extends BaseListRepository<
  User,
  UserLoadParams | customerDomain.CustomerLoadParams | moderatorDomain.ModeratorLoadParams,
  UserMeta
> {
  public updatePassword(id: string | number, payload: UpdatePasswordDto): Promise<void> {
    return this.resource(id, 'update-password').patch(payload);
  }

  public updatePhone(id: string | number, payload: UpdatePhoneDto): Promise<User> {
    return this.resource(id, 'update-phone').patch(payload);
  }

  public updateStatus(id: number | string, payload: UpdateStatusDto): Promise<User> {
    return this.resource(id, 'update-status').patch(payload);
  }

  public block(id: number | string): Promise<User> {
    return this.resource(id, 'block').patch({});
  }

  public unblock(id: number | string): Promise<User> {
    return this.resource(id, 'unblock').patch({});
  }

  public saveModerator(payload: Partial<moderatorDomain.SaveModeratorDto>): Promise<User> {
    return payload.id ? this.patch(payload) : this.resource('moderator').create(payload);
  }

  public updateProfile(payload: Partial<User>): Promise<User> {
    return this.resource('me').patch(payload);
  }

  public loadProfile(): Promise<User> {
    return this.resource('me').get();
  }
}

export const userRepository = new UserRepository(userResource);
