import { Icon } from '@components';
import { IconsId } from '@icons';
import { ReactNode } from 'react';
import { Color } from '@core';
import { toast, ToastOptions } from 'react-toastify';

type Options = {
  header?: string | ReactNode | null;
  content: string | ReactNode;
} & ToastOptions;

const NotificationIconMap: { [key: string]: { icon: IconsId; color: Color } } = {
  error: { icon: 'exclamation-circle', color: 'main-error' },
  warning: { icon: 'warning', color: 'main-warning' },
  success: { icon: 'check-circle', color: 'main-success' },
};

export const notification = ({ header, content, type, ...rest }: Options) => {
  const icon = type && NotificationIconMap[type];

  return toast(
    <div>
      {header && <h5 className="text-sm font-medium text-primary">{header}</h5>}
      <p className="text-third text-xs line-height-3">{content}</p>
    </div>,
    {
      type,
      icon: icon && <Icon icon={icon.icon} color={icon.color} />,
      ...rest,
    },
  );
};
