import { useEffect } from 'react';

export const useDisableWindowScroll = (isDisable: boolean | undefined) => {
  useEffect(() => {
    if (isDisable) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isDisable]);
};
