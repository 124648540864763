import { Button } from '@components';
import { FormInputTextarea } from '@form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog } from 'primereact/dialog';
import { FormProvider, useForm } from 'react-hook-form';
import { useValidation } from './use-validation';

type FormValues = {
  reject_reason: string;
};

type Props = {
  visible: boolean;
  header: string;
  label: string;
  isLoading: boolean;
  onSubmit: (values: FormValues) => void;
  onHide: () => void;
};

export const RejectModal = ({ visible, header, label, isLoading, onSubmit, onHide }: Props) => {
  const validation = useValidation();

  const form = useForm<FormValues>({
    defaultValues: {
      reject_reason: '',
    },
    resolver: yupResolver(validation),
  });

  const { handleSubmit, formState } = form;
  const { isValid } = formState;

  return (
    <Dialog visible={visible} header={header} onHide={onHide}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-fluid">
            <FormInputTextarea
              name="reject_reason"
              label={label}
              rows={3}
              autoResize
              required
              colon
            />
          </div>
          <div className="flex justify-content-end">
            <Button label="Отмена" className="p-button-info mr-extra1" onClick={onHide} />
            <Button type="submit" label="Отправить" loading={isLoading} disabled={!isValid} />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
