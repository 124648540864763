import { Button } from '@components';
import { useNavigateToMain } from '@router';
import styles from './styles.module.scss';

export const NotFoundLayout = () => {
  const navigateToMain = useNavigateToMain();

  const onBack = () => {
    navigateToMain();
  };

  return (
    <div className={styles.layout}>
      <h2 className="text-2xl font-semibold line-height-3 main-white">
        Мы не можем найти нужную вам страницу
      </h2>
      <Button label="Вернуться на главную" className={styles.back} onClick={onBack} />
    </div>
  );
};
