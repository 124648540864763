import dayjs, { Dayjs } from 'dayjs';
import { DatePickerValue } from './types';

export const parseValueToDate = (value: DatePickerValue | Dayjs | number): Date | undefined => {
  // todo hotfix: Учитываем таймзону для того чтобы вернуть дату в пикер в формате utc +0
  const userTimezoneOffset = (new Date()).getTimezoneOffset() * 60000

  const date = value
    ? dayjs.utc(typeof value === 'number' ? dayjs().year(value) : value).toDate()
    : undefined;

  return date ? new Date(date.getTime() + userTimezoneOffset) : date;
};

export const parseValueToDayjs = (value: DatePickerValue | Dayjs): Dayjs | undefined => {
  return value ? dayjs(value) : undefined;
};

export const formatDate = (
  date: Date | Dayjs | null | undefined,
  time?: Date | null | undefined,
): string | undefined => {
  if (!date) return undefined;
  let dayjsDate = dayjs(date);
  if (time) dayjsDate = dayjsDate.set('hours', time.getHours()).set('minutes', time.getMinutes());
  return date ? dayjsDate.utc(true).format().replace('Z', '') : undefined;
};

export const yearFormatDate = (date: Date | null | undefined): number | undefined => {
  return date ? date.getFullYear() : undefined;
};
