import { formatDate, RangeDatePickerValue } from '@form';
import dayjs from 'dayjs';

export const getDateRangeParam = (value: RangeDatePickerValue) => {
  return value
    ? `$btw:${value[0] || formatDate(dayjs(value[1]).subtract(1, 'year'))},${formatDate(
        (value[1] ? dayjs(value[1]) : dayjs(value[0]).add(1, 'year'))
          .hour(23)
          .minute(59)
          .second(59),
      )}`
    : undefined;
};
