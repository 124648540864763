import { useMutation } from '@tanstack/react-query';
import { userDomain, userStore } from '@user';
import { companyDomain } from 'modules/company';
import { useSetRecoilState } from 'recoil';

export const usePatchCompanyMutation = () => {
  const setUser = useSetRecoilState(userStore.userState);

  return useMutation(
    (payload: Partial<companyDomain.Company>) => {
      return companyDomain.companyRepository.patch(payload);
    },
    {
      onSuccess: (data) => {
        setUser((currVal) => ({
          ...(currVal as userDomain.User),
          company: data,
        }));
      },
    },
  );
};
