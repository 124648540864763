import { memo, useCallback, useEffect, useMemo, useRef, useState, KeyboardEvent } from 'react';
import cn from 'classnames';

import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { PaginatorTemplate } from 'primereact/paginator';

import { BaseListMeta } from '@core';
import { Button, Icon } from '@components';

import { getFakeValues, getFirstRow } from './utils';
import { TableProps } from './types';
import styles from './styles.module.scss';
import { InputNumber } from '@form';

const defaultMeta: Pick<BaseListMeta, 'itemsPerPage' | 'currentPage'> = {
  itemsPerPage: 10,
  currentPage: 1,
};

const TableComponent = <T extends object>({
  columns,
  dataKey,
  isLoading,
  value,
  actions,
  meta,
  selectionMode,
  selection,
  onExport,
  isExportDisabled,
  onPageChange,
  className,
  onRowClick,
  ...otherProps
}: TableProps<T>) => {
  const mergedMeta = useMemo(() => ({ ...defaultMeta, ...meta }), [meta]);
  const { itemsPerPage, currentPage, totalItems, totalPages } = mergedMeta;
  const [pageToGo, setPageToGo] = useState<number | null>(1);
  const [first, setFirst] = useState<number>();
  const hasSelection = selectionMode && selectionMode === 'checkbox';

  const fakeValue = useMemo(
    () => getFakeValues(dataKey as string, itemsPerPage),
    [dataKey, itemsPerPage],
  );

  useEffect(() => {
    setFirst(getFirstRow(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage]);

  const BodyActionsTemplate = memo(
    ({ rowData, options }: { rowData: T; options: ColumnBodyOptions }) => {
      const actionsMenuRef = useRef<Menu>(null);

      return (
        <>
          <Button
            label="Действия"
            icon="chevron-down"
            iconPos="right"
            className="p-button-text"
            onClick={(e) => actionsMenuRef.current?.toggle(e)}
          />
          <Menu model={actions!(rowData, options)} ref={actionsMenuRef} popup />
        </>
      );
    },
  );

  const pageChangeHandler = useCallback(
    (page: number) => () => {
      onPageChange && onPageChange(page);
    },
    [onPageChange],
  );

  const paginatorTemplate: PaginatorTemplate = useMemo(
    () => ({
      layout: 'PrevPageLink PageLinks NextPageLink CurrentPageReport',
      PrevPageLink: ({ disabled }) => {
        return (
          <Icon
            icon="chevron-left"
            color="icons-ghost"
            className="mr-extra1"
            disabled={disabled}
            onClick={pageChangeHandler(currentPage - 1)}
          />
        );
      },
      NextPageLink: ({ disabled }) => {
        return (
          <Icon
            icon="chevron-right"
            color="icons-ghost"
            className="ml-extra1"
            disabled={disabled}
            onClick={pageChangeHandler(currentPage + 1)}
          />
        );
      },
      PageLinks: ({ className, view, page, totalPages }) => {
        const hasPagesBefore = view.startPage === page && view.startPage !== 0;
        const hasPagesAfter = view.endPage === page && page + 1 !== totalPages;

        if (hasPagesBefore || hasPagesAfter) {
          return (
            <>
              {hasPagesBefore && (
                <div className={className} onClick={pageChangeHandler(1)}>
                  1
                </div>
              )}
              <div className="p-paginator-element p-paginator-dots">...</div>
              {hasPagesAfter && (
                <div className={className} onClick={pageChangeHandler(totalPages)}>
                  {totalPages}
                </div>
              )}
            </>
          );
        }

        return (
          <div className={className} onClick={pageChangeHandler(page + 1)}>
            {page + 1}
          </div>
        );
      },
      CurrentPageReport: () => {
        const onPageInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter' && pageToGo) {
            pageChangeHandler(pageToGo)();
          }
        };

        const onPageInputChange = (value: any) => {
          setPageToGo(value);
        };

        return (
          <div className="p-paginator-go">
            Перейти к{' '}
            <InputNumber
              min={1}
              max={totalPages}
              value={pageToGo}
              onKeyDown={onPageInputKeyDown}
              onChange={onPageInputChange}
            />
          </div>
        );
      },
    }),
    [pageChangeHandler, currentPage, totalPages, pageToGo],
  );

  const interactiveClassName = onRowClick && 'cursor-pointer';

  return (
    <div className={className}>
      {hasSelection && (
        <div className="flex align-items-center mb-3">
          <span className="text-sm text-third">Выбрано: {selection?.length}</span>
          {onExport && (
            <Button
              label="Экспортировать выбранные (.xslx)"
              className="p-button-text ml-3"
              onClick={() => onExport(selection)}
              disabled={!selection.length || isExportDisabled}
            />
          )}
        </div>
      )}
      <DataTable
        value={isLoading ? fakeValue : value}
        dataKey={dataKey}
        {...otherProps}
        onPage={() => {}}
        rows={itemsPerPage}
        paginator={!!onPageChange && !!totalPages && totalPages > 1}
        first={first}
        totalRecords={totalItems}
        paginatorTemplate={paginatorTemplate}
        selectionMode={selectionMode}
        selection={selection}
        onRowClick={!isLoading ? onRowClick : undefined}
        lazy
      >
        {hasSelection && (
          <Column
            selectionMode={isLoading ? undefined : 'multiple'}
            className={cn(styles.selection, interactiveClassName)}
            body={isLoading && <Skeleton />}
          />
        )}
        {columns.map(({ body, field, ...rest }) => (
          <Column
            key={field}
            field={field}
            className={cn(interactiveClassName)}
            body={isLoading ? <Skeleton /> : body}
            {...rest}
          />
        ))}
        {actions?.length && (
          <Column
            className={cn(styles.actions, interactiveClassName)}
            body={(rowData, options) =>
              isLoading ? <Skeleton /> : <BodyActionsTemplate rowData={rowData} options={options} />
            }
          />
        )}
      </DataTable>
    </div>
  );
};

TableComponent.defaultProps = {
  isLoading: false,
  dataKey: 'id',
  value: [],
  isExportDisabled: false,
  selection: [],
};

export const Table = memo(TableComponent) as unknown as typeof TableComponent;
