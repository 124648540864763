import * as yup from 'yup';
import { usePasswordValidation } from '@form';

export const useValidation = () => {
  const passwordValidation = usePasswordValidation();

  return yup.object().shape({
    ...passwordValidation,
  });
};
