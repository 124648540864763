import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getLastSubPath, routes } from '@router';
import { getDashboardPath } from 'router/utils';
import { useIsAvailableByOwnerType } from '@permissions';
import { userDomain, userStore } from '@user';
import { useSetRecoilState } from 'recoil';
import { TabItem, Tabs } from '@components';

const tabs: TabItem[] = [
  {
    label: 'Основная информация',
    key: 'profile',
    to: getDashboardPath(routes.dashboard.sub.profile.path),
  },
  {
    label: 'Данные о компании',
    key: 'company',
    to: routes.dashboard.sub.profile.sub.company.path,
  },
];

const ProfileLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeTab = getLastSubPath(pathname);
  const isTabsShown = useIsAvailableByOwnerType({ oneOf: userDomain.OwnerType.LegalEntity });
  const setEditableForm = useSetRecoilState(userStore.editableFormState);

  const onTabSelect = (value: typeof tabs[0]) => {
    navigate(value.to);
    setEditableForm(null);
  };

  return (
    <div>
      {isTabsShown && <Tabs items={tabs} active={activeTab} onSelect={onTabSelect} />}
      <Outlet />
    </div>
  );
};

export default ProfileLayout;
