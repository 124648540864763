import { OptionalId } from '@core';
import { surveyDomain } from '@survey';
import { userDomain } from '@user';
import { FormValues } from './sidebar';
import { ChildrenFormSettingsVariant } from '@widgets';

export const getDefaultValues = (
  data?: OptionalId<surveyDomain.TargetAudience> | null,
): FormValues => {
  const children = data?.children ?? [
    {
      gender: userDomain.Gender.Male,
      birth_year: null,
    },
  ];
  const hasAnyChildren = data?.has_any_children ? true : children?.length <= 0;
  
  const childrenSettings = hasAnyChildren
    ? ChildrenFormSettingsVariant.HasAny
    : !hasAnyChildren && data?.children
    ? ChildrenFormSettingsVariant.Settings
    : ChildrenFormSettingsVariant.HasAny;

  return {
    age_from: data?.age_from ?? null,
    age_to: data?.age_to ?? null,
    city: data?.city ?? null,
    gender: data?.gender ?? userDomain.Gender.Any,
    children_number: data?.children?.length ?? 0,
    has_any_children: hasAnyChildren,
    children_settings: childrenSettings,
    children: data?.children ?? [
      {
        gender: userDomain.Gender.Male,
        birth_year: null,
      },
    ],
  };
};
