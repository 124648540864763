import { selector } from 'recoil';
import { authState } from '../atoms';
import { authCheckAuthSelectorKey } from '../constants';

export const checkAuthSelector = selector({
  key: authCheckAuthSelectorKey,
  get: ({ get }) => {
    const { isChecked, isAuthorized } = get(authState);
    return { isChecked, isAuthorized };
  },
  set: ({ get, set }, value) => {
    set(authState, {
      ...get(authState),
      ...value,
    });
  },
});
