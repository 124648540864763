import { balanceDomain } from '@balance';
import { useMutation } from '@tanstack/react-query';

export const useCreateMutation = () => {
  return useMutation(
    (payload: balanceDomain.CreateTransactionDto) => {
      return balanceDomain.transactionRepository.createTransaction(payload);
    },
    {
      onSuccess: ({ redirect: redirectPath }) => {
        window.location.replace(redirectPath);
      },
    },
  );
};
