import { FormCheckbox, FormInputPasswordToggle, FormInputText } from '@form';
import { Button } from '@components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withAuthForm } from '../../hocs';
import { useValidation } from './use-validation';
import { useRegister } from '../../hooks';
import { useState } from 'react';
import { getErrorMessage, getErrorStatus, RequestErrors } from 'core';

type FormValues = {
  full_name: string;
  email: string;
  password: string;
  repeat_password: string;
  contract_offer: boolean;
  personal_data_processing: boolean;
};

export const IndividualRegistrationForm = withAuthForm(({ credentials, backToFirstStep }) => {
  const [error, setError] = useState<string | null>(null);
  const validation = useValidation();
  const { mutateAsync: register, isLoading } = useRegister();
  const form = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      full_name: '',
      email: '',
      password: '',
      repeat_password: '',
      contract_offer: false,
      personal_data_processing: false,
    },
    resolver: yupResolver(validation),
  });
  const { handleSubmit, formState } = form;
  const { isValid } = formState;

  const clearError = () => {
    setError(null);
  };

  const onSubmit = async (values: FormValues) => {
    if (credentials.codeId) {
      const { contract_offer, personal_data_processing, repeat_password, ...rest } = values;

      try {
        await register({
          ...rest,
          code_id: credentials.codeId,
          owner_type: credentials.ownerType,
        });
      } catch (err) {
        if (getErrorStatus(err) === RequestErrors.WrongRequest) {
          setError(getErrorMessage(err));
        }
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInputText
          data-testid="fullIndividualName"
          name="full_name"
          label="Фамилия, имя, отчество"
        />
        <FormInputText
          name="email"
          data-testid="authIndividualEmail"
          label="Вашая электронная почта"
          error={error}
          errorTimeout={4}
          onErrorHide={clearError}
        />
        <FormInputPasswordToggle
          data-testid="authIndividualPassword"
          name="password"
          label="Придумайте пароль"
        />
        <FormInputPasswordToggle
          data-testid="authpasswordAgain"
          name="repeat_password"
          label="Повторите пароль"
        />
        <FormCheckbox
          name="contract_offer"
          label={
            <span>
              Ознакомился с{' '}
              <a href="/Оферта.docx" rel="noreferrer noopener" className="text-accent no-underline">
                договором оферты
              </a>
            </span>
          }
          inline
        />
        <FormCheckbox
          name="personal_data_processing"
          label="Согласие на обработку перс. данных"
          inline
        />
        <div className="flex mt-5 mb-4">
          <Button
            icon="chevron-left"
            className="p-button-info mr-3"
            onClick={backToFirstStep}
          />
          <Button
            type="submit"
            label="Завершить"
            data-testid="authIndividualRegistrationCompleted"
            loading={isLoading}
            disabled={!isValid}
            className="mt-5"
          />
        </div>
      </form>
    </FormProvider>
  );
});
