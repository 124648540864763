import { Icon } from '@components';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  title: string;
  reason: string;
  className?: string;
};

export const Notification = ({ title, reason, className }: Props) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className="flex align-items-center">
        <Icon icon="warning" color="main-warning" className="mr-extra1" />
        <h3 className="text-sm font-medium">{title}</h3>
      </div>
      <p className={styles.reason}>{reason}</p>
    </div>
  );
};
