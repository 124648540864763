import * as yup from 'yup';

export const usePasswordValidation = (passwordKey = 'password') => {
  return {
    [passwordKey]: yup
      .string()
      .trim()
      .required('Введите пароль')
      .min(6, 'Пароль слишком короткий')
      .max(12, 'Пароль слишком длинный'),
    repeat_password: yup
      .string()
      .trim()
      .oneOf([yup.ref(passwordKey), null], 'Пароли должны совпадать'),
  };
};
