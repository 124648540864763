import { memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableRowClickEventParams } from 'primereact/datatable';

import { Table } from '@components';
import { BaseListResponse } from '@core';
import { routes } from '@router';
import { userDomain } from '@user';

import {
  useUpdateUserStatusMutation,
  useBlockUserMutation,
  useUnblockUserMutation,
  useRemoveUserMutation,
} from '../../hooks';

import { useActions } from './use-actions';
import { useColumns } from './use-columns';
import { RejectUserModal } from '../reject-user-modal';
import { DecodedValueMap } from 'use-query-params';

type Props = {
  data: BaseListResponse<userDomain.User> | undefined;
  queryParams: DecodedValueMap<userDomain.UserLoadParamsConfig>;
  isLoading: boolean;
  onPageChange: (page: number) => void;
};

export const UserTable = memo(({ data, queryParams, isLoading, onPageChange }: Props) => {
  const navigate = useNavigate();
  const [isRejectModalShown, setIsRejectModalShown] = useState(false);
  const [rejectableUser, setRejectableUser] = useState<userDomain.User | null>(null);
  const { updateUserStatus } = useUpdateUserStatusMutation();
  const { blockUser } = useBlockUserMutation();
  const { unblockUser } = useUnblockUserMutation();
  const { removeUser } = useRemoveUserMutation();

  const onHideRejectModal = useCallback(() => {
    setIsRejectModalShown(false);
    setRejectableUser(null);
  }, []);

  const onEdit = useCallback(
    (item: userDomain.User) => {
      navigate(`${routes.dashboard.sub.user.sub.editing.path}/${item.id}`);
    },
    [navigate],
  );

  const onRemove = useCallback(
    (item: userDomain.User) => {
      removeUser(item.id);
    },
    [removeUser],
  );

  const onApprove = useCallback(
    (item: userDomain.User) => {
      updateUserStatus({ id: item.id, payload: { status: userDomain.UserStatus.Approved } });
    },
    [updateUserStatus],
  );

  const onDecline = useCallback((item: userDomain.User) => {
    setRejectableUser(item);
    setIsRejectModalShown(true);
  }, []);

  const onBlock = useCallback(
    (item: userDomain.User) => {
      blockUser(item.id);
    },
    [blockUser],
  );

  const onUnblock = useCallback(
    (item: userDomain.User) => {
      unblockUser(item.id);
    },
    [unblockUser],
  );

  const columns = useColumns({ status: queryParams['filter.status'] });
  const actions = useActions({ onEdit, onRemove, onApprove, onDecline, onBlock, onUnblock });

  const onRowClick = useCallback(
    (e: DataTableRowClickEventParams) => {
      navigate(`${routes.dashboard.sub.user.sub.view.path}/${e.data.id}`);
    },
    [navigate],
  );

  return (
    <>
      <Table
        columns={columns}
        value={data}
        isLoading={isLoading}
        meta={data?.meta}
        actions={actions}
        onPageChange={onPageChange}
        onRowClick={onRowClick}
      />
      {rejectableUser && (
        <RejectUserModal
          visible={isRejectModalShown}
          user={rejectableUser}
          onHide={onHideRejectModal}
        />
      )}
    </>
  );
});
