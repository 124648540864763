import { ReactComponent as LogoImage } from '@images/logo.svg';
import { useNavigateToMain } from '@router';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  withTitle?: boolean;
  isLink?: boolean;
  className?: string;
};

export const Logo = ({ withTitle, isLink, className }: Props) => {
  const navigateToMain = useNavigateToMain();

  const onClick = () => {
    isLink && navigateToMain();
  };

  return (
    <div
      className={cn(styles.wrapper, className, {
        [styles.withTitle]: withTitle,
        'cursor-pointer': isLink,
      })}
      onClick={onClick}
    >
      <div className={styles.logo}>
        <LogoImage />
      </div>
      {withTitle && <h3 className={cn('text-xl font-semibold', styles.title)}>Фотоголос</h3>}
    </div>
  );
};

Logo.defaultProps = {
  withTitle: false,
  isLink: false,
};
