import { FormInputPasswordToggle, FormInputText } from '@form';
import { Button } from '@components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withAuthForm } from '../../hocs';
import { useValidation } from './use-validation';
import { useEmailLogin } from '../../hooks';
import { useState } from 'react';
import { getErrorMessage, getErrorStatus, RequestErrors } from 'core';
import { AuthStep } from '../../pages';

type FormValues = {
  email: string;
  password: string;
};

export const EmailLoginForm = withAuthForm(({ setCurrentStep, updateCredentials }) => {
  const validation = useValidation();
  const [error, setError] = useState<string | null>(null);
  const { mutateAsync: emailLogin, isLoading } = useEmailLogin();
  const form = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validation),
  });
  const { handleSubmit, formState, watch } = form;
  const { isValid } = formState;
  const email = watch('email');

  const clearError = () => {
    setError(null);
  };

  const onResetPassword = () => {
    updateCredentials({ email });
    setCurrentStep(AuthStep.ResetPasswordByEmail);
  };

  const onSubmit = async (values: FormValues) => {
    try {
      await emailLogin(values);
    } catch (err) {
      if (getErrorStatus(err) === RequestErrors.WrongRequest) {
        setError(getErrorMessage(err));
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInputText data-testid="emailAdmin" name="email" label="E-mail" />
        <FormInputPasswordToggle
          name="password"
          data-testid="passwordAdmin"
          label="Пароль"
          error={error}
          errorTimeout={4}
          onErrorHide={clearError}
        />
        <Button
          data-testid="signInAdmin"
          type="submit"
          label="Войти"
          loading={isLoading}
          disabled={!isValid}
          className="mt-5 mb-4"
        />
        <Button
          label="Восстановить пароль"
          className="p-button-text mx-auto"
          onClick={onResetPassword}
        />
      </form>
    </FormProvider>
  );
});
