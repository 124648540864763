import { useDynamicPage } from '@layouts';
import { useLoadUserByIdQuery, userDomain } from '@user';

export const useUserDynamicPage = (
  redirectPath: string | string[],
  useLoadById?: typeof useLoadUserByIdQuery,
) => {
  return useDynamicPage<userDomain.User>(
    useLoadById ?? useLoadUserByIdQuery,
    'full_name',
    redirectPath,
  );
};
