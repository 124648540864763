import { useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import cn from 'classnames';

import { Icon } from '@components';
import { withFormControl } from '@form';

import {
  DatePickerChangeParams,
  DatePickerValue,
  RangeDatePickerChangeParams,
  RangeDatePickerValue,
} from './types';
import { DatePicker } from './date-picker';
import { parseValueToDayjs } from './utils';

type DatePickerProps = {
  prefix?: string;
};

type Props = {
  value?: RangeDatePickerValue;
  min?: DatePickerValue | Dayjs;
  max?: DatePickerValue | Dayjs;
  onChange?: (e: RangeDatePickerChangeParams) => void;
  clearable?: boolean;
  className?: string;
  toOptions?: DatePickerProps;
  fromOptions?: DatePickerProps;
};

export const RangeDatePicker = ({
  value,
  onChange,
  min,
  max,
  clearable,
  className,
  toOptions,
  fromOptions,
}: Props) => {
  const maxFrom =
    max && value?.[1]
      ? dayjs.max(dayjs(max), dayjs(value[1]))
      : parseValueToDayjs(max ?? value?.[1]);
  const minTo =
    min && value?.[0]
      ? dayjs.min(dayjs(min), dayjs(value[0]))
      : parseValueToDayjs(min ?? value?.[0]);

  const handleChangeFrom = useCallback(
    (e: DatePickerChangeParams) => {
      if (onChange) {
        const newValue = !(value?.[1] || e.value)
          ? null
          : [e.value as DatePickerValue, value?.[1] || null];
        onChange({
          ...e,
          value: newValue as RangeDatePickerValue,
        });
      }
    },
    [onChange, value],
  );

  const handleChangeTo = useCallback(
    (e: DatePickerChangeParams) => {
      if (onChange) {
        const newValue = !(value?.[0] || e.value)
          ? null
          : [value?.[0] || null, e.value as DatePickerValue];
        onChange({
          ...e,
          value: newValue as RangeDatePickerValue,
        });
      }
    },
    [onChange, value],
  );

  const onClear = useCallback(() => {
    // @ts-ignore
    onChange && onChange({ value: null });
  }, [onChange]);

  return (
    <div className={cn('flex align-items-center', className)}>
      <DatePicker
        value={value?.[0]}
        onChange={handleChangeFrom}
        min={min}
        max={maxFrom}
        className="w-11rem"
        {...toOptions}
      />
      <span className="text-sm font-medium mx-extra1">–</span>
      <DatePicker
        value={value?.[1]}
        onChange={handleChangeTo}
        min={minTo}
        max={max}
        className="w-11rem"
        {...fromOptions}
      />
      {clearable && (
        <Icon
          icon="brush"
          color="icons-ghost"
          className="ml-extra2 cursor-pointer"
          onClick={onClear}
        />
      )}
    </div>
  );
};

export const FormRangeDatePicker = withFormControl<Props>((props) => {
  return <RangeDatePicker {...props} />;
});
