import { authDomain } from '@auth';
import { userStore } from '@user';
import { useSetRecoilState } from 'recoil';

export const useLogout = () => {
  const setUser = useSetRecoilState(userStore.userState);

  const logout = async () => {
    await authDomain.authService.logout();
    setUser(null);
  };

  return logout;
};
