import { useCodeValidation, usePhoneValidation } from '@form';
import * as yup from 'yup';

export const useValidation = () => {
  const codeValidation = useCodeValidation();
  const phoneValidation = usePhoneValidation();

  return yup.object().shape({
    ...phoneValidation,
    code_id: yup.string().nullable(),
    code: yup.string().when('code_id', {
      is: (codeId: string) => codeId,
      then: codeValidation.code,
    }),
  });
};
