import * as yup from 'yup';
import { useEmailValidation, usePasswordValidation } from '@form';

export const useValidation = () => {
  const passwordValidation = usePasswordValidation();
  const emailValidation = useEmailValidation();

  return yup.object().shape({
    full_name: yup
      .string()
      .trim()
      .required('Введите фамилию, имя и отчество')
      .min(5, 'Количество символов меньше необходимого')
      .max(360, 'Слишком большое количество символов'),
    ...emailValidation,
    ...passwordValidation,
    contract_offer: yup.bool().oneOf([true], ''),
    personal_data_processing: yup.bool().oneOf([true], ''),
  });
};
