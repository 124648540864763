import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '@router';
import { useCurrentUser } from '@user';

export const withDashboardGuard = (DashboardPage: FC) => {
  const GuardedDashboardPage = () => {
    const user = useCurrentUser();

    if (!user) {
      return <Navigate to={routes.auth.path} replace />;
    }

    return <DashboardPage />;
  };

  return GuardedDashboardPage;
};
