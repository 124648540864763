import cn from 'classnames';

import { Button } from '@components';
import { OptionalId } from '@core';
import { surveyDomain } from '@survey';
import { useGenderMap } from '@user';

import styles from './styles.module.scss';

type Props = {
  data: OptionalId<surveyDomain.TargetAudience>;
  interactive?: boolean;
  onEdit?: () => void;
  onRemove?: () => void;
};

export const TargetAudienceView = ({ data, interactive, onEdit, onRemove }: Props) => {
  const { age_from, age_to, city, gender, has_any_children, children } = data;
  const genderMap = useGenderMap();

  const getLabel = (label: string) => `${label}${interactive ? '' : ':'}`;

  return (
    <div className={cn(styles.container, { [styles.interactive]: interactive })}>
      <div className={styles.item}>
        <span>{getLabel('Возраст')}</span>
        <span>
          {age_from}-{age_to} лет
        </span>
      </div>
      <div className={styles.item}>
        <span>{getLabel('Город')}</span>
        {<span>{city ? city.name : 'Отсутствует'}</span>}
      </div>
      <div className={styles.item}>
        <span>{getLabel('Пол')}</span>
        <span>{genderMap[gender ?? 'any']}</span>
      </div>
      {has_any_children && (
        <div className={styles.item}>
          <span>{getLabel('Дети')}</span>
          <span>Есть (Не важно)</span>
        </div>
      )}
      {!!children && !!children.length && (
        <>
          <div className={styles.item}>
            <span>{getLabel('Дети')}</span>
            <span>{children.length}</span>
          </div>
          {children.map(({ gender, birth_year }, index) => (
            <div key={index} className={styles.item}>
              <span>{getLabel(`Ребенок ${index + 1}`)}</span>
              <span>
                {genderMap[gender]}, {birth_year} г.
              </span>
            </div>
          ))}
        </>
      )}
      {interactive && (
        <div className="flex mt-extra2">
          <Button label="Редактировать" className="p-button-secondary" onClick={onEdit} />
          <Button
            label="Удалить"
            className="p-button-danger p-button-text ml-extra2"
            onClick={onRemove}
          />
        </div>
      )}
    </div>
  );
};

TargetAudienceView.defaultProps = {
  interactive: false,
};
