import { layoutsStore } from '@layouts';
import { Button, Sidebar } from '@components';
import { useRecoilState } from 'recoil';
import { FormProvider, useForm } from 'react-hook-form';
import { FormInputNumber, FormRadioButtonGroup, RadioButtonGroupItem } from '@form';
import { balanceDomain, useCreateTransactionMutation } from '@balance';
import { useValidation } from './use-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { getDefaultValues } from './utils';

export type FormValues = {
  transaction_summ: number | null;
  refill_type: balanceDomain.RefillType;
};

export const RefillSidebar = () => {
  const { mutateAsync, isLoading } = useCreateTransactionMutation();
  const validation = useValidation();
  const [refillSidebarVisible, setRefillSidebarVisible] = useRecoilState(
    layoutsStore.refillSidebarVisibleState,
  );
  const [refillSidebarDefaultValues, setRefillSidebarDefaultValues] = useRecoilState(
    layoutsStore.refillSidebarDefaultValuesState,
  );

  const form = useForm<FormValues>({
    defaultValues: getDefaultValues(refillSidebarDefaultValues),
    resolver: yupResolver(validation),
  });
  const { handleSubmit, formState, reset } = form;
  const { isValid } = formState;

  useEffect(() => {
    reset(getDefaultValues(refillSidebarDefaultValues));
  }, [refillSidebarDefaultValues, reset]);

  const onSidebarHide = () => {
    setRefillSidebarVisible(false);
    setRefillSidebarDefaultValues(null);
  };

  const onCancel = () => {
    onSidebarHide();
    reset();
  };

  const onSubmit = async (values: FormValues) => {
    await mutateAsync({
      ...values,
      type: balanceDomain.TransactionType.Refill,
      survey: refillSidebarDefaultValues?.survey,
    } as balanceDomain.CreateTransactionDto);
    reset();
  };

  return (
    <Sidebar
      visible={refillSidebarVisible}
      onHide={onSidebarHide}
      position="right"
      title="Пополение баланса"
      subtitle="Укажите сумму для выставления счета"
    >
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <FormInputNumber
            data-testid="amountRefillBalance"
            name="transaction_summ"
            label="Сумма"
            readOnly={!!refillSidebarDefaultValues}
            currency
          />
          <FormRadioButtonGroup name="refill_type" direction="column" className="my-4">
            <RadioButtonGroupItem
              label="Пополнить как физическое лицо"
              value={balanceDomain.RefillType.Individual}
            />
            <RadioButtonGroupItem
              label="Пополнить через Сбербанк Бизнес Онлайн"
              value={balanceDomain.RefillType.LegalEntity}
            />
          </FormRadioButtonGroup>
          <div className="flex gap-3">
            <Button label="Отмена" className="p-button-info" onClick={onCancel} />
            <Button
              data-testid="submitPay"
              type="submit"
              label="Оплатить"
              disabled={!isValid}
              loading={isLoading}
            />
          </div>
        </form>
      </FormProvider>
    </Sidebar>
  );
};
