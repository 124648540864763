import * as yup from 'yup';
import { usePasswordValidation } from '@form';

export const useValidation = () => {
  const passwordValidation = usePasswordValidation('new_password');

  return yup.object().shape({
    old_password: yup.string().required('Введите старый пароль'),
    ...passwordValidation,
  });
};
