import * as yup from 'yup';

export const useCodeValidation = () => {
  return {
    code: yup
      .string()
      .trim()
      .required('Введите код')
      .test('valid', 'Некорректный код', (value) => !value || value.length === 4),
  };
};
