import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import { notification } from 'core/services/notification';
import { balanceDomain } from '@balance';

export const useSaveCostSettingsMutation = (
  options?: UseMutationOptions<
    balanceDomain.CostSettings,
    unknown,
    Partial<balanceDomain.CostSettings>
  >,
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (payload: Partial<balanceDomain.CostSettings>) => {
      return await balanceDomain.costSettingsRepository.saveCostSettings(payload);
    },
    {
      ...options,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries([balanceDomain.costSettingsResourceUrl]);
        notification({
          header: 'Сохранено',
          content: 'Настройки стоимости сохранены',
          type: 'success',
        });
        options?.onSuccess && options.onSuccess(...args);
      },
    },
  );

  const saveCostSettings = useCallback(
    (payload: Partial<balanceDomain.CostSettings>) => {
      confirmDialog({
        header: 'Сохранение настроек стоимости',
        message: 'Вы действительно хотите сохранить натройки стоимости? ',
        acceptLabel: 'Сохранить',
        rejectLabel: 'Отмена',
        accept: () => mutation.mutateAsync(payload),
      });
    },
    [mutation],
  );

  return {
    saveCostSettings,
    ...mutation,
  };
};
