import { routes } from '@router';
import { userDomain } from '@user';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  PhoneLoginForm,
  PhoneForm,
  CodeForm,
  RegistrationTypeForm,
  IndividualRegistrationForm,
  LegalEntityRegistrationForm,
  RestorePasswordForm,
  EmailLoginForm,
  ResetPasswordByEmailForm,
} from '../../components';

export enum AuthStep {
  Phone = 'phone',
  PhoneConfirmation = 'phone-confirmation',
  PhoneLogin = 'phone-login',
  EmailLogin = 'email-login',
  Code = 'code',
  RegistrationType = 'registration-type',
  IndividualRegistration = 'individual-registration',
  LegalEntityRegistration = 'legal-entity-registration',
  ResetPasswordByPhone = 'reset-password-by-phone',
  ResetPasswordByPhoneConfirmation = 'reset-password-by-phone-confirmation',
  ResetPasswordByPhoneCode = 'reset-password-by-phone-code',
  RestorePasswordByPhone = 'restore-password-by-phone',
  RestorePasswordByEmail = 'restore-password-by-email',
  ResetPasswordByEmail = 'reset-password-by-email',
}

type Credentials = {
  email: string;
  phone: string;
  codeId: string | null;
  ownerType: userDomain.OwnerType;
};

export type AuthContextType = {
  isAdminAuth: boolean;
  credentials: Credentials;
  updateCredentials: (credentials: Partial<Credentials>) => void;
  currentStep: AuthStep;
  setCurrentStep: (step: AuthStep) => void;
  backToFirstStep: () => void;
};

const DEFAULT_STEP: AuthStep = AuthStep.Phone;
const DEFAULT_ADMIN_STEP: AuthStep = AuthStep.EmailLogin;
const DEFAULT_CREDENTIALS: Credentials = {
  email: '',
  phone: '',
  codeId: null,
  ownerType: userDomain.OwnerType.Individual,
};

export const AuthContext = createContext<AuthContextType>({
  isAdminAuth: false,
  credentials: DEFAULT_CREDENTIALS,
  updateCredentials: () => {},
  currentStep: DEFAULT_STEP,
  setCurrentStep: () => {},
  backToFirstStep: () => {},
});

const AuthStepHeaderMap: { [key: string]: { title: string; subtitle?: string } } = {
  [AuthStep.Phone]: {
    title: 'Авторизация',
    subtitle: 'Введите номер телефона\nдля продолжения авторизации',
  },
  [AuthStep.PhoneConfirmation]: {
    title: 'Авторизация',
    subtitle: 'Мы совершим звонок-сброс\nдля подтверждения вашего номера',
  },
  [AuthStep.PhoneLogin]: {
    title: 'Авторизация',
    subtitle: 'Введите пароль для завершения авторизации',
  },
  [AuthStep.EmailLogin]: {
    title: 'Авторизация',
  },
  [AuthStep.Code]: {
    title: 'Авторизация',
    subtitle: 'Впишите в поле 4 последних цифр номера,\nс которого вам позвонили, пример:',
  },
  [AuthStep.RegistrationType]: {
    title: 'Тип пользователя',
    subtitle: 'Выберите тип пользователя:',
  },
  [AuthStep.IndividualRegistration]: {
    title: 'Физическое лицо',
  },
  [AuthStep.LegalEntityRegistration]: {
    title: 'Юридическое лицо',
  },
  [AuthStep.ResetPasswordByPhone]: {
    title: 'Восстановление пароля',
    subtitle: 'Введите номер телефона',
  },
  [AuthStep.ResetPasswordByPhoneConfirmation]: {
    title: 'Восстановление пароля',
    subtitle: 'Мы совершим звонок-сброс\nдля подтверждения вашего номера',
  },
  [AuthStep.ResetPasswordByPhoneCode]: {
    title: 'Восстановление пароля',
    subtitle: 'Впишите в поле 4 последних цифр номера,\nс которого вам позвонили, пример:',
  },
  [AuthStep.RestorePasswordByPhone]: {
    title: 'Восстановление пароля',
  },
  [AuthStep.RestorePasswordByEmail]: {
    title: 'Восстановление пароля',
  },
  [AuthStep.ResetPasswordByEmail]: {
    title: 'Восстановление пароля',
    subtitle: 'Введите e-mail',
  },
};

const AuthPage = () => {
  const { pathname } = useLocation();
  const [codeId] = useQueryParam('code_id', StringParam);
  const isAdminAuth = useMemo(() => pathname.includes(routes.auth.sub.admin.path), [pathname]);
  const defaultStep = useMemo(
    () => (isAdminAuth ? DEFAULT_ADMIN_STEP : DEFAULT_STEP),
    [isAdminAuth],
  );
  const [currentStep, setCurrentStep] = useState<AuthStep>(
    codeId ? AuthStep.RestorePasswordByEmail : defaultStep,
  );
  const [credentials, setCredentials] = useState<Credentials>(DEFAULT_CREDENTIALS);

  const backToFirstStep = useCallback(() => {
    setCurrentStep(defaultStep);
  }, [defaultStep]);

  const updateCredentials = useCallback((credentials: Partial<Credentials>) => {
    setCredentials((prev) => ({ ...prev, ...credentials }));
  }, []);

  useEffect(() => {
    updateCredentials({ codeId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeId]);

  const renderCurrentStep = useCallback(() => {
    switch (currentStep) {
      case AuthStep.ResetPasswordByEmail:
        return <ResetPasswordByEmailForm />;
      case AuthStep.RestorePasswordByEmail:
      case AuthStep.RestorePasswordByPhone:
        return <RestorePasswordForm />;
      case AuthStep.LegalEntityRegistration:
        return <LegalEntityRegistrationForm />;
      case AuthStep.IndividualRegistration:
        return <IndividualRegistrationForm />;
      case AuthStep.RegistrationType:
        return <RegistrationTypeForm />;
      case AuthStep.ResetPasswordByPhoneCode:
      case AuthStep.Code:
        return <CodeForm />;
      case AuthStep.PhoneLogin:
        return <PhoneLoginForm />;
      case AuthStep.EmailLogin:
        return <EmailLoginForm />;
      case AuthStep.ResetPasswordByPhoneConfirmation:
      case AuthStep.ResetPasswordByPhone:
      case AuthStep.PhoneConfirmation:
      case AuthStep.Phone:
      default:
        return <PhoneForm />;
    }
  }, [currentStep]);

  const title = AuthStepHeaderMap[currentStep].title;
  const subtitle = AuthStepHeaderMap[currentStep].subtitle;
  const isCodeStep =
    currentStep === AuthStep.Code || currentStep === AuthStep.ResetPasswordByPhoneCode;

  return (
    <>
      <div className={`text-2xl font-semibold mb-${!subtitle ? '4' : 'extra1'} text-center`}>
        {title}
      </div>
      {subtitle && (
        <div
          className={`text-sm text-secondary text-center pre-line mx-auto mb-${
            isCodeStep ? '2' : '4'
          }`}
        >
          {subtitle}
        </div>
      )}
      <AuthContext.Provider
        value={{
          isAdminAuth,
          credentials,
          updateCredentials,
          currentStep,
          setCurrentStep,
          backToFirstStep,
        }}
      >
        {renderCurrentStep()}
      </AuthContext.Provider>
    </>
  );
};

export default AuthPage;
