import { parseCookies, setCookie, destroyCookie } from 'nookies';
import { IStorage } from './IStorage';

export class CookiesService implements IStorage {
  public async set<T extends string>(
    key: string,
    value: T,
    options?: any
  ): Promise<T> {
    setCookie(null, key, value, { path: '/', ...options });
    return value;
  }

  public async get<T extends string>(key: string): Promise<T | null> {
    return parseCookies()[key] as T;
  }

  public async remove(key: string, options?: any): Promise<void> {
    destroyCookie(null, key, { path: '/', ...options });
    return new Promise((resolve) => resolve());
  }
}

export const cookiesService = new CookiesService();
