import { routes } from '@router';
import { UserForm } from '../../components';
import { useUserDynamicPage } from '../../hooks';

const EditingPage = () => {
  const { data, isLoading } = useUserDynamicPage(routes.dashboard.sub.user.path);

  return <UserForm data={data} isLoading={isLoading} />;
};

export default EditingPage;
