import { FormControlProps, FormSelectButton } from '@form';
import { userDomain } from '@user';
import { SelectItemOptionsType } from 'primereact/selectitem';

const options: SelectItemOptionsType = [
  {
    label: 'Физическое лицо',
    value: userDomain.OwnerType.Individual,
  },
  {
    label: 'Юридическое лицо',
    value: userDomain.OwnerType.LegalEntity,
  },
];

export const OwnerTypeFormControl = (props: Omit<FormControlProps, 'name'>) => {
  return <FormSelectButton name="owner_type" options={options} {...props} />;
};
