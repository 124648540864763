import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { Divider, Icon } from '@components';
import { useLogout } from '@auth';
import { isAvailableByRole } from '@permissions';
import { useCurrentRole } from '@user';

import { Logo } from '../logo';

import { useModel } from './use-model';
import styles from './styles.module.scss';

type Props = {
  withLogo: boolean;
};

export const NavSidebar = ({ withLogo }: Props) => {
  const model = useModel();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const logout = useLogout();
  const currentRole = useCurrentRole();

  return (
    <div className={styles.card}>
      {withLogo && (
        <>
          <Logo className="pt-extra1 px-3" withTitle isLink />
          <Divider className="mt-extra2 mb-3 px-extra1" />
        </>
      )}
      <div className="flex flex-column align-items-start">
        {model.map(({ label, icon, path, adjacentPaths, permissions, showInNav, pathByRole }) => {
          const isShown = isAvailableByRole(currentRole, permissions ?? {});

          if (((currentRole && showInNav?.includes(currentRole)) || isShown) && (label || icon)) {
            const handleNavigate = () =>
              navigate(currentRole && pathByRole?.[currentRole] ? pathByRole[currentRole] : path);
            const itemClassName = cn(styles.item, {
              [styles.active]:
                pathname.includes(path) ||
                adjacentPaths?.some((adjacentPath) => pathname.includes(adjacentPath)),
            });

            return (
              <div
                key={path}
                className={itemClassName}
                data-testid={`nav-${path}`}
                onClick={handleNavigate}
              >
                {icon && <Icon icon={icon} color="icons-ghost" />}
                {label && <span className="text-sm">{label}</span>}
              </div>
            );
          }

          return null;
        })}
      </div>
      <Divider className="my-2 px-extra1" />
      <div className={styles.item} onClick={logout}>
        <Icon icon="sign-out" color="main-error" />
        <span className="text-sm main-error">Выйти</span>
      </div>
    </div>
  );
};

NavSidebar.defaultProps = {
  withLogo: false,
};
