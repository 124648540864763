import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Skeleton } from 'primereact/skeleton';

import { cityDomain } from '@city';
import { Button, Divider, ImageView } from '@components';
import { fileDomain } from '@file';
import {
  FormDatePicker,
  FormImageUpload,
  FormInputPhone,
  FormInputText,
  FormSelectCity,
} from '@form';
import { useGenderMap, userDomain } from '@user';
import { ChildrenFormControl, GenderFormControl } from '@widgets';
import { getDashboardPath, routes } from '@router';
import { formatDate, formatWithPhoneMask } from '@core';

import { useRemoveUserMutation, useSaveUserMutation } from '../../hooks';

import { useValidation } from './use-validation';
import { getDefaultValues } from './utils';
import styles from './styles.module.scss';

type Props = {
  data?: userDomain.User | null;
  isLoading?: boolean;
  displayOnly?: boolean;
};

export type FormValues = {
  full_name: string;
  gender: userDomain.Gender | null;
  birth_date: string | null;
  email: string;
  phone: string;
  city: cityDomain.City | null;
  has_children: boolean;
  children_number: number | null;
  children:
    | {
        gender: userDomain.Gender;
        birth_year: number | null;
      }[]
    | null;
  passport_front_photo: fileDomain.ServerFile | null;
  passport_registration_photo: fileDomain.ServerFile | null;
  created_at: string;
};

export const UserForm = ({ data, isLoading, displayOnly }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const genderMap = useGenderMap();
  const validation = useValidation();
  const form = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: getDefaultValues(data),
    resolver: yupResolver(validation),
  });

  const { handleSubmit, formState, reset } = form;
  const { isValid, isDirty } = formState;

  const {
    saveUser,
    isLoading: isSaving,
    errorMessage,
    clearError,
  } = useSaveUserMutation({
    onSuccess: () => {
      navigate(getDashboardPath(routes.dashboard.sub.user.path));
    },
  });
  const { removeUser, isLoading: isRemoving } = useRemoveUserMutation({
    onSuccess: () => {
      navigate(getDashboardPath(routes.dashboard.sub.user.path));
    },
  });

  useEffect(() => {
    reset(getDefaultValues(data));
  }, [data, reset]);

  const onEdit = () => {
    navigate(
      getDashboardPath([
        routes.dashboard.sub.user.path,
        routes.dashboard.sub.user.sub.editing.path,
        id as string,
      ]),
    );
  };

  const onRemove = () => {
    removeUser(Number(id));
  };

  const onSubmit = (values: FormValues) => {
    const { phone, has_children, children_number, children, created_at, ...rest } = values;
    saveUser({
      id: data?.id,
      children_number: has_children ? (children_number as number) : 0,
      children: has_children ? (children as userDomain.Child[]) : null,
      ...(rest as Partial<userDomain.User>),
    });
  };

  const blockClassName = displayOnly ? 'col-12' : 'col-6';

  const UserFormDivider = displayOnly && <Divider className="my-3" />;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid formgrid p-fluid">
          {displayOnly && (
            <div className="col-12">
              <div className={styles.subtitle}>Основные данные</div>
            </div>
          )}
          <div className={blockClassName}>
            <FormInputText
              name="full_name"
              label="ФИО"
              isLoading={isLoading}
              displayOnly={displayOnly}
            />
            {UserFormDivider}
            <GenderFormControl
              isLoading={isLoading}
              displayOnly={displayOnly}
              displayFormat={(value) => genderMap[value as userDomain.Gender]}
            />
            {UserFormDivider}
            <FormDatePicker
              name="birth_date"
              label="Дата рождения"
              className="mt-extra2"
              isLoading={isLoading}
              displayOnly={displayOnly}
              displayFormat={formatDate}
            />
            {UserFormDivider}
            <FormInputText
              name="email"
              label="E-mail"
              isLoading={isLoading}
              displayOnly={displayOnly}
              error={errorMessage}
              errorTimeout={4}
              onErrorHide={clearError}
            />
            {UserFormDivider}
            <FormInputPhone
              name="phone"
              label="Номер телефона"
              displayFormat={(value) => formatWithPhoneMask(value)}
              displayOnly
            />
            {UserFormDivider}
            <FormSelectCity
              name="city"
              label="Город"
              isLoading={isLoading}
              displayOnly={displayOnly}
              displayFormat={(value) => value?.name}
            />
            {displayOnly && (
              <>
                <Divider className="my-3" />
                <FormDatePicker
                  name="created_at"
                  label="Дата"
                  isLoading={isLoading}
                  displayFormat={(value) => formatDate(value, true)}
                  displayOnly
                />
              </>
            )}
          </div>
          <div className="col-12">
            {displayOnly ? (
              <div className={styles.subtitle}>Дети</div>
            ) : (
              <Divider className="mt-2 mb-3" />
            )}
          </div>
          <div className={blockClassName}>
            {isLoading ? (
              <Skeleton className="mb-3" />
            ) : (
              <ChildrenFormControl displayOnly={displayOnly} />
            )}
          </div>
          <div className="col-12">
            {displayOnly ? (
              <div className={styles.subtitle}>Паспортные данные</div>
            ) : (
              <Divider className="col-12 my-3" />
            )}
          </div>
          <div className={blockClassName}>
            {displayOnly ? (
              <ImageView url={data?.passport_front_photo?.url} label="Фотография лицевой стороны" />
            ) : (
              <FormImageUpload
                name="passport_front_photo"
                label="Фотография лицевой стороны паспорта"
                maxSize={10}
                isLoading={isLoading}
              />
            )}
          </div>
          <Divider className={`col-12 mb-3 mt-${displayOnly ? '4' : '0'}`} />
          <div className={blockClassName}>
            {displayOnly ? (
              <ImageView
                url={data?.passport_registration_photo?.url}
                label="Фотография стороны с пропиской"
              />
            ) : (
              <FormImageUpload
                name="passport_registration_photo"
                label="Фотография паспорта со стороны с пропиской"
                maxSize={10}
                isLoading={isLoading}
              />
            )}
          </div>
          {!displayOnly && <Divider className="col-12 mt-2" />}
        </div>
        {displayOnly ? (
          <div className="flex justify-content-between mt-5">
            <Button label="Редактировать" disabled={isRemoving} onClick={onEdit} />
            <Button
              label="Удалить"
              loading={isRemoving}
              className="p-button-secondary"
              onClick={onRemove}
            />
          </div>
        ) : (
          <Button
            type="submit"
            label="Сохранить"
            disabled={!isValid || !isDirty}
            loading={isSaving}
            className="mt-2"
          />
        )}
      </form>
    </FormProvider>
  );
};

UserForm.defaultProps = {
  data: null,
  isLoading: false,
  displayOnly: false,
};
