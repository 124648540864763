import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { balanceDomain } from '@balance';
import { Button, Divider } from '@components';
import { formatWithNumberMask } from '@core';
import { FormInputNumber } from '@form';
import { getDashboardPath, routes } from '@router';

import { useValidation } from './use-validation';
import { getDefaultValues } from './utils';
import { useSaveCostSettingsMutation } from '../../hooks';

type Props = {
  data?: balanceDomain.CostSettings | null;
  isLoading?: boolean;
  displayOnly?: boolean;
};

export type FormValues = Partial<
  Pick<balanceDomain.CostSettings, 'vote_markup' | 'vote_reward' | 'withdrawal_amount_min'>
>;

const formatValue = (value: any) => {
  return formatWithNumberMask(value, true, true);
};

export const CostSettingsForm = ({ data, isLoading, displayOnly }: Props) => {
  const navigate = useNavigate();
  const validation = useValidation();
  const isNonEditable = displayOnly || (!!data && !data.is_editable);

  const form = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: getDefaultValues(data),
    resolver: yupResolver(validation),
  });

  const { handleSubmit, formState, reset } = form;
  const { isValid, isDirty } = formState;

  const { saveCostSettings } = useSaveCostSettingsMutation({
    onSuccess: () => {
      navigate(getDashboardPath(routes.dashboard.sub.management.path));
    },
  });

  useEffect(() => {
    reset(getDefaultValues(data));
  }, [data, reset]);

  const onEditClick = () => {
    navigate(getDashboardPath(routes.dashboard.sub.cost.path));
  };

  const onSubmit = (values: FormValues) => {
    saveCostSettings({ id: data?.id, ...values });
  };

  const CostSettingsFormDivider = isNonEditable && <Divider className="my-3" />;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid formgrid p-fluid">
          <div className={isNonEditable ? 'col-12' : 'col-6'}>
            <FormInputNumber
              name="vote_reward"
              label="Вознаграждение за голос"
              isLoading={isLoading}
              displayOnly={isNonEditable}
              displayFormat={formatValue}
              scale={2}
              currency
            />
            {CostSettingsFormDivider}
            <FormInputNumber
              name="vote_markup"
              label="Наценка за голос (комиссия площадки)"
              isLoading={isLoading}
              displayOnly={isNonEditable}
              displayFormat={formatValue}
              scale={2}
              currency
            />
            {CostSettingsFormDivider}
          </div>
          <div className={displayOnly ? 'col-12' : 'col-6'}>
            <FormInputNumber
              name="withdrawal_amount_min"
              label="Минимальная сумма для вывода"
              isLoading={isLoading}
              displayOnly={displayOnly}
              displayFormat={formatValue}
              scale={2}
              currency
            />
          </div>
        </div>
        <div className="mt-3">
          {displayOnly ? (
            <Button label="Редактировать" onClick={onEditClick} />
          ) : (
            <Button type="submit" label="Сохранить" disabled={!isDirty || !isValid || isLoading} />
          )}
        </div>
      </form>
    </FormProvider>
  );
};

CostSettingsForm.defaultProps = {
  data: null,
  isLoading: false,
  displayOnly: false,
};
