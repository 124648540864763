import { QueryParamConfig } from 'use-query-params';

export enum NullParamEnum {
  Null = '$null',
  NotNull = '$not:$null',
}
export type NullParamType = NullParamEnum;

export const NullParam: QueryParamConfig<NullParamType> = {
  encode: (nullParam: NullParamType) => nullParam,
  decode: (nullStr: string | (string | null)[] | null | undefined) => nullStr as NullParamType,
};
