import { useQuery } from '@tanstack/react-query';
import { userDomain } from '@user';
import { useLoadUserQueryParams } from './use-load-user-query-params';

export const useLoadUserQuery = () => {
  const [queryParams, setQueryParams] = useLoadUserQueryParams();
  return {
    queryParams,
    setQueryParams,
    ...useQuery([userDomain.userResourceUrl, queryParams], () => {
      return userDomain.userRepository.load({
        ...queryParams,
        'filter.role': userDomain.UserRole.Client,
      });
    }),
  };
};
