import { Suspense } from 'react';
import { Route } from 'react-router-dom';

import { Loader } from '@components';
import { RolePermissions, withPageGuardByRole } from '@permissions';

import { Route as RouteType, routes } from './routes';
import { userDomain } from '@user';

export const renderRoutes = (
  routes: { [key: string]: RouteType },
  parentPermissions: RolePermissions = {},
) => {
  return (
    <>
      {Object.values(routes).map(
        ({ path, param, layout: Layout, page: Page, permissions = {}, sub }) => {
          const GuardedPage = Page
            ? withPageGuardByRole(Page, { ...parentPermissions, ...permissions })
            : Page;

          return (
            <Route
              key={path}
              path={`${path}${param ? `/:${param}` : ''}`}
              element={
                Layout && (
                  <Suspense fallback={<Loader fullContainer />}>
                    <Layout />
                  </Suspense>
                )
              }
            >
              {GuardedPage && (
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader fullContainer />}>
                      <GuardedPage />
                    </Suspense>
                  }
                />
              )}
              {renderRoutes(sub, permissions)}
            </Route>
          );
        },
      )}
    </>
  );
};

export const getDashboardPath = (
  subPath: string | number | Array<string | number> | null | undefined,
) => {
  if (!subPath) return routes.dashboard.path;
  return `${routes.dashboard.path}/${Array.isArray(subPath) ? subPath.join('/') : subPath}`;
};

export const getLastSubPath = (path: string, count = 1) => {
  const pathArr = path.split('/');

  return pathArr
    .slice(-count)
    .filter((subPath) => isNaN(Number(subPath)))
    .join('/');
};

export const getMainPath = (currentRole?: userDomain.UserRole) => {
  if (!currentRole) return routes.auth.path;
  switch (currentRole) {
    case userDomain.UserRole.Admin:
    case userDomain.UserRole.Moderator:
      return getDashboardPath(routes.dashboard.sub.survey.path);
    case userDomain.UserRole.SurveyOwner:
      return getDashboardPath(routes.dashboard.sub.profile.path);
    default:
      return '/';
  }
};
