import { cityDomain } from '@city';
import { notification } from 'core/services/notification';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { confirmDialog } from 'primereact/confirmdialog';
import { useCallback } from 'react';

export const useRemoveCityMutation = (options?: UseMutationOptions<void, unknown, number>) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (id: number) => {
      return cityDomain.cityRepository.delete({ id });
    },
    {
      ...options,
      onSuccess: async (...args) => {
        queryClient.removeQueries([cityDomain.cityRepository, String(args[1])]);
        await queryClient.invalidateQueries([cityDomain.cityResourceUrl]);
        notification({
          header: 'Удалено',
          content: 'Город удален',
          type: 'success',
        });
        options?.onSuccess && options.onSuccess(...args);
      },
    },
  );
  const removeCity = useCallback(
    (id: number) => {
      confirmDialog({
        header: 'Удаление города',
        message: 'Вы действительно хотите удалить город? ',
        acceptLabel: 'Удалить',
        rejectLabel: 'Отмена',
        accept: () => mutation.mutateAsync(id),
      });
    },
    [mutation],
  );
  return {
    removeCity,
    ...mutation,
  };
};
