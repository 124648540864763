import { authDomain } from '@auth';
import { useMutation } from '@tanstack/react-query';
import { userStore } from '@user';
import { useSetRecoilState } from 'recoil';

export const useRegister = () => {
  const setUser = useSetRecoilState(userStore.userState);

  return useMutation(
    (payload: authDomain.RegistrationDto) => {
      return authDomain.authService.register(payload);
    },
    {
      onSuccess: (data) => {
        setUser(data);
      },
    },
  );
};
