import { decodeString, encodeString, QueryParamConfig } from 'use-query-params';
import { UserStatus } from '../enums';

export type UserStatusParamType = UserStatus | null | undefined;

export const UserStatusParam: QueryParamConfig<UserStatusParamType> = {
  encode: (status: UserStatusParamType) => encodeString(status),

  decode: (statusStr: string | (string | null)[] | null | undefined) =>
    decodeString(statusStr) as UserStatus,
};
