import {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import {cityDomain} from '@city';
import {Button, Sidebar} from '@components';
import {OptionalId} from '@core';
import {FormInputNumber, FormLabel, FormSelectCity} from '@form';
import {surveyDomain} from '@survey';
import {userDomain} from '@user';
import {ChildrenFormControl, ChildrenFormSettingsVariant, GenderFormControl} from '@widgets';

import {useValidation} from './use-validation';
import {getDefaultValues} from './utils';
import {MAX_AGE, MIN_AGE} from './constants';

type Props = {
  data?: OptionalId<surveyDomain.TargetAudience> | null;
  visible: boolean;
  onHide: () => void;
  onSubmit: (values: OptionalId<surveyDomain.TargetAudience>) => void;
};

export type FormValues = {
  age_from: number | null;
  age_to: number | null;
  city: cityDomain.City | null;
  gender: userDomain.Gender | null;
  has_any_children: boolean;
  children_settings: ChildrenFormSettingsVariant | null;
  children_number: number | null;
  children:
    | {
        gender: userDomain.Gender;
        birth_year: number | null;
      }[]
    | null;
};

export const TargetAudienceSidebar = ({ data, visible, onHide, onSubmit }: Props) => {
  const validation = useValidation();
  const form = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: getDefaultValues(data),
    resolver: yupResolver(validation),
  });

  const { formState, reset, watch, getValues } = form;
  const { isValid, isDirty } = formState;

  const ageFrom = watch('age_from');
  const ageTo = watch('age_to');
  const maxFrom = Math.max(ageTo || MAX_AGE, ageFrom || 0);
  const minTo = ageFrom || MIN_AGE;

  useEffect(() => {
    reset(getDefaultValues(data));
  }, [data, reset]);

  const onCancel = () => {
    reset();
    onHide();
  };

  const handleSubmit = () => {
    const values = getValues();
    const { children_number, children, children_settings, ...rest } = values;
    const preparedValues = {
      ...rest,
      children_number: ChildrenFormSettingsVariant.Settings ? children_number : 0,
      children: children_settings === ChildrenFormSettingsVariant.Settings ? children : null,
    } as surveyDomain.TargetAudience;
    onSubmit(preparedValues);
    reset(values);
    onHide();
  };

  return (
    <Sidebar
      visible={visible}
      title="Целевая аудитория"
      onHide={onHide}
    >
      <FormProvider {...form}>
        <form>
          <div className="p-fluid">
            <FormLabel label="Возраст" className="mb-2 pl-extra1" />
            <div className="grid formgrid">
              <div className="col-6">
                <FormInputNumber
                  data-testid="inputAgeFrom"
                  name="age_from"
                  prefix="от "
                  min={MIN_AGE}
                  max={maxFrom}
                />
              </div>
              <div className="col-6">
                <FormInputNumber
                  data-testid="inputAgeTo"
                  name="age_to"
                  prefix="до "
                  min={minTo}
                  max={MAX_AGE}
                />
              </div>
            </div>
            <FormSelectCity data-testid="selectCity" name="city" label="Город" />
            <GenderFormControl className="my-4" />
            <ChildrenFormControl />
          </div>
          <div className="flex mt-5">
            <Button
              data-testid="submitSurvey"
              label="Сохранить"
              disabled={!isValid || !isDirty}
              onClick={handleSubmit}
            />
            <Button label="Отмена" className="p-button-text ml-extra2" onClick={onCancel} />
          </div>
        </form>
      </FormProvider>
    </Sidebar>
  );
};
