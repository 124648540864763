import { Button } from '@components';
import { getErrorMessage, getErrorStatus, RequestErrors } from 'core';
import { FormInputMask } from '@form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withAuthForm } from '../../hocs';
import { useConfirmCode, useResendCode, useRetryTimer } from '../../hooks';
import { AuthStep } from '../../pages';
import { useValidation } from './use-validation';

type FormValues = {
  code: string;
};

export const CodeForm = withAuthForm(
  ({ credentials, setCurrentStep, backToFirstStep, updateCredentials, currentStep }) => {
    const validation = useValidation();
    const [error, setError] = useState<string | null>(null);
    const { seconds, formattedTime } = useRetryTimer();
    const { mutateAsync: confirmCode, isLoading: isConfirming } = useConfirmCode();
    const { mutateAsync: resendCode } = useResendCode();
    const form = useForm<FormValues>({
      defaultValues: {
        code: '',
      },
      resolver: yupResolver(validation),
    });
    const { handleSubmit, formState } = form;
    const { isValid } = formState;

    const clearError = () => {
      setError(null);
    };

    const onResendCode = async () => {
      const { id: codeId, credential } = await resendCode({ credential: credentials.phone });
      updateCredentials({ codeId, phone: credential });
    };

    const onSubmit = async (values: FormValues) => {
      try {
        if (credentials.codeId) {
          await confirmCode({ ...values, code_id: credentials.codeId });
          setCurrentStep(
            currentStep === AuthStep.ResetPasswordByPhoneCode
              ? AuthStep.RestorePasswordByPhone
              : AuthStep.RegistrationType,
          );
        }
      } catch (err) {
        if (getErrorStatus(err) === RequestErrors.WrongRequest) {
          setError(getErrorMessage(err));
        }
      }
    };

    return (
      <div>
        <div className="text-sm text-secondary text-center mb-4">
          + 7 (***) *** <span className="text-primary font-medium">12 34</span>
        </div>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInputMask
              name="code"
              label="Код подтверждения"
              mask="9999"
              slotChar=""
              error={error}
              errorTimeout={4}
              onErrorHide={clearError}
              clearable
            />
            <div className="flex mt-5 mb-4">
              <Button
                icon="chevron-left"
                className="p-button-info mr-3"
                onClick={backToFirstStep}
              />
              <Button
                type="submit"
                label="Подтвердить"
                data-testid="confirmCode"
                loading={isConfirming}
                disabled={!isValid}
              />
            </div>
            {seconds > 0 ? (
              <div className="text-sm text-secondary text-center">
                Позвонить снова через{' '}
                <span className="text-accent font-medium">{formattedTime}</span>
              </div>
            ) : (
              <Button
                label="Позвонить снова"
                className="p-button-text mx-auto"
                onClick={onResendCode}
              />
            )}
          </form>
        </FormProvider>
      </div>
    );
  },
);
