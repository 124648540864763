import { cityDomain } from '@city';
import { NullParamEnum } from '@core';
import { withFormControl } from '../with-form-control';
import { Select, SelectProps } from './select';

export const SelectCity = (props: SelectProps<cityDomain.City>) => {
  const loadOptions = (searchValue: string, page: number) => {
    return cityDomain.cityRepository.load({
      page,
      limit: 10,
      'filter.name': searchValue || undefined,
      'filter.deleted_at': NullParamEnum.Null,
    });
  };

  return (
    <Select
      loadOptions={loadOptions}
      valueKey="id"
      labelKey="name"
      paginate
      defaultOptions
      {...props}
    />
  );
};

export const FormSelectCity = withFormControl<SelectProps<cityDomain.City>>(SelectCity);
