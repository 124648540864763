import { useEmailValidation, useFullnameValidation } from '@form';
import { useChildrenFormControlValidation } from 'widgets/children-form-control';
import * as yup from 'yup';

export const useValidation = () => {
  const fullnameValidation = useFullnameValidation();
  const emailValiadtion = useEmailValidation();
  const childrenFormControlValidation = useChildrenFormControlValidation();

  return yup.object().shape({
    ...fullnameValidation,
    gender: yup.string().nullable(),
    birth_date: yup.string().nullable().required('Выберите дату рождения'),
    ...emailValiadtion,
    city: yup.object().nullable().required('Выберите город'),
    ...childrenFormControlValidation,
    passport_front_photo: yup
      .object()
      .nullable()
      .required('Выберите фотографию лицевой стороны паспорта'),
    passport_registration_photo: yup
      .object()
      .nullable()
      .required('Выберите фотографию паспорта со стороны с пропиской'),
  });
};
