import { formatWithMask } from './format-with-mask';

export const formatWithNumberMask = (
  value: string | number | undefined | null,
  padFractionalZeros: boolean = true,
  currency: boolean = false,
  unit?: string,
): string | null | undefined => {
  if (!value && typeof value !== 'number') return value;

  return (
    formatWithMask(
      {
        mask: Number,
        thousandsSeparator: ' ',
        padFractionalZeros,
      },
      String(value),
    ) + (unit ? ` ${unit}` : currency ? ' ₽' : '')
  );
};
