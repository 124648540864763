import { memo, useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { SelectItemOptionsType } from 'primereact/selectitem';

import {
  FormDatePicker,
  FormInputNumber,
  FormRadioButtonGroup,
  FormSelectButton,
  RadioButtonGroupItem,
} from '@form';
import { useShortGenderMap, userDomain } from '@user';
import { DisplayValue, Divider } from '@components';
import { ChildrenFormSettingsVariant } from './ChildrenFormSettingsVariant';
import { plural } from '@core';

export * from './ChildrenFormSettingsVariant';

export const useChildrenFormControlValidation = () => {
  return {
    has_children: yup.bool(),
    children_number: yup
      .number()
      .nullable()
      .when('children_settings', {
        is: ChildrenFormSettingsVariant.Settings,
        then: yup
          .number()
          .transform((value) => (value === Number(value) ? value : null))
          .nullable()
          .required('Введите количество детей')
          .min(1, 'Минимальное количество детей 1'),
      }),
    children: yup
      .array()
      .nullable()
      .when('children_settings', {
        is: ChildrenFormSettingsVariant.Settings,
        then: yup
          .array()
          .of(
            yup.object().shape({
              gender: yup.string().required('Выберите пол'),
              birth_year: yup
                .number()
                .transform((value) => (value === Number(value) ? value : null))
                .nullable()
                .required('Выберите год рождения'),
            }),
          )
          .min(yup.ref('children_number' ?? 0)),
      }),
  };
};

type Props = {
  displayOnly?: boolean;
};

export const ChildrenFormControl = memo(({ displayOnly }: Props) => {
  const { control, setValue, watch, getValues } = useFormContext();
  const genderMap = useShortGenderMap();

  const { fields: children } = useFieldArray({
    control,
    name: 'children',
    keyName: 'key',
    shouldUnregister: false,
  });

  const childrenSettings = watch('children_settings');
  const childrenArray = watch('children');

  const genderOptions: SelectItemOptionsType = useMemo(
    () => [
      {
        label: genderMap[userDomain.Gender.Any],
        value: userDomain.Gender.Any,
      },
      {
        label: genderMap[userDomain.Gender.Male],
        value: userDomain.Gender.Male,
      },
      {
        label: genderMap[userDomain.Gender.Female],
        value: userDomain.Gender.Female,
      },
    ],
    [genderMap],
  );

  const onChildrenCountChange = useCallback(
    (childrenCount: number) => {
      const getEmptyChildren = (count: number) => {
        return Array.from({ length: count }, () => ({
          gender: userDomain.Gender.Male,
          birth_year: null,
        }));
      };

      if (!children.length) {
        setValue('children', getEmptyChildren(childrenCount));
      }

      if (!childrenCount) {
        setValue('children', null);
      }

      if (children.length !== childrenCount) {
        setValue(
          'children',
          children.length > childrenCount
            ? children.slice(-childrenCount)
            : [...children, ...getEmptyChildren(childrenCount - children.length)],
        );
      }
    },
    [setValue, children],
  );

  const onChildrenOptionChange = (value: ChildrenFormSettingsVariant) => {
    let hasAnyChildrenValue = false;
    if (value === ChildrenFormSettingsVariant.HasAny) {
      hasAnyChildrenValue = true;
    }
    setValue('has_any_children', hasAnyChildrenValue);
  };

  if (displayOnly) {
    if (children && children.length) {
      return (
        <div className="flex flex-column mb-3">
          {children.map((child, index) => (
            <>
              <DisplayValue
                label={`Ребенок №${index + 1}`}
                // @ts-ignore
                value={`${genderMap[child.gender]}, ${child.birth_year} г.`}
              />
              {index !== children.length - 1 && <Divider className="my-3" />}
            </>
          ))}
        </div>
      );
    }

    return <div className="pb-3">–</div>;
  }

  return (
    <>
      <FormRadioButtonGroup name="children_settings" label="Дети" onChange={onChildrenOptionChange}>
        <RadioButtonGroupItem
          data-testid="hasAnyChildren"
          label="Не важно"
          value={ChildrenFormSettingsVariant.HasAny}
        />
        <RadioButtonGroupItem
          data-testid="noChildren"
          label="Нет"
          value={ChildrenFormSettingsVariant.No}
        />
        <RadioButtonGroupItem
          data-testid="haveChildren"
          label="Да"
          value={ChildrenFormSettingsVariant.Settings}
        />
      </FormRadioButtonGroup>
      {childrenSettings === ChildrenFormSettingsVariant.Settings && (
        <>
          <FormInputNumber
            name="children_number"
            data-testid="childrenCountSurvey"
            label="Количество детей"
            onChange={(value) => onChildrenCountChange(value)}
            min={1}
            className="mt-4"
          />
          {children.map(({ key }, index) => {
            const year = childrenArray[index].birth_year;
            const old = dayjs().year() - year;
            const oldText =
              year != null ? `${old} ${plural(['год', 'года', 'лет'], old)} и моложе` : '';

            return (
              <div key={key}>
                <Divider className="mb-3 mt-2" />
                <div className="grid formgrid p-fluid">
                  <div className="col-6">
                    <FormSelectButton
                      name={`children.${index}.gender`}
                      label="Пол"
                      data-testid="childrenGenderSurvey"
                      options={genderOptions}
                    />
                  </div>
                  <div className="col-6">
                    <FormDatePicker
                      type="year"
                      name={`children.${index}.birth_year`}
                      label="Год рождения"
                      data-testid="birthYearSurvey"
                      max={dayjs().year()}
                      help={oldText}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
});
