import { FormLabel } from '@form';
import { Button } from 'components/button';
import { useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { useDisableWindowScroll } from '@core';

type Props = {
  label?: string | null;
  url?: string | null;
  alt?: string | null;
};

export const ImageView = ({ label, url, alt }: Props) => {
  const [isFullShown, setIsFullShown] = useState(false);

  useDisableWindowScroll(isFullShown);

  const toggleFullShown = () => setIsFullShown((prev) => !prev);

  const overlayClassName = cn(styles.overlay, {
    [styles.visible]: isFullShown,
  });

  return (
    <>
      <div className="flex flex-column">
        {label && <FormLabel label={label} className="mb-3" />}
        {url ? (
          <>
            <div className="flex">
              <div className={styles.image}>
                <img src={url} alt={alt ?? undefined} />
              </div>
              <Button
                className="p-button-secondary ml-4"
                icon="zoom-in"
                onClick={toggleFullShown}
              />
            </div>
            <div className={overlayClassName} onClick={toggleFullShown}>
              <img src={url} alt={alt ?? undefined} />
            </div>
          </>
        ) : (
          <div>–</div>
        )}
      </div>
    </>
  );
};
