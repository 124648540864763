import { Divider as PrimeDivider, DividerProps } from 'primereact/divider';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string;
} & DividerProps;

export const Divider = ({ className, layout, ...other }: Props) => {
  const dividerClassName = cn(className, {
    [styles.horizontal]: layout === 'horizontal',
    [styles.vertical]: layout === 'vertical',
  });

  return (
    <div className={dividerClassName}>
      <PrimeDivider layout={layout} {...other} />
    </div>
  );
};

Divider.defaultProps = {
  layout: 'horizontal',
};
