import { InputText, InputTextProps } from 'primereact/inputtext';
import { withFormControl, FormControlProps } from '@form';
import { useState } from 'react';

export const FormInputPassword = withFormControl<InputTextProps>((props) => {
  return <InputText type="password" autoComplete="off" {...props} />;
});

export const FormInputPasswordToggle = (props: FormControlProps & InputTextProps) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const togglePasswordShown = () => setIsPasswordShown((prev) => !prev);

  return (
    <FormInputPassword
      type={isPasswordShown ? 'text' : 'password'}
      icon={isPasswordShown ? 'eye-closed' : 'eye-opened'}
      iconColor="icons-ghost"
      onIconClick={togglePasswordShown}
      {...props}
    />
  );
};
