import { useCurrentRole } from '@user';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMainPath } from './utils';

export const useNavigateToMain = () => {
  const currentRole = useCurrentRole();
  const navigate = useNavigate();
  return useCallback(() => {
    navigate(getMainPath(currentRole));
  }, [currentRole, navigate]);
};
