import { balanceDomain } from '@balance';
import { FormValues } from '.';

export const getDefaultValues = (data?: balanceDomain.CostSettings | null): FormValues => {
  return {
    vote_markup: data?.vote_markup ?? undefined,
    vote_reward: data?.vote_reward ?? undefined,
    withdrawal_amount_min: data?.withdrawal_amount_min ?? undefined,
  };
};
