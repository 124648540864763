import { fileDomain } from '@file';

export const downloadByUrl = (file: fileDomain.ServerFile) => {
  const link = document.createElement('a');
  link.setAttribute('href', file.url);
  link.setAttribute('download', file.title + file.extension);
  link.setAttribute('_target', 'blank');
  link.click();
  link.remove();
};
