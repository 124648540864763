import { cityDomain } from '@city';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export const useLoadCityByIdQuery = (id: string, options?: UseQueryOptions<cityDomain.City>) => {
  return useQuery<cityDomain.City>(
    [cityDomain.cityResourceUrl, id],
    () => {
      return cityDomain.cityRepository.loadById(id);
    },
    {
      ...options,
    },
  );
};
