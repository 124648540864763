import { OptionalId } from '@core';
import { userDomain } from '@user';
import { FormValues } from '.';

export const getDefaultValues = (data?: OptionalId<userDomain.User> | null): FormValues => {
  return {
    full_name: data?.full_name ?? '',
    gender: data?.gender ?? null,
    birth_date: data?.birth_date ?? null,
    email: data?.email ?? '',
    phone: data?.phone ?? '',
    city: data?.city ?? null,
    has_children: data ? !!data.children?.length : true,
    children_number: data?.children?.length ?? 0,
    children: data?.children ?? [
      {
        gender: userDomain.Gender.Male,
        birth_year: null,
      },
    ],
    passport_front_photo: data?.passport_front_photo ?? null,
    passport_registration_photo: data?.passport_registration_photo ?? null,
    created_at: data?.created_at ?? '',
  };
};
