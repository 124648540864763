import { Button, Divider } from '@components';
import { formatWithNumberMask, formatWithPhoneMask } from '@core';
import { layoutsStore } from '@layouts';
import { routes } from '@router';
import { useCurrentUser } from '@user';
import { Logo } from '@widgets';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styles from './styles.module.scss';

export const BalancePanel = () => {
  const { full_name, phone, balance } = useCurrentUser();
  const { pathname } = useLocation();
  const isRefillButtonDisabled = pathname.includes(routes.dashboard.sub.balance.path);
  const setRefillSidebarVisible = useSetRecoilState(layoutsStore.refillSidebarVisibleState);

  const onRefillBalanceClick = () => {
    setRefillSidebarVisible(true);
  };

  return (
    <div className={styles.card}>
      <Logo withTitle isLink />
      <Divider className="mt-3 mb-extra1" />
      <h4 className="text-lg font-medium mb-1">{full_name}</h4>
      <span className="text-sm text-third">{formatWithPhoneMask(phone)}</span>
      <Divider className="my-extra1" />
      <span className="text-sm text-third">Ваш баланс:</span>
      <h1 className="text-4xl line-height-2 font-semibold mb-extra2">
        {formatWithNumberMask(balance, false, true)}
      </h1>
      <Button
        label="Пополнить"
        data-testid="refillBalance"
        className="p-button-help"
        disabled={isRefillButtonDisabled}
        onClick={onRefillBalanceClick}
      />
    </div>
  );
};
