import { Card, TransactionHistory } from '../../components';

const BalancePage = () => {
  return (
    <>
      <Card />
      <TransactionHistory />
    </>
  );
};

export default BalancePage;
