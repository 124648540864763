import { TabView, TabViewTabChangeParams, TabViewProps, TabPanel } from 'primereact/tabview';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type TabItem<T = string> = {
  key: T;
  label: string;
  visible?: boolean;
  [key: string]: any;
};

type Props<T = string> = {
  items: TabItem<T>[];
  active: any;
  onSelect: (value: TabItem<T>) => void;
} & Omit<TabViewProps, 'onSelect'>;

export const Tabs = <T extends any>({ items, active, onSelect, ...other }: Props<T>) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const filteredItems = useMemo(() => items.filter(({ visible = true }) => visible), [items]);

  useEffect(() => {
    setActiveIndex(filteredItems.findIndex(({ key }) => key === active));
  }, [active, filteredItems]);

  const handleTabSelect = useCallback(
    (e: TabViewTabChangeParams) => {
      onSelect(filteredItems[e.index]);
    },
    [filteredItems, onSelect],
  );

  return (
    <TabView activeIndex={activeIndex} onTabChange={handleTabSelect} scrollable {...other}>
      {filteredItems.map(({ label, key }) => {
        return <TabPanel key={key as string} header={label} />;
      })}
    </TabView>
  );
};
