import { cityDomain } from '@city';
import { Table } from '@components';
import { BaseListResponse, useDefaultActions } from '@core';
import { routes } from '@router';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRemoveCityMutation } from '../../hooks';
import { useColumns } from './use-columns';

type Props = {
  data: BaseListResponse<cityDomain.City> | undefined;
  isLoading: boolean;
  onPageChange: (page: number) => void;
  hasActions?: boolean;
};

export const CityTable = ({ data, isLoading, onPageChange, hasActions }: Props) => {
  const navigate = useNavigate();
  const { removeCity } = useRemoveCityMutation();

  const onEdit = useCallback(
    (item: cityDomain.City) => {
      navigate(`${routes.dashboard.sub.city.sub.editing.path}/${item.id}`);
    },
    [navigate],
  );

  const onRemove = useCallback(
    (item: cityDomain.City) => {
      removeCity(item.id);
    },
    [removeCity],
  );

  const onRowClick = useCallback(
    (e: DataTableRowClickEventParams) => {
      navigate(`${routes.dashboard.sub.city.sub.view.path}/${e.data.id}`);
    },
    [navigate],
  );

  const columns = useColumns();
  const actions = useDefaultActions<cityDomain.City>({ onEdit, onRemove });

  return (
    <Table
      columns={columns}
      value={data}
      isLoading={isLoading}
      meta={data?.meta}
      actions={hasActions ? actions : null}
      onPageChange={onPageChange}
      onRowClick={onRowClick}
    />
  );
};

CityTable.defaultProps = {
  hasActions: false,
};
