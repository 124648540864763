import { notification } from 'core/services/notification';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { userDomain } from '@user';
import { confirmDialog } from 'primereact/confirmdialog';
import { useCallback } from 'react';

export const useBlockUserMutation = (
  options?: UseMutationOptions<userDomain.User, unknown, number | string>,
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (id: number | string) => {
      return userDomain.userRepository.block(id);
    },
    {
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries([userDomain.userResourceUrl]);
        notification({
          header: 'Заблокирован',
          content: 'Пользователь заблокирован',
          type: 'success',
        });
        options?.onSuccess && options.onSuccess(...args);
      },
    },
  );

  const blockUser = useCallback(
    (id: number | string) => {
      confirmDialog({
        header: 'Блокировка',
        message: 'Вы действительно хотите заблокировать пользователя?',
        acceptLabel: 'Заблокировать',
        rejectLabel: 'Отмена',
        accept: () => mutation.mutateAsync(id),
      });
    },
    [mutation],
  );

  return {
    blockUser,
    ...mutation,
  };
};
