import { BaseListRepository } from 'core/classes';
import { CreateTransactionDto } from '../dtos';
import { Transaction } from '../interfaces';
import { transactionResource } from '../resources/TransactionResource';
import { TransactionLoadParams } from '../types';

class TransactionRepository extends BaseListRepository<Transaction, TransactionLoadParams> {
  public createTransaction(
    payload: CreateTransactionDto,
  ): Promise<Transaction & { redirect: string }> {
    return this.resource().create(payload);
  }
}

export const transactionRepository = new TransactionRepository(transactionResource);
