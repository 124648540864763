import { Button } from '@components';
import { getErrorMessage, getErrorStatus, RequestErrors } from 'core';
import { FormInputText } from '@form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userDomain } from '@user';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useCurrentUser, useEditableForm, useUpdateProfileMutation } from '../../hooks';
import { useValidation } from './use-validation';

type FormValues = {
  full_name: string;
  email: string;
};

export const PersonalDataForm = () => {
  const [error, setError] = useState<string | null>(null);
  const { full_name, email } = useCurrentUser();
  const validation = useValidation();

  const { form, editableForm, isEditable, toggleEditable } = useEditableForm<FormValues>(
    userDomain.EditableForm.PersonalData,
    {
      mode: 'onChange',
      defaultValues: {
        full_name,
        email,
      },
      resolver: yupResolver(validation),
    },
  );

  const { handleSubmit, formState } = form;
  const { isValid, isDirty } = formState;

  const { mutateAsync: updateProfile } = useUpdateProfileMutation();

  const clearError = () => {
    setError(null);
  };

  const onSubmit = async (values: FormValues) => {
    try {
      await updateProfile(values);
      toggleEditable(values);
    } catch (err) {
      if (getErrorStatus(err) === RequestErrors.WrongRequest) {
        setError(getErrorMessage(err));
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid formgrid p-fluid">
          <FormInputText
            name="full_name"
            label="Фамилия, имя, отчество"
            displayOnly={!isEditable}
            className="col"
          />
          <FormInputText
            name="email"
            label="E-mail"
            displayOnly={!isEditable}
            className="col"
            error={error}
            errorTimeout={4}
            onErrorHide={clearError}
          />
        </div>
        <div className="flex mt-2">
          {isEditable ? (
            <Button
              type="submit"
              label="Сохранить"
              className="p-button-secondary"
              disabled={!isValid || !isDirty}
            />
          ) : (
            <Button
              key="button"
              type="button"
              label="Редактировать"
              className="p-button-secondary"
              onClick={() => toggleEditable()}
              disabled={!!editableForm}
            />
          )}
          {isEditable && (
            <Button
              label="Отмена"
              className="p-button-text p-button-danger ml-extra2"
              onClick={() => toggleEditable()}
            />
          )}
        </div>
      </form>
    </FormProvider>
  );
};
