import { QueryKey, useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import { notification, RequestErrors, useServerError } from '@core';
import { userDomain } from '@user';
import { usePatchCompanyMutation } from '@company';

type NotificationSettings = {
  confirmTitle?: string;
  confirmMessage?: string;
  notificationMessage?: string;
};

export const useSaveUserMutation = (
  options?: UseMutationOptions<userDomain.User, unknown, Partial<userDomain.User>>,
  notificationSettings?: NotificationSettings,
  queryKey?: QueryKey,
) => {
  const queryClient = useQueryClient();
  const {
    error: errorMessage,
    clearError,
    resolveError,
  } = useServerError(RequestErrors.WrongRequest);
  const { mutateAsync: updateCompany } = usePatchCompanyMutation();
  const mutation = useMutation(
    async (payload: Partial<userDomain.User>) => {
      const { company, ...rest } = payload;

      if (company?.id) {
        await updateCompany(company);
      }

      const entity = company?.id ? rest : payload;

      return payload.id
        ? await userDomain.userRepository.patch(entity)
        : await userDomain.userRepository.create(entity);
    },
    {
      ...options,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(queryKey ?? [userDomain.userResourceUrl]);
        notification({
          header: 'Сохранено',
          content:
            notificationSettings?.notificationMessage ?? 'Информация о пользователе сохранена',
          type: 'success',
        });
        options?.onSuccess && options.onSuccess(...args);
      },
      onError: (...args) => {
        resolveError(args[0]);
        options?.onError && options.onError(...args);
      },
    },
  );

  const saveUser = useCallback(
    (payload: Partial<userDomain.User>) => {
      confirmDialog({
        header: notificationSettings?.confirmTitle ?? 'Сохранение пользователя',
        message:
          notificationSettings?.confirmMessage ??
          'Вы действительно хотите сохранить пользователя? ',
        acceptLabel: 'Сохранить',
        rejectLabel: 'Отмена',
        accept: () => mutation.mutateAsync(payload),
      });
    },
    [mutation, notificationSettings],
  );

  return {
    saveUser,
    errorMessage,
    clearError,
    ...mutation,
  };
};
