import { lazy, LazyExoticComponent, ComponentType } from "react";
import { UserRole } from "modules/user/domain";
import { IconsId } from "@icons";
import { RolePermissions } from "@permissions";

export type Route = {
  path: string;
  pathByRole?: { [key: string]: string };
  param?: string;
  adjacentPaths?: string[];
  page: LazyExoticComponent<ComponentType> | null;
  layout?: LazyExoticComponent<ComponentType> | null;
  label?: string;
  icon?: IconsId;
  permissions?: RolePermissions;
  sub: { [key: string]: Route };
  showInNav?: [UserRole];
};

export const routes: { [key: string]: Route } = {
  "privacy-policy": {
    path: "/privacy-policy",
    page: lazy(
      () => import("modules/support/ui/components/privacy-policy/index"),
    ),
    sub: {},
  },
  auth: {
    path: "/auth",
    layout: lazy(() => import("layouts/auth")),
    page: lazy(() => import("modules/auth/ui/pages/auth")),
    sub: {
      admin: {
        path: "admin",
        page: lazy(() => import("modules/auth/ui/pages/auth")),
        sub: {},
      },
    },
  },
  dashboard: {
    path: "/dashboard",
    layout: lazy(() => import("layouts/dashboard")),
    page: null,
    sub: {
      profile: {
        path: "profile",
        layout: lazy(() => import("layouts/profile")),
        page: lazy(() => import("modules/user/ui/pages/profile")),
        label: "Профиль",
        icon: "user",
        permissions: {
          oneOf: UserRole.SurveyOwner,
        },
        sub: {
          company: {
            path: "company",
            page: lazy(() => import("modules/user/ui/pages/company")),
            sub: {},
          },
        },
      },
      user: {
        path: "user",
        page: lazy(() => import("modules/user/ui/pages/table")),
        label: "Пользователи",
        icon: "user",
        permissions: {
          oneOf: [UserRole.Admin, UserRole.Moderator],
        },
        sub: {
          editing: {
            path: "edit",
            param: "id",
            page: lazy(() => import("modules/user/ui/pages/editing")),
            sub: {},
          },
          view: {
            path: "view",
            param: "id",
            page: lazy(() => import("modules/user/ui/pages/view")),
            sub: {},
          },
        },
      },
      survey: {
        path: "survey",
        page: lazy(() => import("modules/survey/ui/pages/table")),
        label: "Опросы",
        icon: "form",
        sub: {
          creation: {
            path: "create",
            page: lazy(() => import("modules/survey/ui/pages/creation")),
            sub: {},
          },
          editing: {
            path: "edit",
            param: "id",
            page: lazy(() => import("modules/survey/ui/pages/editing")),
            sub: {},
          },
          view: {
            path: "view",
            param: "id",
            page: lazy(() => import("modules/survey/ui/pages/view")),
            sub: {},
          },
        },
      },
      balance: {
        path: "balance",
        page: lazy(() => import("modules/balance/ui/pages/balance")),
        label: "Баланс",
        icon: "wallet",
        permissions: {
          oneOf: UserRole.SurveyOwner,
        },
        sub: {},
      },
      support: {
        path: "support",
        page: lazy(() => import("modules/support/ui/pages/form")),
        label: "Поддержка",
        icon: "headphones",
        permissions: {
          oneOf: UserRole.SurveyOwner,
        },
        sub: {},
      },
      customer: {
        path: "customer",
        page: lazy(() => import("modules/customer/ui/pages/table")),
        label: "Заказчики",
        icon: "diagram",
        permissions: {
          oneOf: [UserRole.Admin, UserRole.Moderator],
        },
        sub: {
          editing: {
            path: "edit",
            param: "id",
            page: lazy(() => import("modules/customer/ui/pages/editing")),
            sub: {},
          },
          view: {
            path: "view",
            param: "id",
            page: lazy(() => import("modules/customer/ui/pages/view")),
            sub: {},
          },
        },
      },
      management: {
        path: "management",
        pathByRole: { [UserRole.Moderator]: "management/distribution" },
        page: lazy(() => import("modules/balance/ui/pages/view")),
        adjacentPaths: ["cost"],
        layout: lazy(() => import("layouts/management")),
        label: "Управление",
        icon: "gear",
        permissions: { oneOf: [UserRole.Admin] },
        showInNav: [UserRole.Moderator],
        sub: {
          distribution: {
            path: "distribution",
            page: lazy(() => import("modules/distribution/ui/pages/form")),
            sub: {},
            permissions: { oneOf: [UserRole.Moderator, UserRole.Admin] },
          },
          anomaly: {
            path: "anomaly",
            page: lazy(() => import("modules/survey/ui/pages/anomaly")),
            sub: {},
            permissions: { oneOf: [UserRole.Moderator, UserRole.Admin] },
          },
        },
      },
      cost: {
        path: "cost",
        page: lazy(() => import("modules/balance/ui/pages/editing")),
        permissions: { oneOf: [UserRole.Admin] },
        sub: {},
      },
      transaction: {
        path: "transaction",
        page: lazy(() => import("modules/balance/ui/pages/table")),
        label: "Операции",
        icon: "transaction",
        permissions: {
          oneOf: [UserRole.Admin, UserRole.Moderator],
        },
        sub: {},
      },
      moderator: {
        path: "moderator",
        page: lazy(() => import("modules/moderator/ui/pages/table")),
        label: "Модераторы",
        icon: "users",
        permissions: {
          oneOf: [UserRole.Admin],
        },
        sub: {
          creation: {
            path: "create",
            page: lazy(() => import("modules/moderator/ui/pages/creation")),
            sub: {},
          },
          editing: {
            path: "edit",
            param: "id",
            page: lazy(() => import("modules/moderator/ui/pages/editing")),
            sub: {},
          },
          view: {
            path: "view",
            param: "id",
            page: lazy(() => import("modules/moderator/ui/pages/view")),
            sub: {},
          },
        },
      },
      city: {
        path: "city",
        page: lazy(() => import("modules/city/ui/pages/table")),
        label: "Города",
        icon: "location",
        permissions: { oneOf: [UserRole.Admin] },
        sub: {
          creation: {
            path: "create",
            page: lazy(() => import("modules/city/ui/pages/creation")),
            sub: {},
          },
          editing: {
            path: "edit",
            param: "id",
            page: lazy(() => import("modules/city/ui/pages/editing")),
            sub: {},
          },
          view: {
            path: "view",
            param: "id",
            page: lazy(() => import("modules/city/ui/pages/view")),
            sub: {},
          },
        },
      },
    },
  },
};
