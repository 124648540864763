import { balanceDomain } from '@balance';
import { TableColumn } from '@components';
import { formatDate, formatWithNumberMask } from '@core';
import { getDashboardPath, routes } from '@router';
import { surveyDomain } from '@survey';
import { userDomain } from '@user';
import { Tag } from 'primereact/tag';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTransactionStatusMap, useTransactionTypeMap } from '../../maps';

export const useColumns = (): TableColumn<balanceDomain.Transaction>[] => {
  const navigate = useNavigate();
  const TransactionTypeMap = useTransactionTypeMap();
  const TransactionStatusMap = useTransactionStatusMap();

  const onUserClick = useCallback(
    ({ id, role }: userDomain.User) => {
      const path = role === userDomain.UserRole.SurveyOwner ? 'customer' : 'user';
      navigate(
        getDashboardPath([
          routes.dashboard.sub[path].path,
          routes.dashboard.sub[path].sub.view.path,
          id,
        ]),
      );
    },
    [navigate],
  );

  const onSurveyClick = useCallback(
    ({ id }: surveyDomain.Survey) => {
      navigate(
        getDashboardPath([
          routes.dashboard.sub.survey.path,
          routes.dashboard.sub.survey.sub.view.path,
          id,
        ]),
      );
    },
    [navigate],
  );

  return useMemo(
    () => [
      {
        field: 'type',
        header: 'Тип',
        body: ({ type }) => TransactionTypeMap[type].type,
      },
      {
        field: 'user',
        header: 'ФИО',
        body: ({ user }) =>
          user ? (
            <span className="text-accent cursor-pointer" onClick={() => onUserClick(user)}>
              {user.full_name}
            </span>
          ) : (
            '–'
          ),
      },
      {
        field: 'survey',
        header: 'ID опроса',
        body: ({ survey }) =>
          survey ? (
            <span className="text-accent cursor-pointer" onClick={() => onSurveyClick(survey)}>
              {survey.id}
            </span>
          ) : (
            '–'
          ),
      },
      {
        field: 'status',
        header: 'Статус',
        body: ({ status }) => <Tag value={TransactionStatusMap[status].label} />,
      },
      {
        field: 'transaction_summ',
        header: 'Сумма',
        align: 'right',
        body: ({ type, transaction_summ }) => (
          <span style={{ color: TransactionTypeMap[type].color }}>
            {TransactionTypeMap[type].sign} {formatWithNumberMask(transaction_summ, false, true)}
          </span>
        ),
      },
      {
        field: 'created_at',
        header: 'Дата операции',
        body: ({ created_at }) => formatDate(created_at),
      },
    ],
    [TransactionTypeMap, TransactionStatusMap, onSurveyClick, onUserClick],
  );
};
