import { cityDomain } from '@city';
import { NullParam, NullParamEnum } from 'core/params';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

const defaultQueryParams: cityDomain.CityLoadParamsConfig = {
  page: withDefault(NumberParam, 1),
  limit: withDefault(NumberParam, 10),
  'filter.name': StringParam,
  'filter.deleted_at': withDefault(NullParam, NullParamEnum.Null),
};

export const useLoadCityQueryParams = () => {
  return useQueryParams(defaultQueryParams);
};
