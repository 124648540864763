import { balanceDomain } from '@balance';

export const useTransactionTypeMap = () => {
  return {
    [balanceDomain.TransactionType.Refill]: {
      type: 'Пополнение',
      sign: '+',
      description: 'Пополнение средств',
      color: '#179917',
    },
    [balanceDomain.TransactionType.WriteOff]: {
      type: 'Списание',
      sign: '-',
      description: 'Списание средств',
      color: '#D43434',
    },
    [balanceDomain.TransactionType.Refund]: {
      type: 'Возврат',
      sign: '+',
      description: 'Возврат средств',
      color: '#179917',
    },
    [balanceDomain.TransactionType.Withdrawal]: {
      type: 'Вывод',
      sign: '-',
      description: 'Вывод средств',
      color: '#D43434',
    },
  };
};
