import { useCallback, useMemo } from 'react';
import { Controller, ControllerProps, useFormContext, useFormState } from 'react-hook-form';
import cn from 'classnames';
import { FormControlProps, FormControlRendererComponent, WithFormControlType } from './types';
import { generateFieldId, isAllowShowError } from './utils';
import styles from './styles.module.scss';
import { FormError, FormLabel } from '@form';
import { Icon } from '@components';
import { Skeleton } from 'primereact/skeleton';

const FormControlRenderer: FormControlRendererComponent = (Component, props) => {
  const {
    name,
    fieldId,
    field,
    label,
    help,
    disabled,
    error,
    errorTimeout,
    onErrorHide,
    showErrorAfter,
    onChange,
    onBlur,
    withSpacing,
    inline,
    icon,
    iconPos,
    iconColor,
    onIconClick,
    clearable,
    displayOnly,
    displayFormat,
    className,
    required,
    colon,
    isLoading,
    ...other
  } = props;

  const { error: fieldError, isTouched, isDirty } = props.fieldState;
  const { onChange: fieldOnChange, onBlur: fieldOnBlur, value, ...fieldProps } = field;
  const { isSubmitted, isSubmitting } = useFormState();

  const isFieldErrorShown = isAllowShowError(showErrorAfter, isSubmitted, isTouched, isDirty);
  const hasError = !!((isFieldErrorShown && fieldError?.message) || error);

  const handleChange = useCallback(
    (e: any) => {
      fieldOnChange(e);
      onChange && onChange(e);
    },
    [fieldOnChange, onChange],
  );

  const handleBlur = useCallback(() => {
    fieldOnBlur();
    onBlur && onBlur();
  }, [fieldOnBlur, onBlur]);

  const onClear = useCallback(() => {
    fieldOnChange(undefined);
  }, [fieldOnChange]);

  const isIconPosRight = iconPos === 'right';

  return (
    <div
      className={cn(className, 'field mb-0', {
        'mb-3': withSpacing,
        'flex align-items-center flex-wrap': inline,
      })}
    >
      {!!label && (
        <FormLabel
          label={label}
          htmlFor={fieldId}
          required={required}
          colon={colon}
          className={cn(!inline ? (displayOnly ? 'mb-1' : 'mb-2') : 'mb-0', {
            'pl-extra1': !displayOnly,
            'text-primary flex-order-1': inline,
          })}
        />
      )}

      {isLoading ? (
        <Skeleton className={cn(!displayOnly && styles.loading)} />
      ) : displayOnly ? (
        <div>{value ? (displayFormat ? displayFormat(value) : value) : '–'}</div>
      ) : (
        <>
          <div className={cn('relative', inline && 'flex align-items-center')}>
            <Component
              value={value}
              id={fieldId}
              disabled={disabled || isSubmitting}
              onChange={handleChange}
              onBlur={handleBlur}
              className={cn({
                [`p${isIconPosRight ? 'r' : 'l'}-6`]: icon,
                'pr-6': clearable,
                'pr-8': clearable && icon && isIconPosRight,
              })}
              {...(other as typeof props)}
              {...fieldProps}
            />
            {clearable && (
              <Icon
                icon="cross"
                color="icons-ghost"
                className={cn(styles.clear, { [styles.withIcon]: icon && isIconPosRight })}
                onClick={onClear}
              />
            )}
            {icon && (
              <Icon
                icon={icon}
                color={iconColor}
                className={cn(styles.icon, styles[iconPos!], onIconClick && 'cursor-pointer')}
                onClick={onIconClick}
              />
            )}
          </div>
          {help && <small className="text-third block pl-extra1">{help}</small>}
          {hasError && (
            <small className={cn('main-error block mt-0 pl-extra1', { 'w-full': inline })}>
              {error ? (
                <FormError message={error} showTimeout={errorTimeout} onHide={onErrorHide} />
              ) : (
                <span>{fieldError?.message}</span>
              )}
            </small>
          )}
        </>
      )}
    </div>
  );
};

const defaultProps: Partial<FormControlProps> = {
  showErrorAfter: 'blur',
  withSpacing: true,
  inline: false,
  iconPos: 'right',
  clearable: false,
  displayOnly: false,
  required: false,
  colon: false,
  isLoading: false,
};

export const withFormControl: WithFormControlType = (WrappedControl) => {
  return (props) => {
    const mergedProps = { ...defaultProps, ...props };
    const fieldId = useMemo(() => generateFieldId(mergedProps.name), [mergedProps.name]);

    const { control } = useFormContext();
    const renderHookFormControl: ControllerProps['render'] = ({ field, fieldState, formState }) => {
      return FormControlRenderer(WrappedControl, {
        fieldId,
        field,
        fieldState,
        formState,
        ...mergedProps,
      });
    };

    return <Controller name={props.name} control={control} render={renderHookFormControl} />;
  };
};
