import * as yup from 'yup';

export const useValidation = () => {
  return yup.object().shape({
    reject_reason: yup
      .string()
      .trim()
      .required('Укажите причину отклонения')
      .min(3, 'Слишком короткая причина')
      .max(450, 'Слишком длинная причина'),
  });
};
