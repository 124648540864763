import { balanceDomain } from '@balance';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useLoadTransactionQueryParams } from './use-load-transaction-query-params';
import { getDateRangeParam } from '@core';

export const useLoadTransactionInfiniteQuery = (defaultItemsPerPage: number = 10) => {
  const [queryParams, setQueryParams] = useLoadTransactionQueryParams(defaultItemsPerPage);
  const { 'filter.created_at': createdAt } = queryParams;
  return {
    queryParams,
    setQueryParams,
    ...useInfiniteQuery(
      [balanceDomain.transactionResourceUrl, queryParams],
      ({ pageParam = 1 }) => {
        return balanceDomain.transactionRepository.load({
          ...queryParams,
          page: pageParam,
          sortBy: 'created_at:DESC',
          // @ts-ignore
          'filter.created_at': getDateRangeParam(createdAt),
        });
      },
      {
        refetchInterval: 5000,
        keepPreviousData: true,
        getNextPageParam: (lastPage) => {
          const meta = lastPage.meta!;
          const nextPage = meta.currentPage + 1;
          return nextPage > meta.totalPages ? undefined : nextPage;
        },
      },
    ),
  };
};
