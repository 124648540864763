import { Button } from '@components';
import { RangeDatePickerChangeParams, RangeDatePicker } from '@form';
import { UIEventHandler, useState } from 'react';
import { useLoadTransactionInfiniteQuery } from '../../hooks';
import { TransactionInfo } from '../transaction-info';

const INITIAL_LIMIT = 3;
const DEFAULT_LIMIT = 10;

export const TransactionHistory = () => {
  const [defaultLimit, setDefaultLimit] = useState(INITIAL_LIMIT);
  const {
    data,
    queryParams,
    setQueryParams,
    isFetching,
    isRefetching,
    fetchNextPage,
    hasNextPage,
  } = useLoadTransactionInfiniteQuery(defaultLimit);
  const isLoading = isFetching && !isRefetching;

  const flatData = data?.pages.flat();
  const hasData = !!flatData?.length;

  const onCreatedAtChange = (e: RangeDatePickerChangeParams) => {
    setQueryParams({ 'filter.created_at': e.value });
  };

  const onShowAllClick = () => {
    setDefaultLimit(DEFAULT_LIMIT);
  };

  const onListScroll: UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    const { scrollHeight, scrollTop, clientHeight } = currentTarget;
    if (scrollHeight - clientHeight - scrollTop < 50) {
      fetchNextPage();
    }
  };

  const isInitialLimit = defaultLimit === INITIAL_LIMIT;
  const isShowAllButtonShown = hasData && isInitialLimit && hasNextPage;

  return (
    <div className="flex flex-column flex-1 pt-5">
      <h4 className="font-medium mb-2">История операций</h4>
      <p className="text-third text-sm">Следите за статусом своих операций</p>
      <RangeDatePicker
        value={queryParams['filter.created_at']}
        onChange={onCreatedAtChange}
        className="my-4"
        clearable
      />
      <div className="flex flex-column flex-1 max-h-30rem overflow-scroll" onScroll={onListScroll}>
        {flatData && !hasData && (
          <div className="font-medium text-center my-auto">Нет информации об операциях</div>
        )}
        {flatData
          ?.slice(0, isInitialLimit ? defaultLimit : flatData.length)
          .map(({ id, ...rest }) => (
            <TransactionInfo key={id} {...rest} />
          ))}
        {isLoading && (
          <>
            {Array.from({ length: defaultLimit }, (_, i) => ({ id: i })).map(({ id, ...rest }) => (
              <TransactionInfo key={id} isLoading={isLoading} {...rest} />
            ))}
          </>
        )}
      </div>
      {isShowAllButtonShown && (
        <Button
          label="Показать все операции"
          icon="chevron-down"
          iconPos="right"
          className="p-button-text mt-3 align-self-start"
          onClick={onShowAllClick}
        />
      )}
    </div>
  );
};
