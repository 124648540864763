import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { userDomain } from '@user';

export const useLoadUserByIdQuery = (
  id: string,
  options?: UseQueryOptions<userDomain.User>,
  mainQueryKey?: string,
) => {
  return useQuery<userDomain.User>(
    [mainQueryKey ?? userDomain.userResourceUrl, id],
    () => {
      return userDomain.userRepository.loadById(id);
    },
    {
      ...options,
    },
  );
};
