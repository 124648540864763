import { balanceDomain } from '@balance';
import { Icon } from '@components';
import { formatDate, formatWithNumberMask } from '@core';
import { Skeleton } from 'primereact/skeleton';
import { useTransactionStatusMap, useTransactionTypeMap } from '../../maps';
import styles from './styles.module.scss';
import cn from 'classnames';

type Props = Partial<Omit<balanceDomain.Transaction, 'id'>> & {
  isLoading?: boolean;
};

export const TransactionInfo = ({
  type,
  transaction_summ,
  status,
  created_at,
  isLoading,
}: Props) => {
  const transactionTypeMap = useTransactionTypeMap();
  const transactionStatusMap = useTransactionStatusMap();

  const {
    label: statusLabel,
    icon: statusIcon,
    className: statusClassName,
  } = transactionStatusMap[status as balanceDomain.TransactionStatus] ?? {};

  const Loader = <Skeleton className={styles.skeleton} />;

  return (
    <div className={styles.container}>
      <div className="flex">
        <div className={styles.icon}>{isLoading ? Loader : <Icon icon="bag" />}</div>
        <div className={styles.info}>
          {isLoading ? (
            Loader
          ) : (
            <div className="flex align-items-center">
              <span className="font-medium">
                {transactionTypeMap[type as balanceDomain.TransactionType].sign}
                {formatWithNumberMask(transaction_summ, false, true)}
              </span>
              <div className={cn(styles.status, styles[statusClassName])}>
                <Icon icon={statusIcon} />
                <span>{statusLabel}</span>
              </div>
            </div>
          )}
          {isLoading ? (
            Loader
          ) : (
            <p className="text-third text-sm">
              {transactionTypeMap[type as balanceDomain.TransactionType].description}
            </p>
          )}
        </div>
      </div>
      {isLoading ? (
        Loader
      ) : (
        <span className="text-third text-xs line-height-3">{formatDate(created_at)}</span>
      )}
    </div>
  );
};

TransactionInfo.defaultProps = {
  isLoading: false,
};
