import { GroupBase, OptionsOrGroups, PropsValue } from 'react-select';
import { SelectProps } from './select';

type GetTargetValueOptions<T> = Pick<
  SelectProps<T>,
  'options' | 'value' | 'valueKey' | 'inValue'
>;

type GetTargetOptionsOptions<T> = Pick<
  SelectProps<T>,
  'loadOptions' | 'defaultOptions' | 'options'
> & {
  loadedOptions: OptionsOrGroups<T, GroupBase<T>>;
};

type ReturnTargetValue<T> = PropsValue<T> | undefined;

export const getTargetOptions = <T extends unknown>({
  loadOptions,
  defaultOptions,
  options,
  loadedOptions,
}: GetTargetOptionsOptions<T>) => {
  if (loadOptions) {
    if (loadedOptions) {
      return loadedOptions;
    } else if (Array.isArray(defaultOptions)) {
      return defaultOptions;
    } else {
      return [];
    }
  }

  return options ?? [];
};

export const getTargetValue = <T extends unknown>({
  options,
  value,
  valueKey,
  inValue,
}: GetTargetValueOptions<T>): ReturnTargetValue<T> => {
  if (options && valueKey && inValue) {
    const flatOptions = options.flatMap((option) =>
      option && typeof option === 'object' && 'options' in option
        ? option.options
        : option
    );

    if (Array.isArray(value)) {
      return flatOptions.filter((option) =>
        value.includes(option[valueKey])
      ) as ReturnTargetValue<T>;
    }

    return flatOptions.find(
      (option) => option[valueKey] === value
    ) as ReturnTargetValue<T>;
  }

  return value;
};
