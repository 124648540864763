import { useMutation } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { userStore } from '@user';
import { authDomain, authStore } from '@auth';

export const useCheckAuth = () => {
  const setUser = useSetRecoilState(userStore.userState);
  const setAuthorized = useSetRecoilState(authStore.checkAuthSelector);

  return useMutation(() => authDomain.authService.checkAuth(), {
    onSuccess: (data) => {
      setUser(data);
      setAuthorized({ isChecked: true, isAuthorized: true });
    },
    onError: () => {
      setAuthorized({ isChecked: true, isAuthorized: false });
    },
  });
};
