import { cityDomain } from '@city';
import { TableColumn } from '@components';
import { useMemo } from 'react';

export const useColumns = (): TableColumn<cityDomain.City>[] => {
  return useMemo(
    () => [
      {
        field: 'name',
        header: 'Город',
      },
      {
        field: 'timezone',
        header: 'Часовой пояс (UTC) ',
        body: ({ timezone }) => timezone.label.replace('UTC', ''),
      },
    ],
    [],
  );
};
