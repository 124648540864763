import { Divider } from '@components';
import { PasswordForm, PersonalDataForm, PhoneForm } from '../../components';

const ProfilePage = () => {
  return (
    <>
      <PersonalDataForm />
      <Divider />
      <PasswordForm />
      <Divider />
      <PhoneForm />
    </>
  );
};

export default ProfilePage;
