import { RejectModal } from '@widgets';
import { UpdateStatusDto, User, UserStatus } from 'modules/user/domain';
import { useUpdateUserStatusMutation } from '../../hooks/use-update-user-status-mutation';

type Props = {
  visible: boolean;
  user: User;
  onHide: () => void;
};

export const RejectUserModal = ({ visible, user, onHide }: Props) => {
  const { mutateAsync: rejectSurvey, isLoading: isRejecting } = useUpdateUserStatusMutation({
    onSuccess: () => {
      onHide();
    },
  });

  const onReject = (values: Pick<UpdateStatusDto, 'reject_reason'>) => {
    rejectSurvey({
      id: user.id,
      payload: { status: UserStatus.Declined, reject_reason: values.reject_reason },
    });
  };

  return (
    <RejectModal
      visible={visible}
      header="Отклонение регистрации пользователя"
      label="Укажите причину отклонения регистрации пользователя"
      isLoading={isRejecting}
      onSubmit={onReject}
      onHide={onHide}
    />
  );
};
