import { selector } from 'recoil';
import { authState } from '../atoms';
import { authCodeSentAtSelectorKey } from '../constants';

export const codeSentAtSelector = selector({
  key: authCodeSentAtSelectorKey,
  get: ({ get }) => {
    const { codeSentAt } = get(authState);
    return codeSentAt;
  },
  set: ({ get, set }, value) => {
    set(authState, {
      ...get(authState),
      codeSentAt: value as string,
    });
  },
});
