import { Button, Divider } from '@components';
import { getLastSubPath } from '@router';
import { BalancePanel, Footer, NavSidebar, RefillSidebar } from '@widgets';
import { useIsAvailableByRole, withDashboardGuard } from '@permissions';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { userDomain } from '@user';
import { useRecoilValue } from 'recoil';
import { layoutsStore } from '@layouts';
import cn from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import { useDashboardHeaderMap } from './use-dashboard-header-map';

const DashboardLayout = withDashboardGuard(() => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isBalancePanelShown = useIsAvailableByRole({ oneOf: userDomain.UserRole.SurveyOwner });
  const { title: dynamicTitle, isLoading } = useRecoilValue(layoutsStore.dynamicTitleState);
  const DashboardHeaderMap = useDashboardHeaderMap();

  const {
    title,
    subtitle,
    divider = true,
    back = false,
    dynamic = false,
  } = DashboardHeaderMap[getLastSubPath(pathname, pathname.split('/').length - 2)] ?? {};

  const onBack = () => {
    navigate(-1);
  };

  const titleClassName = cn(styles.title, {
    'text-third': dynamic,
  });

  const subtitleClassName = cn('text-sm text-third', {
    'mt-2': !!title,
  });

  const dividerClassName = cn('mb-0', {
    'mt-4': !!title || !!subtitle,
  });

  return (
    <>
      <div className={styles.layout}>
        <div className={styles.sidebar}>
          {isBalancePanelShown && <BalancePanel />}
          <NavSidebar withLogo={!isBalancePanelShown} />
        </div>
        <div className={styles.main}>
          {back && (
            <Button
              label="Вернуться назад"
              icon="arrow-left"
              className="p-button-text mb-2 align-self-start"
              onClick={onBack}
            />
          )}
          <div className="mb-4">
            {!!title && (
              <h2 className={titleClassName}>
                <span className="white-space-nowrap">{title}</span>
                {dynamic && (
                  <>
                    {isLoading ? (
                      <Skeleton className="ml-2 mt-1" />
                    ) : (
                      <span className="text-primary ml-2 text-overflow-ellipsis white-space-nowrap overflow-hidden">
                        {dynamicTitle}
                      </span>
                    )}
                  </>
                )}
              </h2>
            )}
            {!!subtitle && <p className={subtitleClassName}>{subtitle}</p>}
            {divider && <Divider className={dividerClassName} />}
          </div>
          <Outlet />
        </div>
        <RefillSidebar />
      </div>
      <Footer />
    </>
  );
});

export default DashboardLayout;
