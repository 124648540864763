import { balanceDomain } from '@balance';
import { IconsId } from '@icons';
import { useMemo } from 'react';

export const useTransactionStatusMap = (): {
  [key: string]: { label: string; icon: IconsId; className: string };
} => {
  return useMemo(
    () => ({
      [balanceDomain.TransactionStatus.Pending]: {
        label: 'В ожидании',
        icon: 'info-circle',
        className: 'pending',
      },
      [balanceDomain.TransactionStatus.Completed]: {
        label: 'Завершена',
        icon: 'check-circle',
        className: 'success',
      },
      [balanceDomain.TransactionStatus.Rejected]: {
        label: 'Сбой',
        icon: 'warning',
        className: 'error',
      },
    }),
    [],
  );
};
