import { Color } from '@core';
import cn from 'classnames';
import { MouseEventHandler } from 'react';
import { IconsId } from '../../assets/icons';

type Props = {
  icon: IconsId;
  color?: Color;
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
};

export const Icon = ({ icon, color, className, disabled, onClick }: Props) => {
  const iconClassName = cn(`icon-${icon} inline-flex`, color, className, {
    'cursor-pointer': !!onClick,
    'p-disabled': disabled,
  });

  return <i className={iconClassName} onClick={onClick} />;
};

Icon.defaultProps = {
  color: 'text-primary',
  disabled: false,
};
