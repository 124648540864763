import * as localforage from 'localforage';
import { IStorage } from './IStorage';

export class StorageService implements IStorage {
  constructor(private storage: LocalForage) {}

  public async set<T>(key: string, value: T): Promise<T> {
    await this.storage.setItem(key, value);
    return value;
  }

  public async get<T>(key: string): Promise<T | null> {
    return await this.storage.getItem(key);
  }

  public async remove(key: string): Promise<void> {
    await this.storage.removeItem(key);
  }
}

export const storageService = new StorageService(localforage);
