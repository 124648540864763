import { notification } from 'core/services/notification';
import { QueryKey, useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { userDomain } from '@user';
import { confirmDialog } from 'primereact/confirmdialog';
import { useCallback } from 'react';

type NotificationSettings = {
  confirmTitle?: string;
  confirmMessage?: string;
  notificationMessage?: string;
};

export const useRemoveUserMutation = (
  options?: UseMutationOptions<void, unknown, number>,
  notificationSettings?: NotificationSettings,
  queryKey?: QueryKey,
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (id: number) => {
      return userDomain.userRepository.delete({ id });
    },
    {
      ...options,
      onSuccess: async (...args) => {
        queryClient.removeQueries([userDomain.userResourceUrl, String(args[1])]);
        await queryClient.invalidateQueries(queryKey ?? [userDomain.userResourceUrl]);
        notification({
          header: 'Удалено',
          content: notificationSettings?.notificationMessage ?? 'Пользователь удален',
          type: 'success',
        });
        options?.onSuccess && options.onSuccess(...args);
      },
    },
  );
  const removeUser = useCallback(
    (id: number) => {
      confirmDialog({
        header: notificationSettings?.confirmTitle ?? 'Удаление пользователя',
        message:
          notificationSettings?.confirmMessage ?? 'Вы действительно хотите удалить пользователя? ',
        acceptLabel: 'Удалить',
        rejectLabel: 'Отмена',
        accept: () => mutation.mutateAsync(id),
      });
    },
    [notificationSettings, mutation],
  );
  return {
    removeUser,
    ...mutation,
  };
};
