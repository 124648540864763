import { authDomain } from '@auth';
import { useMutation } from '@tanstack/react-query';
import { userStore } from '@user';
import { useSetRecoilState } from 'recoil';

export const useEmailLogin = () => {
  const setUser = useSetRecoilState(userStore.userState);

  return useMutation(
    (payload: authDomain.EmailLoginDto) => {
      return authDomain.authService.emailLogin(payload);
    },
    {
      onSuccess: (data) => {
        setUser(data);
      },
    },
  );
};
