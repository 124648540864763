import { balanceDomain } from '@balance';
import { Table } from '@components';
import { BaseListResponse } from '@core';
import { useColumns } from './use-columns';

type Props = {
  data: BaseListResponse<balanceDomain.Transaction> | undefined;
  isLoading: boolean;
  onPageChange: (page: number) => void;
};

export const TransactionTable = ({ data, isLoading, onPageChange }: Props) => {
  const columns = useColumns();

  return (
    <Table
      columns={columns}
      value={data}
      isLoading={isLoading}
      meta={data?.meta}
      onPageChange={onPageChange}
    />
  );
};
