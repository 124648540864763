import * as yup from 'yup';

export const useValidation = () => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .required('Введите название города')
      .min(3, 'Слишком короткое название')
      .max(150, 'Слишком длинное название'),
    timezone: yup.object().nullable().required('Выберите часовой пояс'),
  });
};
