import { routes } from '@router';
import { CityForm } from '../../components';
import { useLoadCityByIdQuery } from '../../hooks';
import { useDynamicPage } from 'layouts/dashboard/use-dynamic-page';

const EditingPage = () => {
  const { data, isLoading } = useDynamicPage(useLoadCityByIdQuery, 'name', [
    routes.dashboard.sub.city.path,
    routes.dashboard.sub.city.sub.creation.path,
  ]);

  return <CityForm data={data} isLoading={isLoading} />;
};

export default EditingPage;
