import { notification } from 'core/services/notification';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { userDomain } from '@user';
import { confirmDialog } from 'primereact/confirmdialog';
import { useCallback } from 'react';
import { useUserStatusMap } from '../maps';

type Variables = {
  id: number | string;
  payload: userDomain.UpdateStatusDto;
};

export const useUpdateUserStatusMutation = (
  options?: UseMutationOptions<userDomain.User, unknown, Variables>,
) => {
  const queryClient = useQueryClient();
  const userStatusMap = useUserStatusMap();
  const mutation = useMutation(
    ({ id, payload }: Variables) => {
      return userDomain.userRepository.updateStatus(id, payload);
    },
    {
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries([userDomain.userResourceUrl]);
        notification({
          header: 'Обновлено',
          content: `Статус пользователя изменился – ${userStatusMap[args[0].status]}`,
          type: 'success',
        });
        options?.onSuccess && options.onSuccess(...args);
      },
    },
  );

  const updateUserStatus = useCallback(
    (payload: Variables) => {
      const isApproved = payload.payload.status === userDomain.UserStatus.Approved;

      confirmDialog({
        header: 'Подтверждение',
        message: `Вы действительно хотите ${
          isApproved ? 'подтвердить' : 'отклонить'
        } регистрацию пользователя?`,
        acceptLabel: isApproved ? 'Подтвердить' : 'Отклонить',
        rejectLabel: 'Отмена',
        accept: () => mutation.mutateAsync(payload),
      });
    },
    [mutation],
  );

  return {
    updateUserStatus,
    ...mutation,
  };
};
