export enum TimezoneEnum {
  EuropeMoscow = 'Europe/Moscow', // +3
  EuropeKaliningrad = 'Europe/Kaliningrad', // +2
  EuropeSamara = 'Europe/Samara', // +4
  AsiaYekaterinburg = 'Asia/Yekaterinburg', // +5
  AsiaOmsk = 'Asia/Omsk', // +6
  AsiaKrasnoyarsk = 'Asia/Krasnoyarsk', // +7
  AsiaIrkutsk = 'Asia/Irkutsk', // +8
  AsiaYakutsk = 'Asia/Yakutsk', // +9
  AsiaVladivostok = 'Asia/Vladivostok', // +10
  AsiaMagadan = 'Asia/Magadan', // +11
  AsiaKamchatka = 'Asia/Kamchatka', // +12
}
