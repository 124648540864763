import { FormControlProps, ShowErrorsAfter } from './types';

export const generateFieldId = (name: string) =>
  `${Math.random().toString(36).substring(7)}_${name}`;

export const isAllowShowError = (
  showErrorsAfter: FormControlProps['showErrorAfter'],
  isSubmitted: boolean,
  isTouched: boolean,
  isDirty: boolean,
): boolean => {
  const checkAllow = (action: ShowErrorsAfter) => {
    switch (action) {
      case 'blur':
        return isTouched;
      case 'change':
        return isDirty;
      case 'submit':
        return isSubmitted;
      default:
        return true;
    }
  };

  if (!showErrorsAfter) {
    return true;
  }

  if (Array.isArray(showErrorsAfter)) {
    return showErrorsAfter.some(checkAllow);
  } else {
    return checkAllow(showErrorsAfter);
  }
};
