import { cityDomain } from '@city';

export const selectTimezoneOptions: cityDomain.Timezone[] = [
  {
    value: cityDomain.TimezoneEnum.EuropeKaliningrad,
    label: 'UTC+2',
  },
  {
    value: cityDomain.TimezoneEnum.EuropeMoscow,
    label: 'UTC+3',
  },
  {
    value: cityDomain.TimezoneEnum.EuropeSamara,
    label: 'UTC+4',
  },
  {
    value: cityDomain.TimezoneEnum.AsiaYekaterinburg,
    label: 'UTC+5',
  },
  {
    value: cityDomain.TimezoneEnum.AsiaOmsk,
    label: 'UTC+6',
  },
  {
    value: cityDomain.TimezoneEnum.AsiaKrasnoyarsk,
    label: 'UTC+7',
  },
  {
    value: cityDomain.TimezoneEnum.AsiaIrkutsk,
    label: 'UTC+8',
  },
  {
    value: cityDomain.TimezoneEnum.AsiaYakutsk,
    label: 'UTC+9',
  },
  {
    value: cityDomain.TimezoneEnum.AsiaVladivostok,
    label: 'UTC+10',
  },
  {
    value: cityDomain.TimezoneEnum.AsiaMagadan,
    label: 'UTC+11',
  },
  {
    value: cityDomain.TimezoneEnum.AsiaKamchatka,
    label: 'UTC+12',
  },
];
