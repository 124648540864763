import { IBaseResource } from '@snap-alex/domain-js';
import { parseCookies } from 'nookies';
import { SessionStorage } from '../services';

export class AuthProvider<AuthSession extends string, AuthResponse = AuthSession> {
  constructor(private storage: SessionStorage<AuthSession>, private httpResource: IBaseResource) {
    const sessionKey = this.storage.getKey();
    const token = parseCookies()[sessionKey];

    if (token) {
      this.setAuthHeaders(token);
    }
  }

  public async checkAuth(): Promise<AuthResponse | null> {
    const localSession = await this.getStoredSession();

    if (!localSession) {
      return null;
    } else {
      try {
        const remoteSession = await this.checkSessionRemote(localSession);
        if (remoteSession) {
          this.setSession(localSession);
          return remoteSession;
        }
        this.deleteStoredSession();
        return null;
      } catch (err) {
        // if (getErrorStatus(err) === RequestErrors.Unauthorized) {
        this.deleteStoredSession();
        // }
        return null;
      }
    }
  }

  public async logout(): Promise<void> {
    this.deleteStoredSession();
  }

  public async getStoredSession(): Promise<AuthSession | null> {
    return this.storage.getSession();
  }

  public setSession(session: AuthSession) {
    this.storage.setSession(session);
    this.setAuthHeaders(session);
  }

  public setAuthHeaders(session: AuthSession | string) {
    const headers = this.createAuthHeaders(session);
    this.httpResource.setHeaders(headers);
  }

  public deleteStoredSession() {
    this.storage.deleteSession();
    this.httpResource.clearHeaders();
  }

  protected resolveRemoteSession(session: AuthResponse): AuthSession {
    console.log('session', session);
    throw new Error('resolveRemoteSession not implemented');
  }

  protected checkSessionRemote(session: AuthSession): Promise<AuthResponse | null> {
    console.log('session', session);
    throw new Error('checkSessionRemote not implemented');
  }

  protected createAuthHeaders(session: AuthSession | string): object {
    console.log('session', session);
    throw new Error('createAuthHeaders not implemented');
  }

  protected isSessionValid(session: AuthResponse): boolean {
    console.log('session', session);
    throw new Error('isSessionValid not implemented');
  }

  protected deleteSessionRemote(): Promise<void> {
    throw new Error('deleteSessionRemote not implemented');
  }
}
