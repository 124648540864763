import { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '@router';
import { useIsAvailableByRole } from '../hooks';
import { RolePermissions } from '@permissions';

export const withPageGuardByRole = (Page: ComponentType, { notOf, oneOf }: RolePermissions) => {
  const GuardedPage = () => {
    const isAvailable = useIsAvailableByRole({ notOf, oneOf });

    if (!isAvailable) {
      return <Navigate to={routes.dashboard.path} replace />;
    }

    return <Page />;
  };

  return GuardedPage;
};
