import { FormInputPasswordToggle, FormInputPhone } from '@form';
import { Button } from '@components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withAuthForm } from '../../hocs';
import { useValidation } from './use-validation';
import { usePhoneLogin } from '../../hooks';
import { useState } from 'react';
import { getErrorMessage, getErrorStatus, RequestErrors } from 'core';
import { AuthStep } from '../../pages';

type FormValues = {
  phone: string;
  password: string;
};

export const PhoneLoginForm = withAuthForm(({ credentials, backToFirstStep, setCurrentStep }) => {
  const validation = useValidation();
  const [error, setError] = useState<string | null>(null);
  const { mutateAsync: login, isLoading: isLogining } = usePhoneLogin();
  const form = useForm<FormValues>({
    defaultValues: {
      phone: credentials.phone,
      password: '',
    },
    resolver: yupResolver(validation),
  });
  const { handleSubmit, formState } = form;
  const { isValid } = formState;

  const clearError = () => {
    setError(null);
  };

  const onResetPassword = () => {
    setCurrentStep(AuthStep.ResetPasswordByPhone);
  };

  const onSubmit = async (values: FormValues) => {
    try {
      await login(values);
    } catch (err) {
      if (getErrorStatus(err) === RequestErrors.WrongRequest) {
        setError(getErrorMessage(err));
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInputPhone name="phone" label="Номер телефона" readOnly />
        <FormInputPasswordToggle
          name="password"
          label="Пароль"
          error={error}
          errorTimeout={4}
          onErrorHide={clearError}
        />
        <div className="flex mt-5 mb-4">
          <Button icon="chevron-left" className="p-button-info mr-3" onClick={backToFirstStep} />
          <Button type="submit" label="Войти" loading={isLogining} disabled={!isValid} />
        </div>
        <Button
          label="Восстановить пароль"
          className="p-button-text mx-auto"
          onClick={onResetPassword}
        />
      </form>
    </FormProvider>
  );
});
