import * as yup from 'yup';
import { useTinValidation } from './use-tin-validation';

export const useCompanyValidation = () => {
  const tinValidation = useTinValidation();

  return {
    name: yup.string().trim().required('Введите название'),
    activity_field: yup.string().trim().required('Введите сферу деятельности'),
    ...tinValidation,
  };
};
