import { useEmailValidation, useFullnameValidation } from '@form';
import * as yup from 'yup';

export const useValidation = () => {
  const emailValidation = useEmailValidation();
  const fullnameValidation = useFullnameValidation();

  return yup.object().shape({
    ...fullnameValidation,
    ...emailValidation,
  });
};
