import { Icon, TabItem, Tabs } from '@components';
import { userDomain } from '@user';
import debounce from 'lodash.debounce';
import { InputText } from 'primereact/inputtext';
import { ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DecodedValueMap, SetQuery } from 'use-query-params';

type Props = {
  queryParams: DecodedValueMap<userDomain.UserLoadParamsConfig>;
  setQueryParams: SetQuery<userDomain.UserLoadParamsConfig>;
  inModerationItems?: number;
};

const debouncedFn = debounce((fn: () => void) => fn(), 300);

export const UserTableToolbar = ({ queryParams, setQueryParams, inModerationItems }: Props) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState(queryParams['search']);
  const [inModerationCount, setInModerationCount] = useState<number>();

  useEffect(() => {
    if (typeof inModerationItems === 'number') {
      setInModerationCount(inModerationItems);
    }
  }, [inModerationItems]);

  const tabs: TabItem<userDomain.UserStatusParamType | boolean>[] = useMemo(
    () => [
      {
        label: 'Все',
        key: undefined,
      },
      {
        label: `На модерации${
          typeof inModerationCount === 'number' ? ` (${inModerationCount})` : ''
        }`,
        key: userDomain.UserStatus.Moderation,
      },
      {
        label: 'Регистрация отклонена',
        key: userDomain.UserStatus.Declined,
      },
      {
        label: 'Заблокированные',
        key: true,
      },
      {
        label: 'Подтвержденные',
        key: userDomain.UserStatus.Approved,
      },
    ],
    [inModerationCount],
  );

  const activeTab = queryParams['filter.is_blocked'] ?? queryParams['filter.status'];

  const onTabSelect = useCallback(
    ({ key }: TabItem<userDomain.UserStatusParamType | boolean>) => {
      setSearch('');
      setQueryParams({
        'filter.status': typeof key === 'string' ? key : undefined,
        'filter.is_blocked': typeof key === 'boolean' ? key : undefined,
        search: '',
        page: 1,
      });
    },
    [setQueryParams],
  );

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = e.target.value;
      setSearch(value);
      debouncedFn(() => setQueryParams({ search: value }));
    },
    [setQueryParams],
  );

  return (
    <>
      <Tabs items={tabs} active={activeTab} onSelect={onTabSelect} className="mb-4" />
      <div className="flex align-items-center gap-4 mb-4">
        <span className="p-input-icon-right">
          <Icon icon="search" color="icons-ghost" />
          <InputText
            ref={searchRef}
            value={search ?? ''}
            placeholder="Поиск"
            onChange={onSearchChange}
          />
        </span>
      </div>
    </>
  );
};
