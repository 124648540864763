import { TableColumn } from '@components';
import { formatDate } from '@core';
import { userDomain } from '@user';
import { useMemo } from 'react';
import { Tag } from 'primereact/tag';
import { useUserStatusMap } from '../../maps';
import { UserStatus } from 'modules/user/domain';

type Options = {
  status: userDomain.UserStatusParamType;
};

export const useColumns = ({ status }: Options): TableColumn<userDomain.User>[] => {
  const UserStatusMap = useUserStatusMap();

  return useMemo<TableColumn<userDomain.User>[]>(
    () => [
      {
        field: 'full_name',
        header: 'ФИО / E-mail',
        body: ({ full_name, email }) => (
          <>
            <div>{full_name}</div>
            <div className="text-third">{email}</div>
          </>
        ),
      },
      {
        field: 'city',
        header: 'Город',
        body: ({ city }) => city?.name,
      },
      {
        field: 'gender',
        header: 'Начало',
        body: ({ gender }) => (gender === userDomain.Gender.Female ? 'Жен.' : 'Муж.'),
      },
      {
        field: 'birth_date',
        header: 'Дата рождения',
        body: ({ birth_date }) => birth_date && formatDate(birth_date),
      },
      {
        field: 'created_at',
        header: 'Дата',
        hidden: status !== UserStatus.Moderation,
        body: ({ created_at }) => formatDate(created_at, true),
      },
      {
        field: 'status',
        header: 'Статус',
        body: ({ status, is_blocked }) => (
          <Tag value={is_blocked ? 'Заблокирован' : UserStatusMap[status]} />
        ),
      },
    ],
    [UserStatusMap, status],
  );
};
