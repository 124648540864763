import { FormInputPasswordToggle } from '@form';
import { Button } from '@components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withAuthForm } from '../../hocs';
import { useValidation } from './use-validation';
import { useRestorePasswordByEmail, useRestorePasswordByPhone } from '../../hooks';
import { useState } from 'react';
import { getErrorMessage, getErrorStatus, RequestErrors } from 'core';
import { AuthStep } from '../../pages';

type FormValues = {
  password: string;
  repeat_password: string;
};

export const RestorePasswordForm = withAuthForm(({ backToFirstStep, credentials, currentStep }) => {
  const [error, setError] = useState<string | null>(null);
  const validation = useValidation();
  const { mutateAsync: restorePasswordByPhone, isLoading: isRestoringByPhone } =
    useRestorePasswordByPhone();
  const { mutateAsync: restorePasswordByEmail, isLoading: isRestoringByEmail } =
    useRestorePasswordByEmail();
  const form = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      repeat_password: '',
    },
    resolver: yupResolver(validation),
  });
  const { handleSubmit, formState } = form;
  const { isValid } = formState;

  const clearError = () => {
    setError(null);
  };

  const onSubmit = async (values: FormValues) => {
    if (credentials.codeId) {
      try {
        const { repeat_password, ...rest } = values;
        if (currentStep === AuthStep.RestorePasswordByEmail) {
          await restorePasswordByEmail({ ...rest, code_id: credentials.codeId });
        } else if (currentStep === AuthStep.RestorePasswordByPhone) {
          await restorePasswordByPhone({ ...rest, code_id: credentials.codeId });
        }
        backToFirstStep();
      } catch (err) {
        if (getErrorStatus(err) === RequestErrors.WrongRequest) {
          setError(getErrorMessage(err));
        }
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInputPasswordToggle name="password" label="Пароль" />
        <FormInputPasswordToggle
          name="repeat_password"
          label="Повторите пароль"
          error={error}
          errorTimeout={4}
          onErrorHide={clearError}
        />
        <Button
          type="submit"
          label="Завершить"
          loading={isRestoringByPhone || isRestoringByEmail}
          disabled={!isValid}
          className="mt-5"
        />
      </form>
    </FormProvider>
  );
});
