import { BooleanParam } from 'core/params';
import { userDomain } from '@user';
import { UserStatusParam } from 'modules/user/domain/params';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

const defaultQueryParams: userDomain.UserLoadParamsConfig = {
  page: withDefault(NumberParam, 1),
  limit: withDefault(NumberParam, 10),
  search: StringParam,
  'filter.status': UserStatusParam,
  'filter.is_blocked': BooleanParam,
};

export const useLoadUserQueryParams = () => {
  return useQueryParams(defaultQueryParams);
};
