import { RadioButton, RadioButtonChangeParams, RadioButtonProps } from 'primereact/radiobutton';
import { useCallback, useContext, useMemo } from 'react';
import { RadioButtonGroupContext } from './radio-button-group';
import { generateFieldId } from '../with-form-control/utils';

type Props = {
  label?: string;
} & RadioButtonProps;

export const RadioButtonGroupItem = ({ label, value, ...other }: Props) => {
  const { name, value: selectedValue, onChange } = useContext(RadioButtonGroupContext);

  const fieldId = useMemo(() => generateFieldId(name ?? ''), [name]);

  const handleChange = useCallback(
    (e: RadioButtonChangeParams) => {
      onChange && onChange(e.value ?? undefined);
    },
    [onChange],
  );

  return (
    <div className="field-radiobutton mb-0">
      <RadioButton
        {...other}
        inputId={fieldId}
        name={name}
        value={value}
        onChange={handleChange}
        checked={selectedValue === value}
      />
      {label && (
        <label htmlFor={fieldId} className="text-sm pl-extra1 ml-0 cursor-pointer">
          {label}
        </label>
      )}
    </div>
  );
};
