import { withFormControl } from '../with-form-control';
import { IMaskInput } from 'react-imask';
import cn from 'classnames';
import { IMaskInputProps } from 'react-imask/dist/mixin';
import { AnyMaskedOptions, MaskedNumber } from 'imask';
import { useCallback, useMemo } from 'react';

type Props = Omit<
  IMaskInputProps & Partial<MaskedNumber>,
  'mask' | 'onAccept' | 'unmask' | 'mapToRadix' | 'value' | 'onChange'
> & {
  value?: number | string | null;
  prefix?: string;
  currency?: boolean;
  onChange?: (value: number | null) => void;
};

export const InputNumber = ({
  value,
  className,
  onChange,
  currency,
  prefix,
  scale,
  thousandsSeparator,
  signed,
  min,
  max,
  ...other
}: Props) => {
  const blocks: { [key: string]: AnyMaskedOptions } = useMemo(
    () => ({
      num: {
        mask: Number,
        mapToRadix: ['.', ','],
        scale,
        thousandsSeparator,
        signed,
        min,
        max,
      },
    }),
    [max, min, scale, signed, thousandsSeparator],
  );

  const handleChange = useCallback(
    (value: any) => {
      onChange && onChange(value ? Number(value) : null);
    },
    [onChange],
  );

  return (
    <IMaskInput
      mask={`${prefix ?? ''}num${currency && value ? ' ₽' : ''}`}
      blocks={blocks}
      lazy={false}
      unmask
      value={String(value)}
      onAccept={handleChange}
      // @ts-ignore
      className={cn('p-inputtext', className)}
      {...other}
    />
  );
};

InputNumber.defaultProps = {
  scale: 0,
  thousandsSeparator: ' ',
  currency: false,
} as Props;

export const FormInputNumber = withFormControl<Props>((props) => {
  return <InputNumber {...props} />;
});
