import { AxiosResource, AxiosResourceOptions } from '@snap-alex/domain-js';
import { getErrorMessage, getErrorStatus } from 'core/utils';
import { RequestErrors } from '../enums/RequestErrors';
import { notification } from '../services/notification';

const excludeErrorStatuses = [
  RequestErrors.WrongRequest,
  RequestErrors.Unauthorized,
  RequestErrors.NotFound,
];

export const options: AxiosResourceOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
  trailingSlash: false,
  timeOffset: false,
};

const resource = new AxiosResource(process.env.REACT_APP_API_URL as string, options);

resource.addErrorHandler(({ response: err }) => {
  const errStatus = getErrorStatus(err);
  if (!excludeErrorStatuses.includes(errStatus)) {
    notification({
      header: `Ошибка сервера: ${errStatus}`,
      content: getErrorMessage(err),
      type: 'error',
    });
  }
});

export const httpResource = resource;
