import { createContext, PropsWithChildren } from 'react';
import cn from 'classnames';
import { withFormControl } from '../with-form-control';

type RadioButtonGroupContextValue = {
  name?: string;
  value?: any;
  onChange?: (value: any) => void;
};

type Props = PropsWithChildren<{
  name?: string;
  value?: any;
  onChange?: (value: any) => void;
  direction?: 'row' | 'column';
}>;

export const RadioButtonGroupContext = createContext<RadioButtonGroupContextValue>({
  name: undefined,
  value: undefined,
  onChange: undefined,
});

export const RadioButtonGroup = ({ name, value, onChange, direction, children }: Props) => {
  return (
    <RadioButtonGroupContext.Provider value={{ name, value, onChange }}>
      <div className={cn('flex gap-3', { 'flex-column': direction === 'column' })}>{children}</div>
    </RadioButtonGroupContext.Provider>
  );
};

RadioButtonGroup.defaultValues = {
  direction: 'row',
};

export const FormRadioButtonGroup = withFormControl<Props>((props) => {
  return <RadioButtonGroup {...props} />;
});
