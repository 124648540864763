import { Button } from '@components';
import { OptionalId } from '@core';
import { FormLabel } from '@form';
import { surveyDomain } from '@survey';
import { Skeleton } from 'primereact/skeleton';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TargetAudienceView } from '../target-audience-view';
import { TargetAudienceSidebar } from './sidebar';

type Props = {
  isLoading?: boolean;
};

export const TargetAudience = ({ isLoading }: Props) => {
  const { setValue, watch } = useFormContext();
  const targetAudience = watch('target_audience');
  const [isTargetAudienceSidebarShown, setIsTargetAudienceSidebarShown] = useState(false);

  const toggleTargetAudienceSidebarShown = () => {
    setIsTargetAudienceSidebarShown((prev) => !prev);
  };

  const onTargetAudienceReset = () => {
    setValue('target_audience', null, { shouldValidate: true, shouldDirty: true });
  };

  const onTargetAudienceSubmit = (values: OptionalId<surveyDomain.TargetAudience>) => {
    setValue('target_audience', values, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <>
      <FormLabel label="Целевая аудитория" className="mb-2 pl-extra1" required colon />
      {isLoading ? (
        <Skeleton />
      ) : targetAudience ? (
        <TargetAudienceView
          data={targetAudience}
          onEdit={toggleTargetAudienceSidebarShown}
          onRemove={onTargetAudienceReset}
          interactive
        />
      ) : (
        <Button
          label="Настроить"
          data-testid="createTargetAudience"
          className="p-button-info"
          onClick={toggleTargetAudienceSidebarShown}
        />
      )}
      <TargetAudienceSidebar
        data={targetAudience}
        visible={isTargetAudienceSidebarShown}
        onHide={toggleTargetAudienceSidebarShown}
        onSubmit={onTargetAudienceSubmit}
      />
    </>
  );
};

TargetAudience.defaultProps = {
  isLoading: false,
};
