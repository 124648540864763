import { FormInputPhone } from '@form';
import { Button } from '@components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValidation } from './use-validation';
import { withAuthForm } from '../../hocs';
import { useCheckPhone, useSendCode } from '../../hooks';
import { AuthStep } from '../../pages';
import { getErrorMessage, getErrorStatus, RequestErrors } from '@core';
import { useState } from 'react';

type FormValues = {
  phone: string;
};

export const PhoneForm = withAuthForm(
  ({ currentStep, setCurrentStep, updateCredentials, backToFirstStep, credentials }) => {
    const [error, setError] = useState<string | null>(null);
    const validation = useValidation();
    const isRequestResetPasswordStep =
      currentStep === AuthStep.ResetPasswordByPhone ||
      currentStep === AuthStep.ResetPasswordByPhoneConfirmation;
    const { mutateAsync: checkPhone, isLoading: isChecking } = useCheckPhone();
    const { mutateAsync: sendCode, isLoading: isSending } = useSendCode();
    const form = useForm<FormValues>({
      defaultValues: {
        phone: isRequestResetPasswordStep ? credentials.phone : '',
      },
      resolver: yupResolver(validation),
      context: {
        name: 'phone-form',
      },
    });
    const { handleSubmit, formState } = form;
    const { isValid } = formState;
    const isConfirmationStep =
      currentStep === AuthStep.PhoneConfirmation ||
      currentStep === AuthStep.ResetPasswordByPhoneConfirmation;

    const clearError = () => {
      setError(null);
    };

    const onSubmit = async (values: FormValues) => {
      if (isConfirmationStep) {
        const { id: codeId, credential } = await sendCode({ credential: values.phone });
        updateCredentials({ codeId, phone: credential });
        setCurrentStep(
          isRequestResetPasswordStep ? AuthStep.ResetPasswordByPhoneCode : AuthStep.Code,
        );
      } else {
        updateCredentials(values);
        try {
          await checkPhone(values);
          setCurrentStep(
            isRequestResetPasswordStep
              ? AuthStep.ResetPasswordByPhoneConfirmation
              : AuthStep.PhoneLogin,
          );
        } catch (err) {
          const errorStatus = getErrorStatus(err);
          if (errorStatus === RequestErrors.NotFound) {
            if (isRequestResetPasswordStep) {
              setError('Номер телефона не найден');
            } else {
              setCurrentStep(AuthStep.PhoneConfirmation);
            }
          } else if (errorStatus === RequestErrors.WrongRequest) {
            setError(getErrorMessage(err));
          }
        }
      }
    };

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInputPhone
            name="phone"
            data-testid="phoneNumber"
            label="Введите ваш номер телефона"
            placeholder="+ 7 (123) 456-78-90"
            readOnly={isConfirmationStep}
            error={error}
            errorTimeout={4}
            onErrorHide={clearError}
            icon={isConfirmationStep ? 'check' : null}
            iconColor="main-success"
          />
          <div className="flex mt-5">
            {(isConfirmationStep || isRequestResetPasswordStep) && (
              <Button
                icon="chevron-left"
                className="p-button-info mr-3"
                onClick={backToFirstStep}
              />
            )}
            <Button
              type="submit"
              label={isConfirmationStep ? 'Получить код' : 'Продолжить'}
              loading={isChecking || isSending}
              disabled={!isValid}
            />
          </div>
        </form>
      </FormProvider>
    );
  },
);
