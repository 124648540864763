import { useMutation } from '@tanstack/react-query';
import { useCurrentUser, userDomain, userStore } from '@user';
import { useSetRecoilState } from 'recoil';

export const useUpdatePhone = () => {
  const { id } = useCurrentUser();
  const setUser = useSetRecoilState(userStore.userState);
  return useMutation(
    (payload: userDomain.UpdatePhoneDto) => {
      return userDomain.userRepository.updatePhone(id, payload);
    },
    {
      onSuccess: (data) => {
        setUser(data);
      },
    },
  );
};
