import { atom } from 'recoil';
import { authStateKey } from '../constants';

type State = {
  isChecked: boolean;
  isAuthorized: boolean;
  codeSentAt: string | null;
};

export const authState = atom<State>({
  key: authStateKey,
  default: {
    isChecked: false,
    isAuthorized: false,
    codeSentAt: null,
  },
});
