import { selector } from 'recoil';
import { userState } from '../atoms';
import { userRoleSelectorKey } from '../constants';

export const roleSelector = selector({
  key: userRoleSelectorKey,
  get: ({ get }) => {
    const user = get(userState);
    return user?.role;
  },
});
