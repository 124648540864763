import { useChildrenFormControlValidation } from 'widgets/children-form-control';
import * as yup from 'yup';
import { MAX_AGE, MIN_AGE } from './constants';

export const useValidation = () => {
  const childrenFormControlValidation = useChildrenFormControlValidation();

  return yup.object().shape({
    age_from: yup
      .number()
      .transform((value) => (value === Number(value) ? value : null))
      .nullable()
      .required('Укажите возраст')
      .min(MIN_AGE, `Мин. ${MIN_AGE} лет`)
      .max(MAX_AGE, `Макс. ${MAX_AGE} лет`),
    age_to: yup
      .number()
      .transform((value) => (value === Number(value) ? value : null))
      .nullable()
      .required('Укажите возраст')
      .min(MIN_AGE, `Мин. ${MIN_AGE} лет`)
      .max(MAX_AGE, `Макс. ${MAX_AGE} лет`),
    city: yup.object().nullable().required('Выберите город'),
    gender: yup.string().nullable(),
    ...childrenFormControlValidation,
  });
};
