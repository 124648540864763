import { useCheckPhone, useSendCode } from '@auth';
import { Button } from '@components';
import { formatWithPhoneMask, getErrorMessage, getErrorStatus, RequestErrors } from '@core';
import { FormInputMask, FormInputPhone } from '@form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userDomain } from '@user';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useCurrentUser, useEditableForm, useUpdatePhone } from '../../hooks';
import { useValidation } from './use-validation';

type FormValues = {
  phone: string;
  code: string;
  code_id: string | null;
};

export const PhoneForm = () => {
  const [error, setError] = useState<string | null>(null);
  const { phone } = useCurrentUser();
  const validation = useValidation();

  const { form, editableForm, isEditable, toggleEditable } = useEditableForm<FormValues>(
    userDomain.EditableForm.Phone,
    {
      defaultValues: {
        phone,
        code: '',
        code_id: null,
      },
      resolver: yupResolver(validation),
    },
  );

  const { handleSubmit, formState, setValue, watch } = form;
  const { isValid, isDirty } = formState;
  const isCodeSent = !!watch('code_id');

  const { mutateAsync: sendCode } = useSendCode();
  const { mutateAsync: updatePhone } = useUpdatePhone();
  const { mutateAsync: checkPhone } = useCheckPhone();

  const clearError = () => {
    setError(null);
  };

  const onSubmit = async (values: FormValues) => {
    const { phone, code_id, code } = values;

    if (isCodeSent) {
      try {
        await updatePhone({ phone, code_id: code_id!, code });
        toggleEditable({ phone, code_id: null, code: '' });
      } catch (err) {
        if (getErrorStatus(err) === RequestErrors.WrongRequest) {
          setError(getErrorMessage(err));
        }
      }
    } else {
      try {
        await checkPhone({ phone });
        setError('Данный телефон уже используется');
      } catch {
        const { id } = await sendCode({ credential: phone });
        setValue('code_id', id);
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid formgrid p-fluid">
          <FormInputPhone
            name="phone"
            label={isEditable ? 'Новый номер телефона' : 'Номер телефона'}
            displayOnly={!isEditable}
            displayFormat={formatWithPhoneMask}
            className="col-6"
            help={
              isCodeSent
                ? 'На указанный номер направлен звонок-сброс'
                : 'Мы совершим звонок-сброс для подтверждения вашего номера'
            }
            readOnly={isCodeSent}
            icon={isCodeSent ? 'check' : null}
            iconColor="main-success"
            error={isCodeSent ? null : error}
            errorTimeout={4}
            onErrorHide={clearError}
          />
          {isCodeSent && (
            <FormInputMask
              name="code"
              label="Код подтверждения"
              mask="9999"
              slotChar=""
              displayOnly={!isEditable}
              className="col-6"
              help="Впишите 4 последних цифр номера, с которого вам позвонили"
              error={isCodeSent ? error : null}
              errorTimeout={4}
              onErrorHide={clearError}
            />
          )}
        </div>
        <div className="flex mt-2">
          {isEditable ? (
            <Button
              type="submit"
              label={isCodeSent ? 'Сохранить' : 'Получить код'}
              data-testid="getCode"
              className="p-button-secondary"
              disabled={!isValid || !isDirty}
            />
          ) : (
            <Button
              key="button"
              type="button"
              label="Сменить"
              className="p-button-secondary"
              onClick={() => toggleEditable()}
              disabled={!!editableForm}
            />
          )}
          {isEditable && (
            <Button
              label="Отмена"
              className="p-button-text p-button-danger ml-extra2"
              onClick={() => toggleEditable()}
            />
          )}
        </div>
      </form>
    </FormProvider>
  );
};
