import * as yup from 'yup';
import { useEmailValidation } from '@form';

export const useValidation = () => {
  const emailValidation = useEmailValidation();

  return yup.object().shape({
    ...emailValidation,
    password: yup.string().trim().required('Введите пароль'),
  });
};
