import { FormControlProps, FormRadioButtonGroup, RadioButtonGroupItem } from '@form';
import { useGenderMap, userDomain } from '@user';

export const GenderFormControl = (props: Omit<FormControlProps, 'name'>) => {
  const genderMap = useGenderMap();

  return (
    <FormRadioButtonGroup name="gender" label="Пол" {...props}>
      <RadioButtonGroupItem
        data-testid="genderAny"
        label={genderMap[userDomain.Gender.Any]}
        value={userDomain.Gender.Any}
      />
      <RadioButtonGroupItem
        data-testid="genderMale"
        label={genderMap[userDomain.Gender.Male]}
        value={userDomain.Gender.Male}
      />
      <RadioButtonGroupItem
        data-testid="genderFemale"
        label={genderMap[userDomain.Gender.Female]}
        value={userDomain.Gender.Female}
      />
    </FormRadioButtonGroup>
  );
};
