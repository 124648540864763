import { userDomain } from '@user';

type Rules = {
  notOf?: userDomain.UserRole | userDomain.UserRole[];
  oneOf?: userDomain.UserRole | userDomain.UserRole[];
};

export const isAvailableByRole = (
  role: userDomain.UserRole | undefined,
  { oneOf, notOf }: Rules,
) => {
  if (
    (!role && (oneOf || notOf)) ||
    (notOf &&
      (Array.isArray(notOf) ? notOf.includes(role as userDomain.UserRole) : notOf === role)) ||
    (oneOf &&
      (Array.isArray(oneOf) ? !oneOf.includes(role as userDomain.UserRole) : oneOf !== role))
  ) {
    return false;
  }

  return true;
};
