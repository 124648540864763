import { cityDomain } from '@city';
import { Button, Divider } from '@components';
import { FormInputText, FormSelectTimezone } from '@form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDashboardPath, routes } from '@router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRemoveCityMutation, useSaveCityMutation } from '../../hooks';
import { useValidation } from './use-validation';
import { getDefaultValues } from './utils';

type Props = {
  data?: cityDomain.City | null;
  isLoading?: boolean;
  displayOnly?: boolean;
};

export type FormValues = {
  name: string;
  timezone: cityDomain.Timezone | null;
};

export const CityForm = ({ data, isLoading, displayOnly }: Props) => {
  const navigate = useNavigate();
  const validation = useValidation();

  const form = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: getDefaultValues(data),
    resolver: yupResolver(validation),
  });

  const { handleSubmit, formState, reset } = form;
  const { isValid, isDirty } = formState;

  useEffect(() => {
    reset(getDefaultValues(data));
  }, [data, reset]);

  const { saveCity, isLoading: isSaving } = useSaveCityMutation({
    onSuccess: () => {
      navigate(getDashboardPath(routes.dashboard.sub.city.path));
    },
  });
  const { removeCity, isLoading: isRemoving } = useRemoveCityMutation({
    onSuccess: () => {
      navigate(getDashboardPath(routes.dashboard.sub.city.path));
    },
  });

  const onEdit = () => {
    if (data) {
      navigate(
        getDashboardPath([
          routes.dashboard.sub.city.path,
          routes.dashboard.sub.city.sub.editing.path,
          data.id,
        ]),
      );
    }
  };

  const onRemove = () => {
    if (data) {
      removeCity(Number(data.id));
    }
  };

  const onSubmit = (values: FormValues) => {
    saveCity({ id: data?.id, ...(values as Partial<cityDomain.City>) });
  };

  return (
    <>
      <Divider className="mt-0 mb-3" />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid formgrid p-fluid">
            <div className={displayOnly ? 'col-12' : 'col-6'}>
              <FormInputText
                data-testid="cityName"
                name="name"
                label="Название города"
                isLoading={isLoading}
                displayOnly={displayOnly}
              />
              {displayOnly && <Divider className="my-3" />}
              <FormSelectTimezone
                data-testid="timeZone"
                name="timezone"
                label="Часовой пояс"
                isLoading={isLoading}
                displayOnly={displayOnly}
                displayFormat={(value) => value.label}
              />
            </div>
          </div>
          <div className="flex justify-content-between mt-3">
            {displayOnly ? (
              <>
                <Button label="Редактировать" disabled={isRemoving} onClick={onEdit} />
                <Button
                  label="Удалить"
                  loading={isRemoving}
                  className="p-button-secondary"
                  onClick={onRemove}
                />
              </>
            ) : (
              <Button
                data-testid="submitNewCity"
                type="submit"
                label="Сохранить"
                loading={isSaving}
                disabled={!isDirty || !isValid || isLoading}
              />
            )}
          </div>
        </form>
      </FormProvider>
    </>
  );
};

CityForm.defaultProps = {
  data: null,
  isLoading: false,
  displayOnly: false,
};
