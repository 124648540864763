import { useTinValidation } from '@form';
import * as yup from 'yup';

export const useValidation = () => {
  const tinValidation = useTinValidation();

  return yup.object().shape({
    name: yup.string().trim().required('Введите название'),
    activity_field: yup.string().trim().required('Введите сферу деятельности'),
    ...tinValidation,
  });
};
