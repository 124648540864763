import { FormInputText } from '@form';
import { Button } from '@components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValidation } from './use-validation';
import { withAuthForm } from '../../hocs';
import { useCheckEmail, useSendRestoreLink } from '../../hooks';
import { AuthStep } from '../../pages';
import { getErrorStatus, notification, RequestErrors } from '@core';
import { useState } from 'react';

type FormValues = {
  email: string;
};

export const ResetPasswordByEmailForm = withAuthForm(
  ({ setCurrentStep, backToFirstStep, credentials }) => {
    const [isEmailChecked, setIsEmailChecked] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const validation = useValidation();
    const { mutateAsync: checkEmail, isLoading: isChecking } = useCheckEmail();
    const { mutateAsync: sendRestoreLink, isLoading: isSending } = useSendRestoreLink();
    const form = useForm<FormValues>({
      defaultValues: {
        email: credentials.email,
      },
      resolver: yupResolver(validation),
    });
    const { handleSubmit, formState } = form;
    const { isValid } = formState;

    const clearError = () => {
      setError(null);
    };

    const onSubmit = async (values: FormValues) => {
      if (isEmailChecked) {
        await sendRestoreLink(values);
        notification({
          header: 'Отправлено',
          content: 'Ссылка для восстановления пароля выслана на указанную электронну почту',
          type: 'success',
        });
        setCurrentStep(AuthStep.EmailLogin);
      } else {
        try {
          await checkEmail(values);
          setIsEmailChecked(true);
        } catch (err) {
          if (getErrorStatus(err) === RequestErrors.NotFound) {
            setError('E-mail не найден');
          }
        }
      }
    };

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInputText
            name="email"
            label="E-mail"
            placeholder="Введите e-mail"
            error={error}
            errorTimeout={4}
            onErrorHide={clearError}
            icon={isEmailChecked ? 'check' : null}
            iconColor="main-success"
            readOnly={isEmailChecked}
          />
          <div className="flex mt-5">
            <Button icon="chevron-left" className="p-button-info mr-3" onClick={backToFirstStep} />
            <Button
              type="submit"
              label={isEmailChecked ? 'Отправить' : 'Проверить'}
              loading={isChecking || isSending}
              disabled={!isValid}
            />
          </div>
        </form>
      </FormProvider>
    );
  },
);
