import { FunctionComponent, useContext } from 'react';
import { AuthContext, AuthContextType } from '../pages';

export const withAuthForm = <ProvidedProps extends object>(
  Component: FunctionComponent<AuthContextType & ProvidedProps>,
) => {
  const AuthModal = (props: ProvidedProps) => {
    const authContext = useContext(AuthContext);

    return <Component {...authContext} {...props} />;
  };

  return AuthModal;
};
