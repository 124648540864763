import {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import { Calendar, CalendarChangeParams, CalendarSelectParams } from 'primereact/calendar';

import { Icon } from '@components';
import { withFormControl } from '@form';

import { DatePickerChangeParams, DatePickerType, DatePickerValue } from './types';
import { parseValueToDate, formatDate, yearFormatDate } from './utils';
import { Dayjs } from 'dayjs';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import { InputMask } from "primereact/inputmask";

type Props = {
  type?: DatePickerType;
  value?: DatePickerValue | number;
  min?: DatePickerValue | Dayjs | number;
  max?: DatePickerValue | Dayjs | number;
  showTime?: boolean;
  onChange?: (e: DatePickerChangeParams) => void;
  className?: string;
  prefix?: string;
};

const CustomInput = forwardRef(({ value, onClick, ...other }: any, ref) => (
  <InputMask ref={ref} value={value} onClick={onClick} {...other} />
));

export const DatePicker = ({
  type,
  value,
  min,
  max,
  onChange,
  className,
  showTime,
  prefix,
  ...other
}: Props) => {
  const isYearPicker = type === 'year';
  const [time, setTime] = useState<Date | null | undefined>(parseValueToDate(value));

  const prefixRef = useRef<HTMLDivElement>(null);
  const prefixWidth = prefixRef.current?.clientWidth;
  const inputStyle = prefixWidth
    ? { paddingLeft: `calc(${prefixWidth / 16}rem + 1rem)` }
    : undefined;

  const parsedValue = parseValueToDate(value);
  const parsedMin = parseValueToDate(min);
  const parsedMax = parseValueToDate(max);

  const handleChangeTime = useCallback(
    (e: CalendarChangeParams | CalendarSelectParams) => {
      const timeValue = e.value as Date | null | undefined;
      setTime(timeValue);
      if (value && onChange) {
        onChange({ ...e, value: formatDate(parsedValue, timeValue) });
      }
    },
    [onChange, parsedValue, value],
  );

  const inputContainerClassName = cn('relative w-full', className);

  const handleChange = (date: Date) => {
    if (onChange) {
      onChange({
        value: isYearPicker ? yearFormatDate(date) : formatDate(date, time),
      });
    }
  };

  const mask = isYearPicker ? '9999' : '99.99.9999';

  useEffect(() => {
    if (showTime) {
      const initialTime = new Date();
      initialTime.setUTCHours(12,0);
      setTime(initialTime);
    }
  }, []);

  return (
    <div className="flex">
      <div className={inputContainerClassName}>
        {prefix && parsedValue && (
          <div ref={prefixRef} className="p-datepicker-prefix">
            {prefix}
          </div>
        )}
        <ReactDatePicker
          onChange={handleChange}
          selected={parsedValue}
          dateFormat={isYearPicker ? 'yyyy' : 'dd.MM.yyyy'}
          placeholderText={isYearPicker ? 'год' : 'дд.мм.гггг'}
          showYearPicker={isYearPicker}
          onSelect={handleChange}
          maxDate={parsedMax}
          minDate={parsedMin}
          customInput={<CustomInput style={inputStyle} mask={mask} />}
          locale={ru}
        />
        <Icon icon="calendar" color="icons-ghost" className="cursor-pointer f-icon f-icon-r" />
      </div>
      {showTime && !isYearPicker && !!parsedValue && (
        <div className={inputContainerClassName}>
          <Calendar
            value={time}
            className="ml-extra1"
            onChange={handleChangeTime}
            placeholder="чч:мм"
            mask="99:99"
            showIcon
            showTime
            timeOnly
          />
          <Icon icon="clock" color="icons-ghost" className="cursor-pointer" />
        </div>
      )}
    </div>
  );
};

DatePicker.defaultProps = {
  type: 'date',
  showTime: false,
};

export const FormDatePicker = withFormControl<Props>(({ onChange, ...other }) => {
  return <DatePicker onChange={({ value }) => onChange(value)} {...other} />;
});
