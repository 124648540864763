import { useCallback } from 'react';
import { TransactionTable, TransactionTableToolbar } from '../../components';
import { useLoadTransactionQuery } from '../../hooks';

const TablePage = () => {
  const { data, isLoading, queryParams, setQueryParams } = useLoadTransactionQuery();

  const onPageChange = useCallback(
    (page: number) => {
      setQueryParams({ page });
    },
    [setQueryParams],
  );

  return (
    <>
      <TransactionTableToolbar queryParams={queryParams} setQueryParams={setQueryParams} />
      <TransactionTable data={data} isLoading={isLoading} onPageChange={onPageChange} />
    </>
  );
};

export default TablePage;
