import { Button } from '@components';
import { getErrorMessage, getErrorStatus, RequestErrors } from 'core';
import { FormInputPasswordToggle } from '@form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userDomain } from '@user';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useEditableForm, useUpdatePassword } from '../../hooks';
import { useValidation } from './use-validation';

type FormValues = {
  old_password: string;
  new_password: string;
  repeat_password: string;
};

export const PasswordForm = () => {
  const [error, setError] = useState<string | null>(null);
  const validation = useValidation();
  const { form, editableForm, isEditable, toggleEditable } = useEditableForm<FormValues>(
    userDomain.EditableForm.Password,
    {
      mode: 'onChange',
      defaultValues: {
        old_password: '',
        new_password: '',
        repeat_password: '',
      },
      resolver: yupResolver(validation),
    },
  );

  const { handleSubmit, formState } = form;
  const { isValid } = formState;

  const { mutateAsync } = useUpdatePassword();

  const clearError = () => {
    setError(null);
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const { repeat_password, ...rest } = values;
      await mutateAsync(rest);
      toggleEditable();
    } catch (err) {
      if (getErrorStatus(err) === RequestErrors.WrongRequest) {
        setError(getErrorMessage(err));
      }
    }
  };

  return isEditable ? (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid formgrid p-fluid">
          <div className="col-12">
            <div className="grid formgrid">
              <FormInputPasswordToggle
                name="old_password"
                label="Старый пароль"
                placeholder="Введите старый пароль"
                displayOnly={!isEditable}
                className="col-6"
                error={error}
                errorTimeout={4}
                onErrorHide={clearError}
              />
            </div>
          </div>
          <FormInputPasswordToggle
            name="new_password"
            label="Новый пароль"
            placeholder="Введите новый пароль"
            help="Минимальное количество символов – 6"
            displayOnly={!isEditable}
            className="col-6"
          />
          <FormInputPasswordToggle
            name="repeat_password"
            label="Подтвердите новый пароль"
            placeholder="Введите новый пароль повторно"
            displayOnly={!isEditable}
            className="col-6"
          />
        </div>
        <div className="flex mt-2">
          <Button
            type="submit"
            label="Сохранить"
            className="p-button-secondary"
            disabled={!isValid}
          />
          <Button
            label="Отмена"
            className="p-button-text p-button-danger ml-extra2"
            onClick={() => toggleEditable()}
          />
        </div>
      </form>
    </FormProvider>
  ) : (
    <Button
      type="button"
      label="Сменить пароль"
      className="p-button-secondary"
      onClick={() => toggleEditable()}
      disabled={!!editableForm}
    />
  );
};
