import { Icon } from '@components';
import { memo, useCallback, useMemo, useState } from 'react';
import ReactSelect, {
  ActionMeta,
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
  components,
} from 'react-select';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import ReactAsyncSelect, { AsyncProps } from 'react-select/async';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { withFormControl } from '../with-form-control';
import { getTargetOptions, getTargetValue } from './utils';

export type SelectOption = {
  value: string;
  label: string;
};

export type SelectProps<T> = {
  options?: OptionsOrGroups<T, GroupBase<T>>;
  valueKey?: keyof T;
  labelKey?: keyof T;
  inValue?: boolean;
  outValue?: boolean;
  inOutValue?: boolean;
  paginate?: boolean;
  loadOptions?: (searchValue: string, page: number) => Promise<OptionsOrGroups<T, GroupBase<T>>>;
  'data-testid'?: string;
} & Omit<StateManagerProps<T, boolean, GroupBase<T>>, 'options'> &
  Omit<AsyncProps<T, boolean, GroupBase<T>>, 'loadOptions'>;

export type SelectComponentType = <T>(props: SelectProps<T>) => JSX.Element;

export const SelectComponent: SelectComponentType = ({
  value,
  options,
  valueKey,
  labelKey,
  inValue,
  outValue,
  inOutValue,
  onChange,
  loadOptions,
  defaultOptions,
  paginate,
  'data-testid': dataTestId,
  ...other
}) => {
  const [loadedOptions, setLoadedOptions] = useState<OptionsOrGroups<any, GroupBase<any>>>([]);

  const targetOptions = useMemo(
    () => getTargetOptions({ options, loadedOptions, loadOptions, defaultOptions }),
    [defaultOptions, loadOptions, loadedOptions, options],
  );
  const targetValue = useMemo(
    () =>
      getTargetValue({
        options: targetOptions,
        value,
        inValue: inValue || inOutValue,
        valueKey,
      }),
    [inOutValue, inValue, targetOptions, value, valueKey],
  );

  const getOptionValue: GetOptionValue<any> = useCallback(
    (option) => (valueKey ? String(option[valueKey]) : ''),
    [valueKey],
  );
  const getOptionLabel: GetOptionLabel<any> = useCallback(
    (option) => (labelKey ? String(option[labelKey]) : ''),
    [labelKey],
  );

  const onSelect = useCallback(
    (newValue: MultiValue<any> | SingleValue<any>, actionMeta: ActionMeta<any>) => {
      let value = newValue;

      if (newValue && valueKey && (outValue || inOutValue)) {
        if (Array.isArray(newValue)) {
          value = newValue.map((option) => option[valueKey]);
        }

        value = newValue[valueKey as keyof SingleValue<any>];
      }

      onChange && onChange(value, actionMeta);
    },
    [inOutValue, onChange, outValue, valueKey],
  );

  const loadOptionsHandler = useCallback(
    (...args: any[]) => {
      // @ts-ignore
      const loadableOptions = loadOptions!(...args);
      if (loadableOptions) {
        loadableOptions.then(setLoadedOptions);
      }
      return loadableOptions;
    },
    [loadOptions],
  );

  const onOptionsLoad: SelectProps<any>['loadOptions'] = useCallback(
    (...args: any[]) => {
      const loadableOptions = loadOptionsHandler(...args);
      return loadableOptions;
    },
    [loadOptionsHandler],
  );

  const onPaginateOptionsLoad: LoadOptions<any, GroupBase<any>, { page: number }> = useCallback(
    async (searchValue, _, additional) => {
      const loadableOptions = loadOptionsHandler(searchValue, additional?.page);
      const loadedOptions = await loadableOptions;

      return {
        options: loadedOptions,
        // @ts-ignore
        hasMore: additional && additional.page < loadedOptions.meta.totalPages,
        // @ts-ignore
        additional: { page: loadedOptions.meta.currentPage + 1 },
      };
    },
    [loadOptionsHandler],
  );

  const selectProps: SelectProps<any> = {
    value: targetValue,
    onChange: onSelect,
    getOptionValue,
    getOptionLabel,
    noOptionsMessage: () => 'Нет данных',
    loadingMessage: () => 'Загрузка...',
    placeholder: '',
    defaultOptions,
    menuPlacement: 'auto',
    menuPortalTarget: document.body,
    components: {
      Input: (props) => <components.Input {...props} data-testid={dataTestId} />,
      DropdownIndicator: () => {
        return <Icon icon="chevron-down" color="icons-ghost" className="cursor-pointer" />;
      },
      LoadingIndicator: () => {
        return <span className="pi pi-spinner pi-spin icons-ghost mr-extra1" />;
      },
    },
    classNamePrefix: 'dcs-select',
    ...other,
  };

  if (loadOptions) {
    if (paginate) {
      return (
        <AsyncPaginate
          // @ts-ignore
          loadOptions={onPaginateOptionsLoad}
          additional={{ page: 1 }}
          {...selectProps}
        />
      );
    }

    // @ts-ignore
    return <ReactAsyncSelect loadOptions={onOptionsLoad} {...selectProps} />;
  }

  return <ReactSelect options={options} {...selectProps} />;
};

// @ts-ignore
SelectComponent.defaultProps = {
  valueKey: 'value',
  labelKey: 'label',
  inValue: false,
  outValue: false,
  inOutValue: false,
  paginate: false,
} as Partial<SelectProps<any>>;

export const Select = memo(SelectComponent) as SelectComponentType;

export const FormSelect = withFormControl(Select);
