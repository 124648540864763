import { userDomain } from '@user';

export const useUserStatusMap = () => {
  return {
    [userDomain.UserStatus.New]: 'Новый',
    [userDomain.UserStatus.Moderation]: 'На модерации',
    [userDomain.UserStatus.Approved]: 'Подтвержден',
    [userDomain.UserStatus.Declined]: 'Рег. отклонена',
  };
};
