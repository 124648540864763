import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { locale, addLocale } from 'primereact/api';
import './assets/styles/styles.scss';
import { ru } from 'locales';

dayjs.extend(minMax);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

addLocale('ru', ru);

require('dayjs/locale/ru');
locale('ru');

if (process.env.REACT_APP_API_MOCKING === 'enabled') {
  const { worker } = require('@mocks');
  worker.start();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
