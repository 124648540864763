import { Button } from '@components';
import { formatWithNumberMask } from '@core';
import { layoutsStore } from '@layouts';
import { useCurrentUser } from '@user';
import { useSetRecoilState } from 'recoil';
import styles from './styles.module.scss';

export const Card = () => {
  const { balance } = useCurrentUser();
  const setRefillSidebarVisible = useSetRecoilState(layoutsStore.refillSidebarVisibleState);

  const onRefillBalanceClick = () => {
    setRefillSidebarVisible(true);
  };

  return (
    <div className={styles.card}>
      <span className="text-sm font-medium main-white">Ваш баланс:</span>
      <h2 className="text-4xl line-height-2 main-white mb-3">
        {formatWithNumberMask(balance, false, true)}
      </h2>
      <Button label="Пополнить" className="p-button-white" onClick={onRefillBalanceClick} />
    </div>
  );
};
