import { selector } from 'recoil';
import { userState } from '../atoms';
import { userOwnerTypeSelectorKey } from '../constants';

export const ownerTypeSelector = selector({
  key: userOwnerTypeSelectorKey,
  get: ({ get }) => {
    const user = get(userState);
    return user?.owner_type;
  },
});
