import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import { notification } from 'core/services/notification';
import { cityDomain } from '@city';

export const useSaveCityMutation = (
  options?: UseMutationOptions<cityDomain.City, unknown, Partial<cityDomain.City>>,
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (payload: Partial<cityDomain.City>) => {
      return payload.id
        ? await cityDomain.cityRepository.patch(payload)
        : await cityDomain.cityRepository.create(payload);
    },
    {
      ...options,
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries([cityDomain.cityResourceUrl]);
        notification({
          header: 'Сохранено',
          content: 'Информация о городе сохранена',
          type: 'success',
        });
        options?.onSuccess && options.onSuccess(...args);
      },
    },
  );

  const saveCity = useCallback(
    (payload: Partial<cityDomain.City>) => {
      confirmDialog({
        header: 'Сохранение города',
        message: 'Вы действительно хотите сохранить город? ',
        acceptLabel: 'Сохранить',
        rejectLabel: 'Отмена',
        accept: () => mutation.mutateAsync(payload),
      });
    },
    [mutation],
  );

  return {
    saveCity,
    ...mutation,
  };
};
