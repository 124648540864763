import { ReactElement } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  label: string | ReactElement | null;
  required?: boolean;
  colon?: boolean;
  htmlFor?: string;
  className?: string;
};

export const FormLabel = ({ label, required, colon, htmlFor, className }: Props) => {
  return (
    <label htmlFor={htmlFor} className={cn(styles.label, htmlFor && 'cursor-pointer', className)}>
      {label}
      {required && <span className="main-error">*</span>}
      {colon && ':'}
    </label>
  );
};

FormLabel.defaultProps = {
  required: false,
  color: false,
};
