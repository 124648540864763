import { balanceDomain } from '@balance';
import { atom } from 'recoil';
import { refillSidebarDefaultValuesStateKey } from '../constants';

export const refillSidebarDefaultValuesState = atom<Pick<
  balanceDomain.CreateTransactionDto,
  'transaction_summ' | 'survey'
> | null>({
  key: refillSidebarDefaultValuesStateKey,
  default: null,
});
