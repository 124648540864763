import { authDomain, authStore } from '@auth';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSetRecoilState } from 'recoil';

export const useResendCode = () => {
  const setCodeSentAt = useSetRecoilState(authStore.codeSentAtSelector);

  return useMutation(
    (payload: authDomain.SendCodeDto) => {
      return authDomain.authService.resendCode(payload);
    },
    {
      onSuccess: () => {
        setCodeSentAt(dayjs().toISOString());
      },
    },
  );
};
