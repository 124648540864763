import { BaseRepository, ContentTypeEnum } from '@snap-alex/domain-js';
import { ServerFile } from '../interfaces';
import { fileResource } from '../resources/FileResource';

class FileRepository extends BaseRepository<ServerFile> {
  public uploadFile(file: File): Promise<ServerFile> {
    return this.resource()
      .create({ file }, { contentType: ContentTypeEnum.FORM_DATA })
      .then((res) => this.processResponse(res)) as Promise<ServerFile>;
  }
}

export const fileRepository = new FileRepository(fileResource);
