import { Button } from '@components';
import { FormInputText } from '@form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userDomain } from '@user';
import { usePatchCompanyMutation } from '@company';
import { FormProvider } from 'react-hook-form';
import { useCurrentUser, useEditableForm } from '../../hooks';
import { useValidation } from './use-validation';
import { useState } from 'react';
import { getErrorMessage, getErrorStatus, RequestErrors } from 'core';

type FormValues = {
  name: string;
  tin: string;
  activity_field: string;
};

export const CompanyForm = () => {
  const [error, setError] = useState<string | null>(null);
  const { company } = useCurrentUser();
  const validation = useValidation();

  const { form, editableForm, isEditable, toggleEditable } = useEditableForm<FormValues>(
    userDomain.EditableForm.Company,
    {
      mode: 'onChange',
      defaultValues: {
        name: company!.name,
        tin: company!.tin,
        activity_field: company!.activity_field,
      },
      resolver: yupResolver(validation),
    },
  );

  const { handleSubmit, formState } = form;
  const { isValid, isDirty } = formState;

  const { mutateAsync } = usePatchCompanyMutation();

  const clearError = () => {
    setError(null);
  };

  const onSubmit = async (values: FormValues) => {
    try {
      await mutateAsync({ id: company!.id, ...values });
      toggleEditable(values);
    } catch (err) {
      if (getErrorStatus(err) === RequestErrors.WrongRequest) {
        setError(getErrorMessage(err));
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid formgrid p-fluid">
          <FormInputText
            name="name"
            label="Название юридического лица"
            displayOnly={!isEditable}
            className="col-6"
          />
          <FormInputText
            name="tin"
            label="ИНН"
            displayOnly={!isEditable}
            className="col-6"
            error={error}
            errorTimeout={4}
            onErrorHide={clearError}
          />
          <FormInputText
            name="activity_field"
            label="Сфера деятельности"
            displayOnly={!isEditable}
            className="col-6"
          />
        </div>
        <div className="flex mt-2">
          {isEditable ? (
            <Button
              type="submit"
              label="Сохранить"
              className="p-button-secondary"
              disabled={!isValid || !isDirty}
            />
          ) : (
            <Button
              key="button"
              type="button"
              label="Редактировать"
              className="p-button-secondary"
              onClick={() => toggleEditable()}
              disabled={!!editableForm}
            />
          )}
          {isEditable && (
            <Button
              label="Отмена"
              className="p-button-text p-button-danger ml-extra2"
              onClick={() => toggleEditable()}
            />
          )}
        </div>
      </form>
    </FormProvider>
  );
};
