import * as yup from 'yup';

const entityItnCoefficients = [2, 4, 10, 3, 5, 9, 4, 6, 8];
const individualEntrepreneurItnFirstCoefficients = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
const individualEntrepreneurItnSecondCoefficients = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];

export const useTinValidation = () => {
  return {
    tin: yup
      .string()
      .trim()
      .required('Введите ИНН')
      .test(
        'length',
        'Некорректная длина ИНН',
        (value) => !value || value.length === 10 || value.length === 12,
      )
      .test('checkSum', 'Контрольная сумма не совпала с десятым символом', (value) => {
        if (!value || value.length !== 10) return true;
        const checkSum =
          (value
            .split('')
            .slice(0, -1)
            .reduce((acc, val, idx) => entityItnCoefficients[idx] * Number(val) + acc, 0) %
            11) %
          10;
        return Number(value[9]) === checkSum;
      })
      .test(
        'firstCheckSum',
        'Первая контрольная сумма не совпала с одиннадцатым символом',
        (value) => {
          if (!value || value.length !== 12) return true;
          const firstCheckSum =
            (value
              .split('')
              .slice(0, -2)
              .reduce(
                (acc, val, idx) =>
                  individualEntrepreneurItnFirstCoefficients[idx] * Number(val) + acc,
                0,
              ) %
              11) %
            10;
          return Number([value[10]]) === firstCheckSum;
        },
      )
      .test(
        'secondCheckSum',
        'Вторая контрольная сумма не совпала с двенадцатым символом',
        (value) => {
          if (!value || value.length !== 12) return true;
          const secondCheckSum =
            (value
              .split('')
              .slice(0, -1)
              .reduce(
                (acc, val, idx) =>
                  individualEntrepreneurItnSecondCoefficients[idx] * Number(val) + acc,
                0,
              ) %
              11) %
            10;
          return Number(value[11]) === secondCheckSum;
        },
      ),
  };
};
