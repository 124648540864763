import { Divider, Icon } from '@components';
import { Logo } from '../logo';
import styles from './styles.module.scss';

export const Footer = () => {
  return (
    <div className={styles.container}>
      <Logo withTitle isLink />
      <Divider className="mt-extra2" />
      <div className="flex">
        <div className={styles.column}>
          <h5 className={styles.title}>Контакты</h5>
          <a href="tel:+74212475353">
            <Icon icon="phone" color="icons-ghost" />
            <span>+7(4212) 47-53-53</span>
          </a>
          <a href="mailto:rospostavka@mail.ru">
            <Icon icon="mail" color="icons-ghost" />
            <span>rospostavka@mail.ru </span>
          </a>
        </div>
        <div className={styles.column}>
          <h5 className={styles.title}>Реквизиты компании</h5>
          <span>ИП Сударев М.А.</span>
          <span>
            <span className="font-medium mr-1">ОГРН:</span>317272400022024
          </span>
          <span>
            <span className="font-medium mr-1">ИНН:</span>272107109974
          </span>
          <a
            href="/Реквизиты.pdf"
            target="_blank"
            rel="noreferrer noopener"
            className={styles.file}
          >
            Реквизиты
          </a>
        </div>
        <div className={styles.column}>
          <h5 className={styles.title}>Другое</h5>
          <a href="/Оферта.docx" rel="noreferrer noopener" className={styles.file}>
            Оферта
          </a>
          <a
            href="/Политика персональных данных.docx"
            rel="noreferrer noopener"
            className={styles.file}
          >
            Политика персональных данных
          </a>
        </div>
      </div>
    </div>
  );
};
